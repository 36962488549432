import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  MAPPING_JOBS_REQUEST,
  MAPPING_JOBS_AVAILABLE,
  MAPPING_JOBS_UNAVAILABLE,
  SNACK_SET,
} from './constants';

const defaultState = {
  mappingJobsList: [],
  isLoadingMappingJobs: false,
  newMappingJobResponse: {},
  isLoadingNewMappingJob: false,
};

export const reducer = createReducer(defaultState, {
  [MAPPING_JOBS_REQUEST]: handleRequest,
  [MAPPING_JOBS_AVAILABLE]: handleAvailable,
  [MAPPING_JOBS_UNAVAILABLE]: handleUnavailable,
});

export function getMappingJobs(state = null) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingMappingJobs';
    const keyState = 'mappingJobsList';
    dispatch({ type: MAPPING_JOBS_REQUEST, payload: { loadingName } });
    try {
      const jobsResponse = await dataSource.getMappingJobs(state);
      const mappingJobsList = jobsResponse ? jobsResponse : [];
      dispatch({
        type: MAPPING_JOBS_AVAILABLE,
        payload: { keyState, loadingName, data: mappingJobsList },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: MAPPING_JOBS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function postMappingJob(robot_id, mapping_zones) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'newMappingJobResponse';
    const loadingName = 'isLoadingNewMappingJob';
    let snack;
    dispatch({
      type: MAPPING_JOBS_REQUEST,
      payload: { loadingName },
    });
    try {
      const response = await dataSource.postNewMappingJob(
        robot_id,
        JSON.stringify(mapping_zones)
      );
      dispatch({
        type: MAPPING_JOBS_AVAILABLE,
        payload: {
          keyState,
          data: response,
          loadingName,
        },
      });
      snack = { open: true, message: response.message, severity: 'success' };
    } catch (error) {
      dispatch({
        type: MAPPING_JOBS_UNAVAILABLE,
        payload: {
          keyState,
          loadingName,
        },
      });
      snack = {
        open: true,
        message: 'There was an error creating the job',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function deleteMappingJob(job_id) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'deleteJobResponse';
    const loadingName = 'isLoadingDeleteJob';
    let snack;
    dispatch({
      type: MAPPING_JOBS_REQUEST,
      payload: { loadingName },
    });
    try {
      const response = await dataSource.deleteMappingJob(job_id);
      dispatch({
        type: MAPPING_JOBS_AVAILABLE,
        payload: {
          keyState,
          data: response,
          loadingName,
        },
      });
      snack = { open: true, message: response, severity: 'success' };
    } catch (error) {
      dispatch({
        type: MAPPING_JOBS_UNAVAILABLE,
        payload: {
          keyState,
          loadingName,
        },
      });
      snack = {
        open: true,
        message: 'There was an error deleting the job',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function patchMappingJob(job_id, values) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'jobUpdateResponse';
    const loadingName = 'updatingJob';
    let snack;
    dispatch({
      type: MAPPING_JOBS_REQUEST,
      payload: { loadingName },
    });
    try {
      const response = await dataSource.updateMappingJob(
        job_id,
        JSON.stringify(values)
      );
      dispatch({
        type: MAPPING_JOBS_AVAILABLE,
        payload: {
          keyState,
          data: response,
          loadingName,
        },
      });
      snack = {
        open: true,
        message: response,
        severity: 'success',
      };
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: MAPPING_JOBS_UNAVAILABLE,
        payload: {
          keyState,
          loadingName,
        },
      });
      snack = {
        open: true,
        message: 'There was an error updating job.',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}
