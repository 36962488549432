import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@zippeditoolsjs/dashboards/dist/styles.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
// Language services
import { i18n, initI18n } from '@zippeditoolsjs/translations';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import HTTPService from 'core/services/http';
import AuthSource from 'core/services/authSource';
import BaldurSource from 'core/services/baldurSource';
import DataSource from 'core/services/dataSource';
import createStore from 'core/store';

import en from './i18n/en.json';
import es from './i18n/es.json';
import de from './i18n/de.json';
import ja from './i18n/ja.json';
import nl from './i18n/nl.json';

initI18n(en, es, null, { de, ja, nl });

const {
  REACT_APP_API_BASE_URL,
  REACT_APP_AUTH_BACKEND_URL,
  REACT_APP_BALDUR_API,
} = process.env;

const http = new HTTPService();

const dataSource = new DataSource({
  baseUrl: REACT_APP_API_BASE_URL,
  http,
});

const authSource = new AuthSource({
  baseUrl: REACT_APP_AUTH_BACKEND_URL,
  http,
});

const baldurSource = new BaldurSource({
  baseUrl: REACT_APP_BALDUR_API,
  http,
});

const store = createStore({
  authSource,
  dataSource,
  baldurSource,
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
