import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useCanvas from "./useCanvas";
import { transformRobot2Pixels, normalizeAngle, existCollision, normalizeBbox, isPointInside, liangBarsky, calculateCoverage } from '../Utils';
import { drawRectangleOnCanvas, canvasDrawOnLine, writeAisleOnCanvas, clearCanvas, drawPinOnCanvas, drawOrientedRectangle, drawPolygon } from '../utils/canvasFunctions';
import { compareObjects } from "./utils";
import { hexToPastel } from "../utils/commons";
import { useTheme } from "@mui/material";
import { DRAWING_WAYS } from "./useLayoutAnnotations";

export const AISLES_LAYER = 'capture';
export const ZONES_LAYER = 'zones';
export const POSES_LAYER = 'poses';
export const EXCLUSION_LAYER = 'nav_excluded';

export const NO_EDITABLE_LAYERS = [
    POSES_LAYER
]
export const EDITABLE_LAYERS = {
    CAPTURE: AISLES_LAYER,
    ZONES: ZONES_LAYER,
    AISLE_SIDE: 'aisle_side',
    FREE_ANNOTATIONS: 'free_annotations',
    EXCLUSION_ZONES: EXCLUSION_LAYER,
}

export const WARNINGS_TYPES = {
    RENAMED: 'renamed',
}

export default function useLayout(storeMap, robotLayout) {
    const [storeMapCanvasRef, storeMapDraw] = useCanvas();
    const [layers, setLayers] = useState({});
    const [modifiedLayout, setModifiedLayout] = useState(null);
    const [useClientAisles, setUseClientAisles] = useState(false);
    const [useRealBbox, setUseRealBbox] = useState(false);
    const [warnings, setWarnings] = useState([]);
    const theme = useTheme();
    const WANING_COLOR = useMemo(() => theme.palette.error.main, [theme]);
    const { globalLayers } = useSelector(state => state.layouts);
    const originalLayout = useMemo(() => ({
        ...globalLayers?.layers?.reduce((acc, { name }) => ({
            ...acc,
            [name]: {}
        }), {}),
        ...robotLayout?.layout,
    }), [globalLayers, robotLayout]);
    const mapMetadata = useMemo(() => {
        if (!storeMap) return null;
        return storeMap?.metadata;
    }, [storeMap]);

    useEffect(() => {
        if (storeMapCanvasRef?.current && mapMetadata) {
            const canvas = storeMapCanvasRef.current;
            canvas.width = mapMetadata.width;
            canvas.height = mapMetadata.height;
            setUseRealBbox(false);
            setUseClientAisles(false);
            const disableContextMenu = (event) => {
                event.preventDefault();
            };
            canvas.style.webkitTouchCallout = 'none';
            canvas.style.webkitUserSelect = 'none';
            canvas.style.userSelect = 'none';
            canvas.style.WebkitUserDrag = 'none';
            canvas?.addEventListener('contextmenu', disableContextMenu);
            return () => {
                canvas?.removeEventListener('contextmenu', disableContextMenu);
            }
        }
    }, [storeMapCanvasRef, mapMetadata]);

    const hasBeenModified = useMemo(() => {
        if (!modifiedLayout?.layout || !originalLayout) return false;
        return !compareObjects(modifiedLayout?.layout || {}, originalLayout || {});
    }, [modifiedLayout, originalLayout]);

    const aislesColissioned = useCallback(({ start, end }) => {
        if (!modifiedLayout) return [];
        const aisles = Object.keys(modifiedLayout?.layout?.[AISLES_LAYER] || {});
        const aislesColissioned = aisles.filter((aisle) => {
            const aisleData = modifiedLayout?.layout?.[AISLES_LAYER]?.[aisle];
            if (!aisleData) return false;
            if (Object.keys(modifiedLayout?.layout?.[ZONES_LAYER] || {}).includes(aisleData?.zone)) return false;
            const { bbox = [] } = aisleData;
            if (bbox.length !== 2) return false;
            return existCollision({ start: bbox[0], end: bbox[1] }, { start, end }, 'full');
        });
        return aislesColissioned;
    }, [modifiedLayout]);

    const colissionLayers = useCallback((parent, { start, end }, type = "rectangle", intersection = 'partial') => {
        if (!parent) return [];
        const parentData = modifiedLayout?.layout?.[parent] || {};
        if (!parentData) return [];

        const layersWithCoverage = Object.keys(parentData).map(layer => {
            const zoneStart = parentData[layer]?.bbox[0];
            const zoneEnd = parentData[layer]?.bbox[1];
            const color = parentData[layer]?.color;
            if (!zoneStart || !zoneEnd) return null;
            switch (type) {
                case "rectangle":
                    if (existCollision({ start, end }, { start: zoneStart, end: zoneEnd }, intersection)) {
                        const { start: normalizedStart, end: normalizedEnd } = normalizeBbox({ start: zoneStart, end: zoneEnd });
                        const { start: normalizedZoneStart, end: normalizedZoneEnd } = normalizeBbox({ start, end });
                        const intersectionStartX = Math.max(normalizedStart.x, normalizedZoneStart.x);
                        const intersectionEndX = Math.min(normalizedEnd.x, normalizedZoneEnd.x);
                        const intersectionStartY = Math.max(normalizedStart.y, normalizedZoneStart.y);
                        const intersectionEndY = Math.min(normalizedEnd.y, normalizedZoneEnd.y);
                        const area = Math.max(1, intersectionEndX - intersectionStartX) * Math.max(1, intersectionEndY - intersectionStartY);
                        return area > 0 ? { layer, coverage: area, color } : null;
                    }
                    break;
                case "line":
                    const { start: lineStart, end: lineEnd } = normalizeBbox({ start, end });
                    const { start: rectStart, end: rectEnd } = normalizeBbox({ start: zoneStart, end: zoneEnd });
                    const isLineFullyInside = () => {
                        return isPointInside(lineStart, rectStart, rectEnd) && isPointInside(lineEnd, rectStart, rectEnd);
                    };
                    switch (intersection) {
                        case 'partial':
                            const intersectionLine = liangBarsky(lineStart, lineEnd, rectStart, rectEnd);
                            const coverage = calculateCoverage(intersectionLine);
                            return coverage ? { layer, coverage, color } : isLineFullyInside() ? { layer, coverage: 1, color } : null;
                        case 'full':
                            return isLineFullyInside() ? { layer, coverage: 1, color } : null;
                        default:
                            return null;
                    }
                default:
                    return null;
            }
            return null;
        }).filter(Boolean);

        return layersWithCoverage.sort((a, b) => b.coverage - a.coverage).map(({ layer, color }) => ({ layer, color }));
    }, [modifiedLayout]);

    const parseSpecificPoint = useCallback((point) => {
        if (!mapMetadata) return;
        const origin = [mapMetadata?.origin?.x, mapMetadata?.origin?.y];
        const resolution = mapMetadata?.resolution;
        const originalHeight = mapMetadata?.height
        return transformRobot2Pixels(point.x, point.y, 0, origin, resolution, originalHeight);
    }, [mapMetadata]);

    const parseCoord = useCallback((coords) => {
        if (!mapMetadata) return;
        const upperLeft = parseSpecificPoint(coords[0]);
        const lowerRight = parseSpecificPoint(coords[1]);
        const px = upperLeft[0];
        const py = upperLeft[1];
        const width = (lowerRight[0] - px);
        const height = (lowerRight[1] - py);
        return [px, py, width, height];
    }, [mapMetadata, parseSpecificPoint]);

    useEffect(() => {
        setModifiedLayout({
            ...robotLayout,
            layout: {
                ...globalLayers?.layers?.reduce((acc, { name }) => ({
                    ...acc,
                    [name]: {}
                }), {}),
                ...robotLayout?.layout,
            }
        });
        setWarnings([]);
        if (!robotLayout || !globalLayers) return;
        const layers = globalLayers?.layers?.reduce((acc, { name, protected: isProtected }) => {
            acc[name] = {
                'name': name,
                'protected': isProtected || false,
                'visible': Object.keys(robotLayout?.layout?.[name] || {}).length === 0 ? true : Object.keys(robotLayout?.layout?.[name] || {}).some(childLayer => robotLayout?.layout?.[name]?.[childLayer]?.visible) || false,
                'layers': Object.keys(robotLayout?.layout?.[name] || {}).reduce((acc, childLayer) => {
                    acc[childLayer] = {
                        'name': childLayer,
                        'visible': robotLayout?.layout?.[name]?.[childLayer]?.visible,
                        'subLayers': Object.keys(robotLayout?.layout?.[name]?.[childLayer] || {}).filter(subLayer => robotLayout?.layout?.[name]?.[childLayer]?.[subLayer]?.type === DRAWING_WAYS.LINE) || []
                    };
                    return acc;
                }, {})
            };
            return acc;
        }, {});
        setLayers(layers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [robotLayout, globalLayers]);

    const parseColorsToDraw = useCallback((data) => {
        const colorFill = data?.warning ? `${WANING_COLOR}8C` : data?.excluded ? 'rgba(170,170,170,0.23)' : data?.color ? `${data.color}55` : 'rgba(255, 164, 0, 0.15)'
        const strokeStyle = data?.warning ? WANING_COLOR : data.color ? data.color : "#212529EE"
        return { colorFill, strokeStyle }
    }, [WANING_COLOR]);

    const parseDataToDraw = useCallback((data) => {
        const [px, py, width, height] = parseCoord(data.bbox);
        const { colorFill, strokeStyle } = parseColorsToDraw(data);
        return { px, py, width, height, colorFill, strokeStyle }
    }, [parseCoord, parseColorsToDraw]);

    const drawLayers = useCallback(() => {
        if (!layers || !modifiedLayout) return;
        const activeLayers = Object.keys(layers)?.filter(layer => layers[layer]?.visible);
        activeLayers?.forEach(layer => {
            const layerData = modifiedLayout?.layout?.[layer] || {};
            const layerItems = Object.keys(layerData)?.filter(item => layers[layer]?.layers[item]?.visible);
            layerItems?.forEach(item => {
                const itemData = layerData?.[item];
                if (itemData?.bbox || itemData?.map_points) {
                    const { type: _type } = itemData
                    let drawerFunction = null;
                    let args = null;
                    const type = useRealBbox ? _type : itemData?.drawing_way || _type;
                    switch (type) {
                        case DRAWING_WAYS.RECTANGLE:
                            drawerFunction = drawRectangleOnCanvas;
                            const { px, py, width, height, colorFill, strokeStyle } = parseDataToDraw(itemData)
                            args = [px, py, width, height, colorFill, strokeStyle];
                            break;
                        case DRAWING_WAYS.LINE:
                            drawerFunction = canvasDrawOnLine;
                            const { map_points } = itemData
                            const [_px, _py, _width, _height] = parseCoord(map_points)
                            const pxInit = { x: _px, y: _py }
                            const pxEnd = { x: _px + _width, y: _py + _height }
                            args = [{ pxInit, pxEnd }, '#00887c', 4];
                            break;
                        case DRAWING_WAYS.ORIENTED_BBOX:
                            const [first, second, third] = itemData.points;
                            const { colorFill: _colorFill, strokeStyle: _strokeStyle } = parseColorsToDraw(itemData);
                            drawerFunction = drawOrientedRectangle;
                            const parsedFirst = parseSpecificPoint(first);
                            const parsedSecond = parseSpecificPoint(second);
                            const parsedThird = parseSpecificPoint(third);
                            args = [{
                                x: parsedFirst[0],
                                y: parsedFirst[1]
                            }, {
                                x: parsedSecond[0],
                                y: parsedSecond[1]
                            }, {
                                x: parsedThird[0],
                                y: parsedThird[1]
                            }, _colorFill, _strokeStyle];
                            break;
                        case DRAWING_WAYS.POLYGON:
                            drawerFunction = drawPolygon;
                            const { colorFill: __colorFill, strokeStyle: __strokeStyle } = parseColorsToDraw(itemData);
                            const parsedPoints = itemData.points.map(point => {
                                const [x, y] = parseSpecificPoint(point)
                                return { x, y }
                            });
                            args = [parsedPoints, __colorFill, __strokeStyle, 0.9];
                            break;
                        default:
                            return;
                    }
                    if (!drawerFunction) return;
                    storeMapDraw(drawerFunction, args);
                    if ([DRAWING_WAYS.ORIENTED_BBOX, DRAWING_WAYS.POLYGON, DRAWING_WAYS.RECTANGLE].includes(type)) {
                        const { px, py, width, height } = parseDataToDraw(itemData)
                        const aisleName = useClientAisles ? itemData?.client_name || item : item;
                        storeMapDraw(writeAisleOnCanvas, [aisleName, [px, py], [px + width, py + height], true]);
                    }
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layers, modifiedLayout, useClientAisles, useRealBbox]);

    const drawLines = useCallback(() => {
        if (!layers || !modifiedLayout) return;
        const activeLayers = Object.keys(layers)?.filter(layer => layers[layer]?.visible);
        activeLayers?.forEach(layer => {
            const layerData = modifiedLayout?.layout?.[layer] || {};
            const layerItems = Object.keys(layerData)?.filter(item => layers[layer]?.layers[item]?.visible);
            layerItems?.forEach(item => {
                const itemData = layerData?.[item];
                const linesItems = Object.keys(itemData)?.filter(key => itemData[key]?.type === DRAWING_WAYS.LINE)
                linesItems?.forEach(lineItem => {
                    const lineData = itemData[lineItem];
                    const { map_points } = lineData
                    const [px, py, width, height] = parseCoord(map_points)
                    const pxInit = { x: px, y: py }
                    const pxEnd = { x: px + width, y: py + height }
                    storeMapDraw(canvasDrawOnLine, [{ pxInit, pxEnd }, '#00887c', 4])
                })
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modifiedLayout, layers, parseCoord]);

    const drawPoses = useCallback(() => {
        const poses = layers?.[POSES_LAYER];
        const info = {
            origin: [mapMetadata?.origin?.x, mapMetadata?.origin?.y],
            resolution: mapMetadata?.resolution,
            height: mapMetadata?.height,
        }
        Object.keys(poses?.layers || {})?.filter(pose => poses?.layers[pose]?.visible).forEach(pose => {
            const { x: xRobot, y: yRobot, ang: angRobot } = modifiedLayout?.layout?.[POSES_LAYER]?.[pose];
            const [x, y, angle] = transformRobot2Pixels(xRobot, yRobot, angRobot, info.origin, info.resolution, info.height);
            storeMapDraw(drawPinOnCanvas, [x, y, angle]);
        });
        Object.keys(modifiedLayout?.layout || {}).filter(layer => layer !== POSES_LAYER).forEach(layer => {
            const poseData = modifiedLayout?.layout?.[layer] || {};
            const layerData = layers?.[layer]?.layers || {};
            Object.keys(poseData || {}).filter(pose => layerData?.[pose]?.visible && poseData?.[pose]?.type === DRAWING_WAYS.POSE).forEach(pose => {
                const { x: xRobot, y: yRobot, ang: angRobot } = modifiedLayout?.layout?.[layer]?.[pose];
                const [x, y, angle] = transformRobot2Pixels(xRobot, yRobot, angRobot, info.origin, info.resolution, info.height);
                storeMapDraw(drawPinOnCanvas, [x, y, angle]);
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modifiedLayout, mapMetadata, layers]);

    const refreshLayout = useCallback(() => {
        if (!storeMapCanvasRef?.current || !robotLayout || !mapMetadata) return;
        const { width, height } = mapMetadata;
        storeMapDraw(clearCanvas, [width, height]);
        drawLines();
        drawLayers();
        drawPoses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeMapCanvasRef, robotLayout, mapMetadata, drawLines, drawLayers, drawPoses]);

    useEffect(() => {
        if (!robotLayout || !storeMap || !layers) return;
        refreshLayout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [robotLayout, storeMap, storeMapCanvasRef, refreshLayout, layers, useClientAisles]);

    const toggleParentLayer = useCallback((layer) => {
        setLayers(prev => {
            const newLayers = { ...prev };
            Object.keys(newLayers[layer]?.layers || {}).forEach(childLayer => {
                newLayers[layer].layers[childLayer].visible = !newLayers[layer].visible;
            });
            newLayers[layer].visible = !newLayers[layer].visible;
            return newLayers;
        });
    }, []);

    const toggleChildLayer = useCallback((layer, childLayer) => {
        setLayers(prev => {
            const newLayers = { ...prev };
            if (!newLayers[layer].visible && !newLayers[layer].layers[childLayer].visible) {
                newLayers[layer].visible = true;
            }
            newLayers[layer].layers[childLayer].visible = !newLayers[layer].layers[childLayer].visible;
            if (Object.keys(newLayers[layer].layers).every(childLayer => !newLayers[layer].layers[childLayer].visible)) {
                newLayers[layer].visible = false;
            }
            return newLayers;
        });
    }, []);

    const zoneColor = useCallback((zone) => modifiedLayout?.layout[ZONES_LAYER]?.[zone]?.color, [modifiedLayout]);
    const assignColorToAisles = useCallback(color => {
        try {
            return hexToPastel(color);
        } catch (error) {
            return color;
        }
    }, []);
    const addNewLayer = useCallback((parent, childData) => {
        const aisleName = childData?.orientation ? `${childData.name}-${childData.orientation}${childData.aisle_part ? `-${childData.aisle_part}` : ''}` : null;
        setLayers(prev => {
            const updatedParent = {
                ...prev?.[parent],
                ...(prev?.[parent] ? {} : { name: parent }),
                visible: true,
                layers: {
                    ...(prev?.[parent]?.layers || {}),
                    ...(childData ? {
                        [childData.name]: {
                            name: childData.name, visible: true,
                            ...(childData.type === DRAWING_WAYS.LINE && aisleName && {
                                subLayers: [
                                    ...(prev?.[parent]?.layers?.[childData.name]?.subLayers || []),
                                    ...(aisleName ? [aisleName] : [])
                                ]
                            })
                        }
                    } : {})
                }
            };
            return { ...prev, [parent]: updatedParent }
        });

        setModifiedLayout(prev => {
            const updatedParentLayout = {
                ...prev?.layout?.[parent],
                ...(childData
                    ? {
                        [childData.name]: {
                            ...prev?.layout?.[parent]?.[childData.name],
                            ...(!aisleName && {
                                type: childData?.type,
                                ...(childData?.bbox ? { bbox: childData.bbox } : {}),
                                ...(childData?.map_points ? { map_points: childData.map_points } : {}),
                                color: childData.zone ? assignColorToAisles(zoneColor(childData.zone)) : childData.color,
                                ...(childData.zone ? { zone: childData.zone } : {}),
                                client_name: childData?.clientName,
                            }),
                            ...(childData.aisle_type && { aisle_type: childData.aisle_type }),
                            ...(childData.drawing_way && { drawing_way: childData.drawing_way }),
                            ...(childData.points && { points: childData.points }),
                            visible: true,
                            ...(aisleName && {
                                [aisleName]: {
                                    name: aisleName,
                                    visible: true,
                                    type: DRAWING_WAYS.LINE,
                                    map_points: childData.bbox,
                                    client_name: childData?.clientName,
                                    params: childData.params,
                                }
                            })
                        }
                    }
                    : {})
            };
            return {
                ...prev,
                layout: {
                    ...prev?.layout,
                    [parent]: updatedParentLayout
                }
            };
        });
    }, [zoneColor, assignColorToAisles]);

    const addNewZone = useCallback((data, colissionedAisles) => {
        const { name, clientName, color, bbox, drawing_way = null, points = null } = data;
        setLayers(prev => ({
            ...prev,
            [ZONES_LAYER]: {
                ...(prev?.[ZONES_LAYER] || {}),
                visible: true,
                layers: {
                    ...(prev?.[ZONES_LAYER]?.layers || {}),
                    [name]: {
                        name: name,
                        visible: true,
                    }
                }
            },
        }));
        setModifiedLayout(prev => ({
            ...prev,
            layout: {
                ...(prev?.layout || {}),
                [ZONES_LAYER]: {
                    ...(prev?.layout?.[ZONES_LAYER] || {}),
                    [name]: {
                        name: name,
                        visible: true,
                        bbox: bbox,
                        ...(drawing_way && {
                            drawing_way,
                            points,
                        }),
                        color: color,
                        client_name: clientName,
                        type: DRAWING_WAYS.RECTANGLE,
                    }
                },
                ...(colissionedAisles.length > 0 ? {
                    [AISLES_LAYER]: {
                        ...(prev?.layout?.[AISLES_LAYER] || {}),
                        ...colissionedAisles.reduce((acc, aisle) => {
                            acc[aisle] = {
                                ...(prev?.layout?.[AISLES_LAYER]?.[aisle] || {}),
                                color: assignColorToAisles(color),
                                zone: name,
                            };
                            return acc;
                        }, {})
                    }
                } : {})
            }
        }));
    }, [assignColorToAisles]);

    const addNewPose = useCallback(({ name, x, y, ang, layer = POSES_LAYER, clientName = null, visible = false }) => {
        setLayers(prev => {
            const updatedLayers = {
                ...prev,
                [layer]: {
                    ...(prev?.[layer] || {}),
                    visible: true,
                    'layers': {
                        ...(prev?.[layer]?.layers || {}),
                        [name]: {
                            name: name,
                            visible: true,
                        }
                    }
                }
            };
            return updatedLayers;
        });
        setModifiedLayout(prev => {
            const updatedLayout = {
                ...prev,
                layout: {
                    ...prev?.layout,
                    [layer]: {
                        ...(prev?.layout?.[layer] || {}),
                        [name]: {
                            visible,
                            ...(prev?.layout?.[layer]?.[name] || {}),
                            x,
                            y,
                            ang: normalizeAngle(ang - Math.PI / 2),
                            type: "pose",
                            ...(clientName ? { client_name: clientName } : {})
                        }
                    }
                }
            };
            return updatedLayout;
        });
    }, []);

    const provisionalName = useCallback((layer, name) => {
        const layerData = modifiedLayout?.layout?.[layer] || {};
        const regex = new RegExp(`${name} \\((\\d+)\\)`);
        let count = 0;
        Object.keys(layerData)?.filter(item => regex.test(item)).forEach(item => {
            const match = item.match(regex);
            count = Math.max(count, parseInt(match[1]));
        });
        return `${name} (${count + 1})`;
    }, [modifiedLayout]);

    const manageNameConflict = useCallback((data, LAYER) => {
        const { _originalName, _existsConflict, name: __name, } = data;
        const lowerNameFind = _existsConflict ? Object.keys(modifiedLayout?.layout?.[LAYER] || {}).find(item => item.toLowerCase() === __name.toLowerCase()) : null;
        const name = lowerNameFind || __name;
        const _provisionalName = provisionalName(LAYER, name);
        const hasWarning = modifiedLayout?.layout?.[LAYER]?.[_originalName]?.warning;
        if (_existsConflict) {
            setWarnings(prev => [...prev, {
                type: WARNINGS_TYPES.RENAMED,
                layer: LAYER,
                timestamp: new Date().toISOString(),
                target_name: _provisionalName,
                data: {
                    caused_by: _originalName,
                    conflict_name: name,
                }
            }]);
        }
        if (hasWarning) {
            setLayers(prev => {
                if (!prev[LAYER]?.layers) return prev;
                const newLayers = { ...prev, [LAYER]: { ...prev[LAYER], layers: { ...prev[LAYER].layers } } };
                const warningItem = warnings?.find(warning => warning.target_name === _originalName);
                if (newLayers[LAYER]?.layers?.[_originalName]) {
                    const updatedLayer = { ...newLayers[LAYER].layers[_originalName] };
                    delete updatedLayer.warning_old_name;
                    newLayers[LAYER].layers[_originalName] = updatedLayer;
                }
                if (warningItem?.data?.conflict_name && newLayers[LAYER]?.layers?.[warningItem.data?.conflict_name]) {
                    const updatedLayer = { ...newLayers[LAYER].layers[warningItem.data?.conflict_name] };
                    delete updatedLayer.warning_old_name;
                    newLayers[LAYER].layers[warningItem.data?.conflict_name] = updatedLayer;
                }
                return newLayers;
            });
            setWarnings(prev => prev.filter(warning => warning.target_name !== _originalName));
        }
        return {
            name: _existsConflict ? name : __name,
            _provisionalName: _existsConflict ? _provisionalName : null,
        }
    }, [modifiedLayout?.layout, provisionalName, warnings]);

    const removeWarnings = useCallback((data) => ({
        ...Object.keys(data || {}).filter(key => key !== 'warning' && key !== 'warning_old_name').reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {}),
    }), []);

    const editAisle = useCallback((data) => {
        const { _originalName, _existsConflict, zone, aisleType, excluded, clientName } = data;
        const { name, _provisionalName } = manageNameConflict(data, AISLES_LAYER);
        setLayers(prev => {
            if (!prev?.[AISLES_LAYER]?.layers) return prev;
            const conflictData = _existsConflict ? { ...prev[AISLES_LAYER]?.layers?.[name] } : null;
            const originalData = { ...prev[AISLES_LAYER]?.layers?.[_originalName] };
            const { [_originalName]: _, ...remainingLayers } = prev[AISLES_LAYER]?.layers || {};
            return {
                ...prev,
                [AISLES_LAYER]: {
                    ...prev[AISLES_LAYER],
                    visible: true,
                    layers: {
                        ...remainingLayers,
                        [name]: {
                            ...removeWarnings(originalData),
                            name: name,
                            visible: true,
                            subLayers: [...(originalData?.subLayers?.map(subLayer => subLayer.replace(_originalName, name)) || [])],
                            ...(conflictData ? {
                                warning_old_name: _originalName,
                            } : {})
                        },
                        ...(conflictData ? {
                            [_provisionalName]: {
                                ...conflictData,
                                name: _provisionalName,
                                warning: true,
                                visible: true,
                                subLayers: [...(conflictData?.subLayers?.map(subLayer => subLayer.replace(name, _provisionalName)) || [])],
                                warning_old_name: name,
                            }
                        } : {})
                    }
                }
            };
        });
        setModifiedLayout(prev => {
            if (!prev?.layout?.[AISLES_LAYER]) return prev;
            const conflictData = _existsConflict ? { ...prev.layout[AISLES_LAYER]?.[name] } : null;
            const originalData = { ...prev.layout[AISLES_LAYER]?.[_originalName] };
            const { [_originalName]: _, ...remainingAisles } = prev.layout[AISLES_LAYER] || {};
            return {
                ...prev,
                layout: {
                    ...prev.layout,
                    [AISLES_LAYER]: {
                        ...remainingAisles,
                        [name]: {
                            ...Object.keys(originalData || {}).filter(key => originalData[key]?.type !== DRAWING_WAYS.LINE && key !== 'warning' && key !== 'color').reduce((acc, key) => {
                                acc[key] = originalData[key];
                                return acc;
                            }, {}),
                            zone,
                            aisle_type: aisleType,
                            client_name: clientName,
                            ...(zone ? { color: assignColorToAisles(zoneColor(zone)) } : {}),
                            excluded,
                            ...Object.keys(originalData || {}).filter(key => originalData[key]?.type === DRAWING_WAYS.LINE).reduce((acc, key) => {
                                acc[key.replace(_originalName, name)] = {
                                    ...originalData[key],
                                    name: key.replace(_originalName, name)
                                };
                                return acc;
                            }, {})
                        },
                        ...(conflictData ? {
                            [_provisionalName]: {
                                ...Object.keys(conflictData).filter(key => conflictData[key]?.type !== DRAWING_WAYS.LINE).reduce((acc, key) => {
                                    acc[key] = conflictData[key];
                                    return acc;
                                }, {}),
                                warning: true,
                                ...Object.keys(conflictData).filter(key => conflictData[key]?.type === DRAWING_WAYS.LINE).reduce((acc, key) => {
                                    acc[key.replace(name, _provisionalName)] = {
                                        ...conflictData[key],
                                        name: key.replace(name, _provisionalName)
                                    };
                                    return acc;
                                }, {})
                            }
                        } : {})
                    }
                }
            };
        });
    }, [manageNameConflict, removeWarnings, assignColorToAisles, zoneColor]);

    const editZone = useCallback((data) => {
        const { _originalName, _existsConflict, clientName, color } = data;
        const { name, _provisionalName } = manageNameConflict(data, ZONES_LAYER);
        setLayers(prev => {
            if (!prev?.[ZONES_LAYER]?.layers) return prev;
            const conflictData = _existsConflict ? { ...prev[ZONES_LAYER]?.layers?.[name] } : null;
            const originalData = { ...prev[ZONES_LAYER]?.layers?.[_originalName] };
            const { [_originalName]: _, ...remainingZones } = prev[ZONES_LAYER]?.layers || {};
            return {
                ...prev,
                [ZONES_LAYER]: {
                    ...prev[ZONES_LAYER],
                    visible: true,
                    layers: {
                        ...remainingZones,
                        [name]: {
                            ...removeWarnings(originalData),
                            name: name,
                            visible: true,
                            color: color,
                            client_name: clientName,
                            ...(conflictData ? {
                                warning_old_name: _originalName,
                            } : {})
                        },
                        ...(conflictData ? {
                            [_provisionalName]: {
                                ...conflictData,
                                name: _provisionalName,
                                visible: true,
                                warning: true,
                                warning_old_name: name,
                            }
                        } : {})
                    }
                }
            };
        });
        setModifiedLayout(prev => {
            if (!prev?.layout?.[ZONES_LAYER]) return prev;
            const conflictData = _existsConflict ? { ...prev.layout[ZONES_LAYER]?.[name] } : null;
            const originalData = { ...prev.layout[ZONES_LAYER]?.[_originalName] };
            const { [_originalName]: _, ...remainingZones } = prev.layout[ZONES_LAYER] || {};
            const { ...remainingAisles } = prev.layout?.[AISLES_LAYER] || {};
            const modifiedAisles = Object.keys(remainingAisles || {}).reduce((acc, aisle) => {
                acc[aisle] = {
                    ...remainingAisles[aisle],
                    ...(remainingAisles[aisle]?.zone === _originalName ? {
                        zone: name,
                        color: assignColorToAisles(color),
                    } : {}),
                    ...(_existsConflict && (remainingAisles[aisle]?.zone === name ? {
                        zone: _provisionalName,
                    } : {}))
                };
                return acc;
            }, {});
            return {
                ...prev,
                layout: {
                    ...prev.layout,
                    [AISLES_LAYER]: modifiedAisles,
                    [ZONES_LAYER]: {
                        ...remainingZones,
                        [name]: {
                            ...removeWarnings(originalData),
                            color: color,
                            client_name: clientName,
                        },
                        ...(conflictData ? {
                            [_provisionalName]: {
                                ...conflictData,
                                visible: true,
                                warning: true,
                            }
                        } : {})
                    }
                }
            };
        });
    }, [manageNameConflict, removeWarnings, assignColorToAisles]);

    const editAisleSide = useCallback((data) => {
        const { _originalName, params, client_name, parent } = data;
        setModifiedLayout(prev => {
            const { [parent]: _, ...remainingAisles } = prev.layout[AISLES_LAYER] || {};
            return {
                ...prev,
                layout: {
                    ...prev.layout, [AISLES_LAYER]: {
                        ...remainingAisles,
                        [parent]: {
                            ..._,
                            [_originalName]: {
                                ..._[_originalName],
                                params,
                                client_name,
                            }
                        }
                    }
                }
            };
        });
    }, []);

    const editDefaultAnnotation = useCallback((data) => {
        const { _originalName, _existsConflict, clientName, layer: CURRENT_LAYER } = data;
        const { name, _provisionalName } = manageNameConflict(data, CURRENT_LAYER);
        setLayers(prev => {
            if (!prev?.[CURRENT_LAYER]?.layers) return prev;
            const conflictData = _existsConflict ? { ...prev[CURRENT_LAYER]?.layers?.[name] } : null;
            const originalData = { ...prev[CURRENT_LAYER]?.layers?.[_originalName] };
            const { [_originalName]: _, ...remainingAnnotations } = prev[CURRENT_LAYER]?.layers || {};
            return {
                ...prev,
                [CURRENT_LAYER]: {
                    ...prev[CURRENT_LAYER],
                    visible: true,
                    layers: {
                        ...remainingAnnotations,
                        [name]: {
                            ...removeWarnings(originalData),
                            name: name,
                            visible: true,
                            ...(conflictData ? {
                                warning_old_name: _originalName,
                            } : {})
                        },
                        ...(conflictData ? {
                            [_provisionalName]: {
                                ...conflictData,
                                name: _provisionalName,
                                visible: true,
                                warning: true,
                                warning_old_name: name,
                            }
                        } : {})
                    }
                }
            };
        });
        setModifiedLayout(prev => {
            if (!prev?.layout?.[CURRENT_LAYER]) return prev;
            const conflictData = _existsConflict ? { ...prev.layout[CURRENT_LAYER]?.[name] } : null;
            const originalData = { ...prev.layout[CURRENT_LAYER]?.[_originalName] };
            const { [_originalName]: _, ...remainingAnnotations } = prev.layout[CURRENT_LAYER] || {};
            return {
                ...prev,
                layout: {
                    ...prev.layout,
                    [CURRENT_LAYER]: {
                        ...remainingAnnotations,
                        [name]: {
                            ...removeWarnings(originalData),
                            client_name: clientName,
                        },
                        ...(conflictData ? {
                            [_provisionalName]: {
                                ...conflictData,
                                visible: true,
                                warning: true,
                            }
                        } : {})
                    }
                }
            };
        });
    }, [manageNameConflict, removeWarnings]);

    const editAnnotation = useCallback((type, data) => {
        if (!modifiedLayout) return;
        if (NO_EDITABLE_LAYERS.includes(type)) return;
        const editFunctions = {
            [EDITABLE_LAYERS.CAPTURE]: editAisle,
            [EDITABLE_LAYERS.ZONES]: editZone,
            [EDITABLE_LAYERS.AISLE_SIDE]: editAisleSide,
        };
        const editFunction = editFunctions?.[type] || editDefaultAnnotation;
        editFunction?.(data);
    }, [modifiedLayout, editAisle, editZone, editAisleSide, editDefaultAnnotation]);

    const existsAnyWarning = useMemo(() => {
        return Object.values(layers || {}).some(layer => Object.values(layer?.layers || {}).some(item => item?.warning));
    }, [layers]);

    const deleteAnnotation = useCallback((layer, annotation, subAnnotation = null) => {
        setLayers(prev => {
            if (!prev[layer]?.layers) return prev;
            const warningItem = warnings?.find(warning => warning.target_name === annotation);
            if (!warningItem) return prev;
            const newLayers = { ...prev, [layer]: { ...prev[layer], layers: { ...prev[layer].layers } } };
            if (warningItem?.data?.conflict_name && newLayers[layer]?.layers?.[warningItem.data?.conflict_name]) {
                const { warning_old_name, ...updatedLayer } = { ...newLayers[layer].layers[warningItem.data?.conflict_name] };
                newLayers[layer].layers[warningItem.data?.conflict_name] = updatedLayer;
            }
            return newLayers;
        });
        setWarnings(prev => prev.filter(warning => warning.target_name !== annotation));

        if (layer === POSES_LAYER) {
            setLayers(prev => {
                const { [POSES_LAYER]: _poses, ...remainingLayers } = prev || {};
                return {
                    ...remainingLayers, [POSES_LAYER]: {
                        ..._poses,
                        layers: {}
                    }
                };
            });
            setModifiedLayout(prev => {
                const { [POSES_LAYER]: _, ...remainingLayout } = prev.layout || {};
                return { ...prev, layout: { ...remainingLayout, [POSES_LAYER]: {} } };
            });
            return;
        }
        if (layer === ZONES_LAYER && !subAnnotation) {
            setModifiedLayout(prev => {
                const { ...remainingAisles } = prev.layout[AISLES_LAYER] || {};
                const modifiedAisles = Object.keys(remainingAisles || {}).reduce((acc, aisle) => {
                    acc[aisle] = {
                        ...Object.keys(remainingAisles[aisle] || {}).reduce((acc, key) => {
                            const { zone = null } = remainingAisles[aisle] || {};
                            if ((key === 'color' && remainingAisles[aisle]?.['zone'] === annotation) || (key === 'zone' && zone === annotation)) {
                                return acc;
                            }
                            acc[key] = remainingAisles[aisle][key];
                            return acc;
                        }, {})
                    };
                    return acc;
                }, {});
                return {
                    ...prev,
                    layout: { ...prev.layout, [AISLES_LAYER]: modifiedAisles }
                };
            });
        }
        setModifiedLayout(prev => {
            const { [annotation]: _an, ...remainingLayers } = prev.layout[layer] || {};
            const annotationModified = subAnnotation ? Object.keys(_an || {}).filter(key => key !== subAnnotation).reduce((acc, key) => {
                acc[key] = _an[key];
                return acc;
            }, {}) : {};
            return {
                ...prev,
                layout: {
                    ...prev.layout, [layer]: {
                        ...(subAnnotation ? {
                            [annotation]: annotationModified,
                        } : {}),
                        ...remainingLayers
                    }
                }
            };
        });
        setLayers(prev => {
            const { [annotation]: _an, ...remainingLayers } = prev[layer]?.layers || {};
            const annotationModified = subAnnotation ? {
                ..._an,
                subLayers: _an?.subLayers?.filter(subLayer => subLayer !== subAnnotation) || [],
            } : {}
            return {
                ...prev, [layer]: {
                    ...prev[layer], layers: {
                        ...remainingLayers,
                        ...(subAnnotation ? {
                            [annotation]: annotationModified,
                        } : {})
                    }
                }
            };
        });
    }, [warnings]);
    return {
        addNewPose,
        storeMapCanvasRef,
        layers,
        colissionLayers,
        toggleParentLayer,
        toggleChildLayer,
        addNewLayer,
        useClientAisles,
        setUseClientAisles,
        useRealBbox,
        setUseRealBbox,
        modifiedLayout,
        hasBeenModified,
        aislesColissioned,
        addNewZone,
        editAnnotation,
        existsAnyWarning,
        warnings,
        deleteAnnotation,
    }
}