import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Paper,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Divider,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import styled from '@emotion/styled';
import HotKeyToggleButton from '../layoutsView/HotKeyToggleButton';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const AnnotationsTools = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    borderRadius: '4px',
    border: 0,
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));

const mappingTools = [
  {
    drag: {
      label: 'Move',
      hotKey: '1',
      icon: <IconComponent iconName="hand-left" style={{ fontSize: '24px' }} />,
      can_disable: false,
    },
    draw: {
      label: 'Draw',
      hotKey: '2',
      icon: (
        <IconComponent iconName="square-outline" style={{ fontSize: '24px' }} />
      ),
      can_disable: true,
    },
    display: {
      label: 'Box Display',
      hotKey: '3',
      icon: <IconComponent iconName="eye" style={{ fontSize: '24px' }} />,
      can_disable: true,
    },
    delete: {
      label: 'Delete Box',
      hotKey: '4',
      icon: <IconComponent iconName="trash" style={{ fontSize: '24px' }} />,
      can_disable: true,
    },
  },
  {
    drag: {
      label: 'Move',
      hotKey: '1',
      icon: <IconComponent iconName="hand-left" style={{ fontSize: '24px' }} />,
      can_disable: false,
    },
    display: {
      label: 'Box Display',
      hotKey: '2',
      icon: <IconComponent iconName="eye" style={{ fontSize: '24px' }} />,
      can_disable: true,
    },
  },
  {
    drag: {
      label: 'Move',
      hotKey: '1',
      icon: <IconComponent iconName="hand-left" style={{ fontSize: '24px' }} />,
      can_disable: true,
    },
    display: {
      label: 'Box Display',
      hotKey: '2',
      icon: <IconComponent iconName="eye" style={{ fontSize: '24px' }} />,
      can_disable: true,
    },
  },
];

const confirmationTools = [
  {
    save: {
      label: 'Save',
      hotKey: '1',
      icon: <SaveIcon />,
      can_disable: false,
    },
    confirm: {},
  },
  { reject: {}, confirm: {} },
  { download: {}, upload: {}, deploy: {} },
];

export default function MapComponent(props) {
  const {
    mapInfo,
    selectedJob,
    tabIndex = 0,
    mode,
    storeInfo,
    multiRobotId,
    isLoadingBucketMapLayout,
    creatingNewJob,
    postMappingJob,
    postMappingJobResponse,
    patchMappingJob,
  } = props;
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const containerRef = useRef(null);

  const { t } = useTranslation();

  const [mapImg, setMapImg] = useState(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [bounds, setBounds] = useState({ minX: 0, minY: 0, maxX: 0, maxY: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
  const [isDrawing, setIsDrawing] = useState(false);
  const [startDrawingPosition, setStartDrawingPosition] = useState({
    x: 0,
    y: 0,
  });
  const [zones, setZones] = useState([]);
  const [activeTool, setActiveTool] = useState(null);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  useEffect(() => {
    if (selectedJob === null) setZones([]);
  }, [selectedJob]);

  useEffect(() => {
    if (mapInfo && selectedJob && selectedJob?.job_content) {
      const job_zones = selectedJob.job_content.zones;
      const jobs_to_draw = job_zones.map((zone) => {
        const [x1, y1] = zone.bbox[0];
        const [x2, y2] = zone.bbox[1];
        const x_min = Math.min(x1, x2);
        const y_min = Math.min(y1, y2);
        const x_max = Math.max(x1, x2);
        const y_max = Math.max(y1, y2);
        const zone_object = {
          remapping_zone_id: zone.remapping_zone_id,
          x0: x_min + imagePosition.x,
          y0: y_min + imagePosition.y,
          x1: x_max + imagePosition.x,
          y1: y_max + imagePosition.y,
          width: x_max - x_min,
          height: y_max - y_min,
          accepted: zone.accepted,
        };
        return zone_object;
      });
      setZones(jobs_to_draw);
    }
  }, [mapInfo]);

  useEffect(() => {
    if (postMappingJobResponse?.code === 200) {
      setZones([]);
    }
  }, [postMappingJobResponse]);

  useEffect(() => {
    if (mapInfo) {
      const container = containerRef.current?.getBoundingClientRect();
      if (container)
        setBounds({
          minX: container.width - mapInfo.width,
          minY: container.height - mapInfo.height,
          maxX: 0,
          maxY: 0,
        });
      setHeight(mapInfo.height);
      setWidth(mapInfo.width);
      setMapImg(mapInfo.map_img);
      setImagePosition({ x: 0, y: 0 });
    }
  }, [mapInfo]);

  // Redraws rectangles when the image is moved or new ones are added
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.font = '14px Arial'; // Establecer la fuente del texto
    ctx.fillStyle = 'black'; // Color del texto

    zones.forEach((rect) => {
      // check condition
      let is_accepted = rect?.accepted;
      if (is_accepted) ctx.strokeStyle = 'green';
      else if (is_accepted === null) ctx.strokeStyle = 'yellow';
      else ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      // Draw the rectangles adjusted to the position of the image
      ctx.strokeRect(
        rect.x0 + imagePosition.x,
        rect.y0 + imagePosition.y,
        rect.width,
        rect.height
      );
      const statusText = rect.accepted
        ? 'Acepted'
        : rect.accepted === false
        ? 'Rejected'
        : 'Pending';
      ctx.fillText(
        statusText,
        rect.x0 + imagePosition.x,
        rect.y0 + imagePosition.y - 5
      );
    });
  }, [imagePosition, zones]);

  const handleCanvasClick = (e) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    if (mode === 'review') {
      const updatedZones = zones.map((zone) => {
        const [x1, y1] = [zone.x0, zone.y0];
        const [x2, y2] = [zone.x1, zone.y1];

        const x_min = Math.min(x1, x2) + imagePosition.x;
        const y_min = Math.min(y1, y2) + imagePosition.y;
        const x_max = Math.max(x1, x2) + imagePosition.x;
        const y_max = Math.max(y1, y2) + imagePosition.y;

        if (
          mouseX >= x_min &&
          mouseX <= x_max &&
          mouseY >= y_min &&
          mouseY <= y_max
        ) {
          return { ...zone, accepted: !zone.accepted };
        }
        return zone;
      });
      setZones(updatedZones);
    }
  };

  const handleMouseDown = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    if (activeTool === 'drag') {
      setIsDragging(true);
      setStartDragPosition({
        x: e.clientX - imagePosition.x,
        y: e.clientY - imagePosition.y,
      });
    } else if (activeTool === 'draw') {
      setIsDrawing(true);
      setStartDrawingPosition({ x: mouseX, y: mouseY });
    } else if (activeTool === 'delete') {
      let clickX = mouseX - imagePosition.x;
      let clickY = mouseY - imagePosition.y;
      let filtered = zones.filter(
        (item) =>
          clickX < Math.min(item.x0, item.x1) ||
          clickX > Math.max(item.x0, item.x1) ||
          clickY < Math.min(item.y0, item.y1) ||
          clickY > Math.max(item.y0, item.y1)
      );
      setZones(filtered);
    }
  };

  const handleMouseMove = (e) => {
    if (activeTool === 'drag' && isDragging) {
      const newX = e.clientX - startDragPosition.x;
      const newY = e.clientY - startDragPosition.y;

      setImagePosition({
        x: Math.max(bounds.minX, Math.min(bounds.maxX, newX)),
        y: Math.max(bounds.minY, Math.min(bounds.maxY, newY)),
      });
    } else if (activeTool === 'draw' && isDrawing) {
      const rect = canvasRef.current.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      zones.forEach((rect) => {
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        // Draw the previous rectangles adjusted to the position of the image
        ctx.strokeRect(
          rect.x0 + imagePosition.x,
          rect.y0 + imagePosition.y,
          rect.width,
          rect.height
        );
      });

      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(
        startDrawingPosition.x,
        startDrawingPosition.y,
        mouseX - startDrawingPosition.x,
        mouseY - startDrawingPosition.y
      );
    }
  };

  const handleMouseUp = (e) => {
    if (activeTool === 'draw' && isDrawing) {
      const rect = canvasRef.current.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      // Save the rectangle in the state in coordinates relative to the map
      setZones((prev) => [
        ...prev,
        {
          remapping_zone_id: zones.length + 1,
          x0: startDrawingPosition.x - imagePosition.x,
          y0: startDrawingPosition.y - imagePosition.y,
          x1: mouseX - imagePosition.x,
          y1: mouseY - imagePosition.y,
          width: mouseX - startDrawingPosition.x,
          height: mouseY - startDrawingPosition.y,
          accepted: null,
        },
      ]);
    }
    setIsDragging(false);
    setIsDrawing(false);
  };

  const onChange = (event, newValue) => {
    setActiveTool(newValue);
  };

  const onClose = () => setOpenSaveModal(false);

  const onSave = () => {
    setOpenSaveModal(false);
    let parsedZones = {
      zones: zones.map((zone) => {
        return {
          remapping_zone_id: zone.remapping_zone_id,
          accepted: zone.accepted,
          bbox: [
            [zone.x0, zone.y0],
            [zone.x1, zone.y1],
          ],
        };
      }),
    };
    if (selectedJob) {
      updateMappingJobState(selectedJob.mapping_job_id, {
        job_content: parsedZones,
      });
    } else
      postMappingJob(`${storeInfo?.store_id}-${multiRobotId}`, parsedZones);
  };

  const updateMappingJobState = (job_id, values) => {
    patchMappingJob(job_id, values);
  };

  const handleCheckButton = () => {
    let newValues = {};
    switch (mode) {
      case 'pending':
        newValues = {
          job_state: 'In Review',
        };
        break;
      case 'review':
        let parsedZones = {
          zones: zones.map((zone) => {
            return {
              remapping_zone_id: zone.remapping_zone_id,
              accepted: zone.accepted,
              bbox: [
                [zone.x0, zone.y0],
                [zone.x1, zone.y1],
              ],
            };
          }),
        };
        newValues = {
          job_content: parsedZones,
          job_state: 'Accepted',
        };
        break;
      default:
        break;
    }
    updateMappingJobState(selectedJob.mapping_job_id, newValues);
  };

  const handleDialogContent = () => {
    let title, message;
    if (selectedJob) {
      title = t('overseer_app.mapping.update_job', 'Update Mapping Job');
      message = t(
        'overseer_app.mapping.update_job_description',
        'You are about to update a mapping job. Do you want to continue?'
      );
    } else {
      title = t(
        'overseer_app.mapping.create_job_confirm',
        'New Mapping Job Confirm'
      );
      message = t(
        'overseer_app.mapping.create_job_description',
        `You are about to create a mapping job with ${zones.length} zones. Do you want to continue?`,
        { nZones: zones.length }
      );
    }
    return (
      <>
        <DialogTitle sx={{ marginBottom: -1 }}>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
      </>
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          boxShadow: (theme) => theme.shadows[1],
          justifyContent: 'space-between',
        }}
      >
        <Grid
          container
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Grid item>
            <AnnotationsTools
              value={activeTool}
              exclusive
              onChange={onChange}
              aria-label="annotations tools"
            >
              {Object.entries(mappingTools[tabIndex]).map(
                ([tool, { label, hotKey, icon, can_disable }]) => {
                  return (
                    <HotKeyToggleButton
                      key={tool}
                      value={tool}
                      aria-label={label}
                      hotKey={hotKey}
                      label={label}
                      /* disabled={can_disable && !creatingNewJob} */
                    >
                      {icon}
                    </HotKeyToggleButton>
                  );
                }
              )}
              <Box
                sx={{
                  alignItems: 'center',
                  gap: 1,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  position: 'relative',
                }}
              >
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{ mx: 0.5, my: 1 }}
                />
              </Box>
            </AnnotationsTools>
          </Grid>
          <Grid item>
            {storeInfo ? (
              <Typography variant="body1" color="text.secondary">
                {`${storeInfo.store_id} - ${storeInfo.name} / ${t(
                  'overseer_app.general.floor',
                  'Floor'
                )} ${multiRobotId}`}
              </Typography>
            ) : null}
          </Grid>
          <Grid item>
            {mode === 'pending' ? (
              <IconButton
                disabled={zones.length === 0}
                onClick={() => setOpenSaveModal(true)}
              >
                <SaveIcon />
              </IconButton>
            ) : mode === 'review' ? (
              <IconButton onClick={() => setOpenSaveModal(true)}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                disabled={zones.length === 0}
                onClick={() => setOpenSaveModal(true)}
              >
                <CloudUploadIcon />
              </IconButton>
            )}

            <IconButton disabled={!selectedJob} onClick={handleCheckButton}>
              <CheckIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>

      <Card sx={{ height: '90%', width: '100%', pb: 0, position: 'relative' }}>
        {isLoadingBucketMapLayout ? (
          <LinearProgress sx={{ width: '95%', mx: 'auto' }} color="secondary" />
        ) : (
          <CardContent
            ref={containerRef}
            sx={{
              height: '100%',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 'inherit',
              padding: 0,
              margin: 0,
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onClick={handleCanvasClick}
          >
            {!mapImg ? (
              <Box
                sx={{
                  width: width,
                  minHeight: '400px',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#F9F9F9',
                  border: '1px solid #DDD',
                  borderRadius: '5px',
                }}
              >
                <Typography variant="h6" sx={{ color: 'grey' }}>
                  No map available
                </Typography>
              </Box>
            ) : (
              <img
                ref={imgRef}
                src={'data:image/jpeg;base64,' + mapImg}
                alt="Bucket Map Layout"
                style={{
                  position: 'absolute',
                  top: imagePosition.y,
                  left: imagePosition.x,
                  zIndex: 1,
                  userSelect: 'none',
                  cursor: activeTool === 'drag' ? 'grab' : 'default',
                }}
              />
            )}
            <canvas
              ref={canvasRef}
              width={width}
              height={height}
              style={{
                zIndex: 2,
                position: 'absolute',
                top: 0,
                left: 0,
                userSelect: 'none',
              }}
            />
          </CardContent>
        )}
      </Card>
      <Dialog open={openSaveModal} onClose={onClose} fullWidth maxWidth="sm">
        {handleDialogContent()}

        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            {t('overseer_app.layouts.cancel', 'Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={onSave}>
            {t('overseer_app.layouts.save', 'Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
