import { styled } from '@mui/material/styles';
import { ToggleButtonGroup, toggleButtonGroupClasses, Paper, Box, MenuItem, ListItemText, Typography, Popper, Grow, ClickAwayListener, MenuList, Chip, Divider } from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useCallback, memo, useRef, useState, useMemo, useEffect } from 'react';
import HotKeyToggleButton from './HotKeyToggleButton';
import { LAYOUT_TOOLS, DRAWING_WAYS_TOOLS, DRAWING_WAYS } from '../tools/useLayoutAnnotations';
import { useTranslation } from 'react-i18next';

const TOOLS_WITH_DRAWING_WAYS = Object.keys(DRAWING_WAYS_TOOLS);

const AnnotationsTools = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        borderRadius: '4px',
        border: 0,
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        marginLeft: -1,
        borderLeft: '1px solid transparent',
    },
}));

function ExcludeZoneIcon() {
    return (
        <Box sx={{ position: 'relative' }}>
            <IconComponent iconName="square-outline" style={{ fontSize: '24px' }} />
            <IconComponent iconName="close-outline" style={{ fontSize: '24px', position: 'absolute', top: 0, right: 0 }} />
        </Box>
    )
}

function AisleSideIcon() {
    return (
        <Box sx={{ position: 'relative' }}>
            <IconComponent iconName="square" style={{ fontSize: '24px' }} />
            <IconComponent iconName="arrow-up-outline" style={{ fontSize: '22px', position: 'absolute', top: 1, right: 1, transform: 'rotate(45deg)', color: '#FFF' }} />
        </Box>
    )
}


function AnnotationBar({ activeTool, setActiveTool, callbackActions, disabled }) {
    const optionSelected = useRef(null);
    const anchorRef = useRef(null);
    const annotationToolsRef = useRef(null);
    const [preCurrentTool, setPreCurrentTool] = useState(null);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleClose = (option = null, hotKey = null) => {
        setOpen(false);
        anchorRef.current = null;
        optionSelected.current?.(option);
    };

    const needToWaitOption = useCallback((tool) => {
        return TOOLS_WITH_DRAWING_WAYS.includes(tool) ? optionSelected : null;
    }, []);

    const AnnotationTools = useMemo(() => ({
        [LAYOUT_TOOLS.MOVE]: {
            label: t('overseer_app.layouts.tools.move', 'Move'),
            hotKey: '1',
            icon: <IconComponent iconName="hand-left" style={{ fontSize: '24px' }} />,
        },
        [LAYOUT_TOOLS.ZONE]: {
            label: t('overseer_app.layouts.tools.zone', 'Zone'),
            hotKey: '2',
            icon: <IconComponent iconName="map-outline" style={{ fontSize: '24px' }} />,
        },
        [LAYOUT_TOOLS.AISLE]: {
            label: t('overseer_app.layouts.tools.aisle', 'Aisle/Fixture'),
            hotKey: '3',
            icon: <IconComponent iconName="square-outline" style={{ fontSize: '24px' }} />,
        },
        [LAYOUT_TOOLS.AISLE_SIDE]: {
            label: t('overseer_app.layouts.tools.aisle_side', 'Aisle Side'),
            hotKey: '4',
            icon: <AisleSideIcon />,
        },
        [LAYOUT_TOOLS.HOME]: {
            label: t('overseer_app.layouts.tools.home', 'Home'),
            hotKey: '5',
            icon: <IconComponent iconName="home" style={{ fontSize: '24px' }} />,
        },
        [LAYOUT_TOOLS.EXCLUSION_ZONE]: {
            label: t('overseer_app.layouts.tools.exclusion_zone', 'Exclusion Zone'),
            hotKey: '6',
            icon: <ExcludeZoneIcon />,
        },
    }), [t]);


    const FreeAnnotationTools = useMemo(() => ({
        [LAYOUT_TOOLS.FREE_AREA]: {
            label: t('overseer_app.layouts.tools.area', 'Area'),
            hotKey: '7',
            icon: <IconComponent iconName="square-outline" style={{ fontSize: '24px' }} />,
        },
        [LAYOUT_TOOLS.FREE_LINE]: {
            label: t('overseer_app.layouts.tools.line', 'Line'),
            hotKey: '8',
            icon: <AisleSideIcon />,
        },
        [LAYOUT_TOOLS.FREE_PIN]: {
            label: t('overseer_app.layouts.tools.pose', 'Pose'),
            hotKey: '9',
            icon: <IconComponent iconName="location-outline" style={{ fontSize: '24px' }} />,
        },
    }), [t]);

    const DrawingWays = useMemo(() => ({
        [DRAWING_WAYS.BBOX]: {
            drawingWay: DRAWING_WAYS.BBOX,
            label: t('overseer_app.layouts.tools.box', 'Box'),
        },
        [DRAWING_WAYS.ORIENTED_BBOX]: {
            drawingWay: DRAWING_WAYS.ORIENTED_BBOX,
            label: t('overseer_app.layouts.tools.oriented_box', 'Oriented Box'),
        },
        [DRAWING_WAYS.POLYGON]: {
            drawingWay: DRAWING_WAYS.POLYGON,
            label: t('overseer_app.layouts.tools.polygon', 'Polygon'),
        },
    }), [t]);

    const onChange = useCallback(async (event, newValue) => {
        if (disabled) return;
        const needToWait = needToWaitOption(newValue);
        let option = null;
        if (needToWait) {
            setOpen(true);
            anchorRef.current = event.currentTarget;
            setPreCurrentTool(newValue);
            option = await new Promise(resolve => {
                optionSelected.current = resolve;
            });
            setPreCurrentTool(null);
            if (!option) return;
        } else {
            setOpen(false);
            optionSelected.current?.(null);
            anchorRef.current = null;
        }
        setActiveTool([newValue, callbackActions?.[newValue] || null, option]);
    }, [setActiveTool, callbackActions, disabled, needToWaitOption]);

    const SubOptionsAvailable = useMemo(() => {
        return DRAWING_WAYS_TOOLS?.[preCurrentTool]?.map((way, index) => ({
            ...DrawingWays?.[way],
            hotKey: index + 1,
        }));
    }, [preCurrentTool, DrawingWays]);

    const handleListKeyDown = useCallback((event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
            anchorRef.current = null;
        } else if (event.key === 'Escape') {
            setOpen(false);
            anchorRef.current = null;
        }
        const options = SubOptionsAvailable?.reduce((acc, { hotKey, drawingWay }) => {
            acc[hotKey] = drawingWay;
            return acc;
        }, {});
        if (options?.[event?.key]) {
            handleClose(options[event.key], event.key);
            event.preventDefault();
            event.stopPropagation();
        }
    }, [SubOptionsAvailable]);

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen?.current === true && open === false) {
            anchorRef?.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const selectedToolOption = useMemo(() => {
        // eslint-disable-next-line no-unused-vars
        const [tool, _, option] = activeTool || [null, null, null];
        if (!TOOLS_WITH_DRAWING_WAYS.includes(tool) || !option) return null;
        const hotKey = DRAWING_WAYS_TOOLS?.[tool]?.findIndex((way) => way === option) + 1 || null;
        return {
            label: DrawingWays?.[option]?.label,
            hotKey,
        };
    }, [activeTool, DrawingWays]);
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 'auto', width: 'fit-content' }}>
            {selectedToolOption ? <Chip label={selectedToolOption?.label} size='small' color='primary' /> : null}
            <Paper elevation={0} sx={{ display: 'flex', boxShadow: (theme) => theme.shadows[1] }} ref={annotationToolsRef}>
                <AnnotationsTools value={activeTool} exclusive onChange={onChange} aria-label="annotations tools" ref={annotationToolsRef}>
                    {Object.entries(AnnotationTools).map(([tool, { label, hotKey, icon }]) => (
                        <HotKeyToggleButton key={tool} value={tool} aria-label={label} hotKey={hotKey} label={label} parentRef={annotationToolsRef} disableHotKey={open} subOption={selectedToolOption?.hotKey}>
                            {icon}
                        </HotKeyToggleButton>
                    ))}
                    <Box sx={{ alignItems: 'center', gap: 1, width: '100%', height: '100%', display: 'flex', position: 'relative' }}>
                        <Typography variant="body2" color="text.secondary" fontSize='8px' sx={{ position: 'absolute', top: 1, left: -15, transform: 'translateX(-50%)' }}>{t('overseer_app.layouts.tools.standard', 'Standard')}</Typography>
                        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                        <Typography variant="body2" color="text.secondary" fontSize='8px' sx={{ position: 'absolute', top: 1, right: -10, transform: 'translateX(50%)' }}>{t('overseer_app.layouts.tools.free', 'Free')}</Typography>
                    </Box>
                    {Object.entries(FreeAnnotationTools).map(([tool, { label, hotKey, icon }]) => (
                        <HotKeyToggleButton key={tool} value={tool} aria-label={label} hotKey={hotKey} label={label} parentRef={annotationToolsRef} disableHotKey={open} subOption={selectedToolOption?.hotKey}>
                            {icon}
                        </HotKeyToggleButton>
                    ))}
                </AnnotationsTools>
            </Paper>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom" transition sx={{ zIndex: 1000 }}>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'center' }}>
                        <Paper sx={{
                            minWidth: 160,
                            overflow: 'visible',
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: '37%',
                                width: 10,
                                height: 10,
                                backgroundColor: 'inherit',
                                transform: 'translateY(-50%) rotate(45deg)',
                                boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
                            }
                        }}>
                            <ClickAwayListener onClickAway={() => handleClose(null)} mouseEvent="onMouseDown">
                                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown} sx={{
                                    '& .MuiMenuItem-root': {
                                        '&:focus': {
                                            backgroundColor: '#EBEBEB',
                                        },
                                        '&:hover': {
                                            backgroundColor: '#F5F5F5',
                                        },
                                    },
                                }}>
                                    {SubOptionsAvailable?.map(({ label, hotKey, drawingWay }) => (
                                        <MenuItem onClick={() => handleClose(drawingWay, hotKey)} key={drawingWay}>
                                            <ListItemText sx={{ '& .MuiTypography-root': { fontSize: '12px' } }}>{label}</ListItemText>
                                            <Typography variant="body2" color="text.secondary" fontSize='10px'>{hotKey}</Typography>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper >
        </Box>
    )
}

export default memo(AnnotationBar);