import { useMemo, useState, memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemButton, ListItemText, Box, IconButton, List, Collapse, Tooltip, useTheme, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import natsort from 'natsort';
import { useSelector } from 'react-redux';
import FallbackInformation from './FallbackInformation';
import { EDITABLE_LAYERS, POSES_LAYER, NO_EDITABLE_LAYERS } from '../tools/useLayout';
import { boldString } from "../Utils";

function LayerChildChildItem({ layer, item, subLayer, deleteAnnotation, setDialogEdit }) {
    const [isHovered, setIsHovered] = useState(false);
    const handleEdit = useCallback(() => {
        setDialogEdit({
            layer: EDITABLE_LAYERS.AISLE_SIDE,
            item: item,
            data: {
                name: subLayer,
                parent: item,
            },
        });
    }, [item, subLayer, setDialogEdit]);
    return <ListItemButton dense sx={{ pl: 10 }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <ListItemText primary={subLayer} />
        <Box sx={{ display: 'flex', gap: 0.5, ml: 'auto', visibility: isHovered ? 'visible' : 'hidden' }}>
            <IconButton size='small' onClick={handleEdit}>
                <IconComponent iconName='create-outline' />
            </IconButton>
            <IconButton size='small' onClick={() => deleteAnnotation(layer?.name, item, subLayer)}>
                <IconComponent iconName='trash-outline' />
            </IconButton>
        </Box>
    </ListItemButton>;
}

function LayerChildItem({ layer, item, toggleChildLayer, setDialogEdit, deleteAnnotation }) {
    const [isHovered, setIsHovered] = useState(false);
    const [open, setOpen] = useState(false);
    const sortedSubLayers = useMemo(() => (layer?.layers?.[item]?.subLayers?.sort(natsort({ insensitive: true }))), [layer, item]);
    const handleEdit = useCallback(() => {
        setDialogEdit({
            layer: layer?.name,
            item: item,
            data: layer?.layers[item],
        });
        setOpen(true);
    }, [layer, item, setDialogEdit]);

    const { t } = useTranslation();
    const theme = useTheme();
    const hasWarning = useMemo(() => layer?.layers[item]?.warning, [layer, item]);

    return (
        <>
            <ListItemButton sx={{ pl: 4 }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <IconButton size='small' onClick={(e) => {
                    e.stopPropagation();
                    setOpen(prev => !prev);
                }} sx={{ visibility: Object.keys(layer?.layers[item]?.subLayers || {}).length > 0 ? 'visible' : 'hidden', mr: 1 }}>
                    <IconComponent iconName={open ? 'chevron-up-outline' : 'chevron-down-outline'} />
                </IconButton>
                <ListItemText primary={
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography variant='body2' sx={{ fontStyle: hasWarning ? 'italic' : 'normal', textWrap: 'nowrap' }}>{item}</Typography>
                        {layer?.layers[item]?.warning_old_name && <Typography variant='body2' sx={{ color: 'gray.main', textWrap: 'nowrap' }}>
                            {`| ${t('overseer_app.layouts.previous', 'Previously')} ${layer?.layers[item]?.warning_old_name}`}
                        </Typography>}
                        {hasWarning && <Tooltip title={t('overseer_app.layouts.needs_review', 'Needs review')} arrow>
                            <span><IconComponent iconName='warning-outline' style={{ color: theme.palette.error.main }} /></span>
                        </Tooltip>}
                    </Box>
                } />
                <Box sx={{ display: 'flex', gap: 0.5, ml: 'auto', visibility: isHovered ? 'visible' : 'hidden', "& #visibility": { visibility: !layer?.layers[item]?.visible || isHovered ? 'visible' : 'hidden' } }}>
                    {!NO_EDITABLE_LAYERS.includes(layer?.name) && (
                        <IconButton size='small' onClick={handleEdit}>
                            <IconComponent iconName='create-outline' />
                        </IconButton>
                    )}

                    <IconButton size='small' onClick={() => deleteAnnotation(layer?.name, item)} id="delete">
                        <IconComponent iconName='trash-outline' />
                    </IconButton>
                    <IconButton size='small' onClick={() => toggleChildLayer(layer?.name, item)} id="visibility">
                        <IconComponent iconName={layer?.layers[item]?.visible ? 'eye-outline' : 'eye-off-outline'} />
                    </IconButton>
                </Box>
            </ListItemButton>
            {layer?.layers[item]?.subLayers && <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {sortedSubLayers?.map(subLayer => (
                        <LayerChildChildItem key={subLayer} layer={layer} item={item} subLayer={subLayer} deleteAnnotation={deleteAnnotation} setDialogEdit={setDialogEdit} />
                    ))}
                </List>
            </Collapse>}
        </>
    );
}

function LayerItem({ layer, toggles, setDialogEdit, deleteAnnotation }) {
    const [open, setOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const sortedChildrenLayers = useMemo(() => (Object.keys(layer?.layers || {}).sort(natsort({ insensitive: true }))), [layer]);
    const { t } = useTranslation();
    const theme = useTheme();
    const existsWarnings = useMemo(() => Object.values(layer?.layers || {}).filter(layer => layer?.warning).length, [layer]);
    return (
        <>
            <ListItemButton key={layer?.name} dense onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <IconButton size='small' onClick={(e) => {
                    e.stopPropagation();
                    setOpen(prev => !prev);
                }} sx={{ visibility: Object.keys(layer?.layers || {}).length > 0 ? 'visible' : 'hidden', mr: 1 }}>
                    <IconComponent iconName={open ? 'chevron-up-outline' : 'chevron-down-outline'} />
                </IconButton>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <ListItemText primary={layer?.name} />
                    {existsWarnings > 0 && <Tooltip title={t('overseer_app.layouts.needs_review_count', 'Needs review {{count}} items', { count: existsWarnings })} arrow>
                        <span><IconComponent iconName='warning-outline' style={{ color: theme.palette.error.main }} /></span>
                    </Tooltip>}
                </Box>
                <Box sx={{ display: 'flex', gap: 1, visibility: isHovered || !layer?.visible ? 'visible' : 'hidden', ml: 'auto' }}>
                    <IconButton size='small' onClick={(e) => {
                        e.stopPropagation();
                        toggles?.parent(layer?.name);
                    }}>
                        <IconComponent iconName={layer?.visible ? 'eye-outline' : 'eye-off-outline'} />
                    </IconButton>
                </Box>
                {layer?.protected && <Tooltip title={t('overseer_app.layouts.protected_layer', 'Protected layer')} arrow>
                    <span><IconComponent iconName='shield-outline' /></span>
                </Tooltip>}
            </ListItemButton>
            {layer?.layers && <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {sortedChildrenLayers?.map(item => (
                        <LayerChildItem key={item} layer={layer} item={item} toggleChildLayer={toggles?.child} setDialogEdit={setDialogEdit} deleteAnnotation={deleteAnnotation} />
                    ))}
                </List>
            </Collapse>}
        </>
    );
}


function LayersSidebar({ layers, toggles, setDialogEdit, deleteAnnotation }) {
    const emptyLayers = useMemo(() => (Object.keys(layers || {}).filter(layer => Object.keys(layers[layer]?.layers || {}).length === 0)).sort(natsort({ insensitive: true })), [layers]);
    const sortedLayers = useMemo(() => (
        [...Object.keys(layers || {}).filter(layer => Object.keys(layers?.[layer]?.layers || {}).length > 0).sort(natsort({ insensitive: true })), ...emptyLayers]
    ), [layers, emptyLayers]);

    const { storeMapLayout } = useSelector(state => state.layouts);
    const { t } = useTranslation();
    const confirmRef = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [layerToDelete, setLayerToDelete] = useState(null);

    const handleDeleteAnnotation = useCallback(async (layer, annotation, subAnnotation = null) => {
        setLayerToDelete({ layer, annotation, subAnnotation });
        setConfirmOpen(true);
        const confirm = await new Promise(resolve => confirmRef.current = resolve);
        if (confirm) {
            deleteAnnotation(layer, annotation, subAnnotation);
        }
    }, [deleteAnnotation]);

    const handleConfirm = useCallback(() => {
        setConfirmOpen(false);
        confirmRef.current(true);
    }, []);

    const handleCancel = useCallback(() => {
        setConfirmOpen(false);
        confirmRef.current(false);
    }, []);

    return storeMapLayout?.id ? (
        <>
            <List>
                {sortedLayers.map(layer => (
                    <LayerItem key={layer} layer={layers[layer]} toggles={toggles} setDialogEdit={setDialogEdit} deleteAnnotation={handleDeleteAnnotation} />
                ))}
            </List>
            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
                <DialogTitle>{t('overseer_app.layouts.delete_annotation', 'Delete annotation')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {boldString(layerToDelete?.layer === POSES_LAYER ? t('overseer_app.layouts.delete_pose_text', 'Are you sure you want to delete this pose? This action will **remove all robot’s** poses (base, exit_jail and dock).') : t('overseer_app.layouts.delete_annotation_text', 'Are you sure you want to delete this annotation? This action **cannot be undone**, and all related references will also be removed.'))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">{t('overseer_app.layouts.cancel', 'Cancel')}</Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">{t('overseer_app.layouts.confirm', 'Confirm')}</Button>
                </DialogActions>
            </Dialog>
        </>
    ) : <FallbackInformation>{t('overseer_app.layouts.error_getting_store_map', 'Error getting store map')}</FallbackInformation>;
}

export default memo(LayersSidebar);
