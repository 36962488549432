export const ZIPPEDI_ICON_COLOR = '#1A242D';

export const DEBOUNCE_WAIT_TIME = 700;

export const secretManagerURL = (project_id, secret_id) => {
  return `https://console.cloud.google.com/security/secret-manager/secret/${secret_id}/versions?project=${project_id}`;
};

export const changesColor = (changeType) => {
  const dict = {
    add: '#5D9C59',
    edit: '#0079FF',
    delete: '#da433b',
  };

  return dict[changeType];
};

export function checkPermission(navigationOptions, viewId) {
  let view = navigationOptions.find(
    (element) => element.description === viewId
  );
  return view;
}

export function boldString(string) {
  const parts = string.split(/\*\*(.*?)\*\*/g);
  return parts.map((part, index) =>
    index % 2 === 1 ? <strong key={index}>{part}</strong> : part
  );
}

export function translateIcon(icon) {
  const dict = {
    'fa-home': 'home',
    'fa-hand-paper': 'hand-right',
    'fa-lock': 'lock-closed',
    'fa-skull': 'skull',
    'fa-stop-circle': 'stop-circle',
    'fa-fire': 'flame',
    'fa-camera': 'camera',
    'fa-wifi': 'wifi',
    'fa-pause-circle': 'pause-circle',
    'fa-battery-empty': 'battery-dead',
    'fa-ban': 'ban',

    'fa-road': null,
    'fa-door-closed': null,
    'fa-camera-retro': null,
    'fa-camera-cctv': null,

    'fa-wifi-slash': null,
    'fa-charging-station': null,
    'fa-solid fa-camera-retro': null,

    'fa-compass-slash': null,
    'fa-skull-crossbones': null,
    'fa-door-open': null,
    'fa-arrows-alt': null,

    'fa-microchip': null,
    'fa-map-marked-alt': null,
  };

  return dict[icon];
}

export function colorIcon(severity) {
  const dict = {
    error: '#da433b',
    warn: '#F4B536',
    fatal: 'black',
    info: '#5D9C59',
    debug: '#9575DE',
    trace: '#0079FF',
  };

  return dict[severity];
}

export function a11yProps(index, { textTransform = 'uppercase' }) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { fontSize: '1rem', textTransform: textTransform },
  };
}

export const transformRobot2Pixels = (
  xRobot,
  yRobot,
  angleRobot,
  origin,
  resolution,
  height
) => {
  let ang = angleRobot + Math.PI / 2,
    x = (xRobot - origin[0]) / resolution,
    y = height - (yRobot - origin[1]) / resolution;
  return [x, y, ang];
};

export const transformPixels2Robot = (
  pixelX,
  pixelY,
  origin,
  resolution,
  height
) => {
  let x = resolution * pixelX + origin[0];
  let y = resolution * (height - pixelY) + origin[1];
  return [x, y];
};

export const normalizeAngle = (angle) => {
  while (angle > Math.PI) {
    angle -= 2 * Math.PI;
  }
  while (angle < -Math.PI) {
    angle += 2 * Math.PI;
  }
  return angle === -0 ? 0 : angle;
};

export const LAYER_OPTIONS = [
  { id: 'robot', name: 'Robot' },
  { id: 'lidar', name: 'Lidar' },
  { id: 'navigation_path', name: 'Navigation Path' },
];

export const CONNECTIVITY_OPTIONS = [
  { id: '4G', name: '4G' },
  { id: 'wifi', name: 'Wifi' },
  { id: 'full', name: '4G + Wifi' },
];

export const MAPPING_ALERT_OPTIONS = [
  { id: 'mapping_alert', name: 'Mapping Alert' },
];

export const SINGLE_OPTIONS = [
  { title: 'Connectivity', items: CONNECTIVITY_OPTIONS },
  { title: 'Mapping', items: MAPPING_ALERT_OPTIONS },
];

export const normalizeBbox = (bbox) => {
  const { start, end } = bbox;
  return {
    start: { x: Math.min(start.x, end.x), y: Math.min(start.y, end.y) },
    end: { x: Math.max(start.x, end.x), y: Math.max(start.y, end.y) },
  };
};
export const existCollision = (firstBbox, secondBbox, intersection = 'partial') => {
  const { start: firstStart, end: firstEnd } = firstBbox;
  const { start: secondStart, end: secondEnd } = secondBbox;
  const { start: normalizedFirstStart, end: normalizedFirstEnd } = normalizeBbox({ start: firstStart, end: firstEnd });
  const { start: normalizedSecondStart, end: normalizedSecondEnd } = normalizeBbox({ start: secondStart, end: secondEnd });
  const clauses = {
    'full': () => {
      return normalizedFirstStart.x >= normalizedSecondStart.x
        && normalizedFirstEnd.x <= normalizedSecondEnd.x
        && normalizedFirstStart.y >= normalizedSecondStart.y
        && normalizedFirstEnd.y <= normalizedSecondEnd.y;
    },
    'partial': () => {
      return normalizedFirstStart.x <= normalizedSecondEnd.x
        && normalizedFirstEnd.x >= normalizedSecondStart.x
        && normalizedFirstStart.y <= normalizedSecondEnd.y
        && normalizedFirstEnd.y >= normalizedSecondStart.y;
    }
  };
  const result = clauses?.[intersection] || clauses['partial'];
  return result?.();
};

export const isPointInside = (point, rectStart, rectEnd) => {
  return point.x >= rectStart.x && point.x <= rectEnd.x &&
    point.y >= rectStart.y && point.y <= rectEnd.y;
};

/**
 * Liang Barsky algorithm to calculate the intersection between a line and a rectangle
 * Ref: https://en.wikipedia.org/wiki/Liang%E2%80%93Barsky_algorithm
 */
export const liangBarsky = (lineStart, lineEnd, rectStart, rectEnd) => {
  let t0 = 0, t1 = 1;
  const dx = lineEnd.x - lineStart.x;
  const dy = lineEnd.y - lineStart.y;

  const p = [-dx, dx, -dy, dy];
  const q = [
    lineStart.x - rectStart.x,
    rectEnd.x - lineStart.x,
    lineStart.y - rectStart.y,
    rectEnd.y - lineStart.y,
  ];

  for (let i = 0; i < 4; i++) {
    if (p[i] === 0) {
      if (q[i] < 0) return null;
    } else {
      const t = q[i] / p[i];
      if (p[i] < 0) t0 = Math.max(t0, t);
      else t1 = Math.min(t1, t);
    }
  }
  if (t0 < t1) {
    const startX = lineStart.x + t0 * dx;
    const startY = lineStart.y + t0 * dy;
    const endX = lineStart.x + t1 * dx;
    const endY = lineStart.y + t1 * dy;
    return { start: { x: startX, y: startY }, end: { x: endX, y: endY } };
  }
  return null;
};

export const calculateCoverage = (intersectionLine) => {
  if (!intersectionLine) return null;
  const dx = intersectionLine.end.x - intersectionLine.start.x;
  const dy = intersectionLine.end.y - intersectionLine.start.y;
  const length = Math.sqrt(dx * dx + dy * dy);
  return length > 0 ? length : null;
};

export const calculateBoundingBox = (start, middle, end) => {
  const fourthPoint = {
    x: start.x + (end.x - middle.x),
    y: start.y + (end.y - middle.y)
  };
  const corners = [start, middle, end, fourthPoint];
  const xs = corners.map(p => p.x);
  const ys = corners.map(p => p.y);
  const minX = Math.min(...xs);
  const maxX = Math.max(...xs);
  const minY = Math.min(...ys);
  const maxY = Math.max(...ys);
  return [
    { x: minX, y: minY },
    { x: maxX, y: maxY }
  ];
}

export const calculatePolygonBoundingBox = (points) => {
  const xs = points.map(p => p.x);
  const ys = points.map(p => p.y);
  const minX = Math.min(...xs);
  const maxX = Math.max(...xs);
  const minY = Math.min(...ys);
  const maxY = Math.max(...ys);
  return [
    { x: minX, y: minY },
    { x: maxX, y: maxY }
  ];
}
