import React, { useEffect, useState } from 'react';
import {
  Paper,
  Stack,
  Tabs,
  Tab,
  Grid,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../tools/utils';
import TabPanel from '../tools/TabPanelv2';
import ListJobs from './ListJobs';
import MapComponent from './MapComponent';

export default function MappingView(props) {
  const {
    bucketMapLayout,
    mappingJobsList,
    loadingMappingJobs,
    availableStoresDict,
    availableFloorsList,
    setSnackAlert,
    getMappingJobs,
    getBucketMapLayout,
    getAvailableStores,
    getAvailableFloors,
    isLoadingBucketMapLayout,
    postMappingJob,
    postMappingJobResponse,
    isLoadingPostMappingJob,
    deleteMappingJob,
    deleteJobResponse,
    isLoadingDeleteJob,
    patchMappingJob,
    jobUpdateResponse,
    isLoadingJobUpdate,
  } = props;

  const { t } = useTranslation();

  const [tab, setTab] = useState(0);
  const [selectedJob, setSelectedJob] = useState(null);
  const [availableStoresList, setAvailableStoresList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [creatingNewJob, setCreatingNewJob] = useState(false);
  const [pendingJobs, setPendingJobs] = useState([]);
  const [inReviewJobs, setInReviewJobs] = useState([]);
  const [readyJobs, setReadyJobs] = useState([]);
  const [mode, setMode] = useState(null);
  const [selectedMaps, setSelectedMaps] = useState([{}, {}, {}]);

  useEffect(() => {
    getMappingJobs();
    getAvailableStores();
    setMode('pending');
  }, []);

  useEffect(() => {
    if (bucketMapLayout) {
      setSelectedMaps((prev) => {
        const newList = [...prev];
        newList[tab] = bucketMapLayout;
        return newList;
      });
    }
  }, [bucketMapLayout]);

  useEffect(() => {
    setPendingJobs(mappingJobsList.filter((item) => item.state === 'Pending'));
    setInReviewJobs(
      mappingJobsList.filter((item) => item.state === 'In Review')
    );
    setReadyJobs(
      mappingJobsList.filter((item) =>
        ['Accepted', 'Applied'].includes(item.state)
      )
    );
  }, [mappingJobsList]);

  useEffect(() => {
    if (postMappingJobResponse) getMappingJobs();
  }, [postMappingJobResponse]);

  useEffect(() => {
    if (deleteJobResponse) getMappingJobs();
  }, [deleteJobResponse]);

  useEffect(() => {
    if (jobUpdateResponse) getMappingJobs();
  }, [jobUpdateResponse]);

  useEffect(() => {
    setAvailableStoresList(availableStoresDict?.stores);
  }, [availableStoresDict]);

  useEffect(() => {
    if (creatingNewJob) {
      getBucketMapLayout({
        store: selectedStore.store_id,
        floor: selectedFloor,
      });
    }
  }, [creatingNewJob]);

  useEffect(() => {
    // store and floor
    let robotCode = selectedJob?.robot_id.split('-');
    if (robotCode)
      getBucketMapLayout({ store: robotCode[0], floor: robotCode[1] });
  }, [selectedJob]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    switch (newValue) {
      case 0:
        setMode('pending');
        break;
      case 1:
        setMode('review');
        break;
      case 2:
        setMode('submit');
        break;
      default:
        setMode('pending');
        break;
    }
  };

  return (
    <Stack component={Paper} sx={{ minHeight: 'calc(100vh - 15em)' }}>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label={t('overseer_app.mapping.pending_jobs', 'Pending Jobs')} />
        <Tab
          label={t('overseer_app.mapping.jobs_in_review', 'Jobs In Review')}
        />
        <Tab label={t('overseer_app.mapping.submit_map', 'Submit Map')} />
      </Tabs>
      <TabPanel value={tab} index={0} sx={{ p: 2, minHeight: 'inherit' }}>
        <Grid container height={'80vh'}>
          <Grid item xs={3}>
            {loadingMappingJobs ? (
              <LinearProgress
                sx={{ width: '95%', mx: 'auto' }}
                color="secondary"
              />
            ) : (
              <ListJobs
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                jobs={pendingJobs}
                availableStores={availableStoresList}
                getAvailableFloors={getAvailableFloors}
                availableFloors={availableFloorsList}
                setCreatingNewJob={setCreatingNewJob}
                selectedStore={selectedStore}
                setSelectedStore={setSelectedStore}
                selectedFloor={selectedFloor}
                setSelectedFloor={setSelectedFloor}
                deleteMappingJob={deleteMappingJob}
                getBucketMapLayout={getBucketMapLayout}
              />
            )}
          </Grid>
          <Grid item xs={9} style={{ position: 'relative' }}>
            <MapComponent
              tabIndex={tab}
              mode={mode}
              creatingNewJob={creatingNewJob}
              selectedJob={selectedJob}
              mapInfo={selectedMaps[tab]}
              storeInfo={selectedStore}
              multiRobotId={selectedFloor}
              isLoadingBucketMapLayout={isLoadingBucketMapLayout}
              postMappingJob={postMappingJob}
              postMappingJobResponse={postMappingJobResponse}
              patchMappingJob={patchMappingJob}
            />
            {(isLoadingPostMappingJob || isLoadingJobUpdate) && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 10,
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1} sx={{ p: 2, minHeight: 'inherit' }}>
        <Grid container height={'80vh'}>
          <Grid item xs={3}>
            {loadingMappingJobs ? (
              <LinearProgress
                sx={{ width: '95%', mx: 'auto' }}
                color="secondary"
              />
            ) : (
              <ListJobs
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                jobs={inReviewJobs}
                availableStores={availableStoresList}
                getAvailableFloors={getAvailableFloors}
                availableFloors={availableFloorsList}
                setCreatingNewJob={setCreatingNewJob}
                selectedStore={selectedStore}
                setSelectedStore={setSelectedStore}
                selectedFloor={selectedFloor}
                setSelectedFloor={setSelectedFloor}
                deleteMappingJob={deleteMappingJob}
                getBucketMapLayout={getBucketMapLayout}
              />
            )}
          </Grid>
          <Grid item xs={9} style={{ position: 'relative' }}>
            <MapComponent
              tabIndex={tab}
              mode={mode}
              creatingNewJob={creatingNewJob}
              selectedJob={selectedJob}
              mapInfo={selectedMaps[tab]}
              storeInfo={selectedStore}
              multiRobotId={selectedFloor}
              isLoadingBucketMapLayout={isLoadingBucketMapLayout}
              postMappingJob={postMappingJob}
              postMappingJobResponse={postMappingJobResponse}
              patchMappingJob={patchMappingJob}
            />
            {(isLoadingPostMappingJob || isLoadingJobUpdate) && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 10,
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={2} sx={{ p: 2, minHeight: 'inherit' }}>
        <Grid container height={'80vh'}>
          <Grid item xs={3}>
            {loadingMappingJobs ? (
              <LinearProgress
                sx={{ width: '95%', mx: 'auto' }}
                color="secondary"
              />
            ) : (
              <ListJobs
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                jobs={readyJobs}
                availableStores={availableStoresList}
                getAvailableFloors={getAvailableFloors}
                availableFloors={availableFloorsList}
                setCreatingNewJob={setCreatingNewJob}
                selectedStore={selectedStore}
                setSelectedStore={setSelectedStore}
                selectedFloor={selectedFloor}
                setSelectedFloor={setSelectedFloor}
                deleteMappingJob={deleteMappingJob}
                getBucketMapLayout={getBucketMapLayout}
              />
            )}
          </Grid>
          <Grid item xs={9} style={{ position: 'relative' }}>
            <MapComponent
              tabIndex={tab}
              mode={mode}
              creatingNewJob={creatingNewJob}
              selectedJob={selectedJob}
              mapInfo={selectedMaps[tab]}
              storeInfo={selectedStore}
              multiRobotId={selectedFloor}
              isLoadingBucketMapLayout={isLoadingBucketMapLayout}
              postMappingJob={postMappingJob}
              postMappingJobResponse={postMappingJobResponse}
              patchMappingJob={patchMappingJob}
            />
            {(isLoadingPostMappingJob || isLoadingJobUpdate) && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 10,
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            )}
          </Grid>
        </Grid>
      </TabPanel>
    </Stack>
  );
}
