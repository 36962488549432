import { format } from 'date-fns';

export function hasOnlyOneValueForThisKey(array, keyToCheck) {
  const val = [];

  for (const obj of array) {
    if (Object.prototype.hasOwnProperty.call(obj, keyToCheck)) {
      val.push(obj[keyToCheck]);
      if (val.length > 1) {
        return false;
      }
    }
  }

  return true;
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
}

export function toTitleCase(string) {
  return string.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

export function convertToUserTimezone(
  dateString,
  userTimezone,
  targetFormat = 'yyyy-MM-dd HH:mm:ss'
) {
  if (dateString) {
    // Convert the date string to a Date object
    const date = new Date(
      dateString.includes('Z') ? dateString : `${dateString}Z`
    );

    const formattedDate = format(date, targetFormat);

    return formattedDate;
  }
  return null;
}

export function getUserTimezone() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimezone;
}

export function maskPhoneNumber(phone, hiddenNumbers = 8) {
  return `${'X'.repeat(hiddenNumbers)}${phone.slice(hiddenNumbers)}`;
}

export function getWeekOfMonth(date) {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayOfWeek = firstDayOfMonth.getDay();
  const adjustedDate = date.getDate() + dayOfWeek;
  return Math.ceil(adjustedDate / 7);
}


export function formatLanguageDate(date, locale = "en") {
  try {
    return new Intl.DateTimeFormat(locale, {
      day: "2-digit",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(new Date(date));
  } catch (error) {
    return date;
  }
}

export function compareObjects(A, B) {
  if (Object.keys(A || {}).length !== Object.keys(B || {}).length) {
    return false;
  }
  for (const key in A) {
    if (typeof A[key] === "object" && typeof B[key] === "object") {
      if (!compareObjects(A[key], B[key])) {
        return false;
      }
    } else if (A[key] !== B[key]) {
      return false;
    }
  }
  return true;
}

export function isValidJson(string) {
  try {
    JSON.parse(string);
    return true;
  } catch (error) {
    return false;
  }
}