import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Add } from '@mui/icons-material';
import ConfirmDialog from './ConfirmDialog';

export default function ListJobs(props) {
  const {
    jobs,
    selectedJob,
    setSelectedJob,
    availableStores,
    availableFloors,
    selectedStore,
    selectedFloor,
    setSelectedStore,
    setSelectedFloor,
    setCreatingNewJob,
    getAvailableFloors,
    isLoadingAvailableStores,
    isLoadingAvailableFloors,
    deleteMappingJob,
    getBucketMapLayout,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [openConfigJob, setOpenConfigJob] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (selectedStore) getAvailableFloors({ store: selectedStore?.store_id });
  }, [selectedStore]);

  useEffect(() => {
    if (availableFloors.length === 1) {
      setSelectedFloor(availableFloors[0]);
    }
  }, [availableFloors]);

  const checkColorState = (state) => {
    let color;
    switch (state) {
      case 'Pending':
        color = theme.palette.warning.main;
        break;
      case 'In Review':
        color = theme.palette.info.main;
        break;
      case 'Cancelled':
        color = theme.palette.error.main;
        break;
      case 'Accepted':
        color = theme.palette.green.main;
        break;
      case 'Applied':
        color = theme.palette.success.main;
        break;
      default:
        break;
    }
    return color;
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewJob = () => {
    setOpenConfigJob(true);
  };

  const filteredJobs = jobs.filter((job) => {
    const matchesSearch = `${job.store_code} ${job.name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  const handleSelectedJob = (job) => {
    setSelectedJob(job);
    setSelectedStore({ store_id: job.store_code, name: job.name });
    setSelectedFloor(job.multirobot_id);
    setCreatingNewJob(false);
  };

  const onClose = () => {
    setOpenConfigJob(false);
  };

  const onSaveConfig = () => {
    setOpenConfigJob(false);
    setCreatingNewJob(true);
    getBucketMapLayout({
      store: selectedStore.store_id,
      floor: selectedFloor,
    });
    setSelectedJob(null);
  };

  const handleDeleteJob = (store, job_id) => {
    setSelectedItem({ store, job_id });
    setOpenConfirmDialog(true);
    setDialogTitle(t('overseer_app.mapping.confirm_delete_title', 'Delte Job'));
    setDialogMessage(
      t(
        'overseer_app.mapping.confirm_delete msg',
        `You are about to delete the job for the ${store} store with ID ${job_id}. Do you want to continue?`,
        { store: store, job_id: job_id }
      )
    );
  };

  const handleConfirm = () => {
    deleteMappingJob(selectedItem.job_id);
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Grid
        container
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Grid item xs={9}>
          <TextField
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search stores"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={t('overseer_app.general.add_store', 'Add new store')}>
            <IconButton>
              <Add color="primary" onClick={handleNewJob} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {filteredJobs ? (
        <List sx={{ maxHeight: '75vh', overflow: 'auto' }}>
          {filteredJobs &&
            filteredJobs.map((item, index) => (
              <ListItem
                secondaryAction={
                  <IconButton edge="end">
                    <DeleteIcon
                      onClick={() =>
                        handleDeleteJob(item.store_code, item.mapping_job_id)
                      }
                    />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton
                  selected={selectedJob === item}
                  onClick={() => handleSelectedJob(item)}
                >
                  <ListItemText
                    primary={`${item.store_code} - ${item.name} / ${t(
                      'overseer_app.general.floor',
                      'Floor'
                    )} ${item.multirobot_id}`}
                    secondary={
                      <>
                        <Typography variant="body2">{`${item?.job_content.zones.length} zones to remap`}</Typography>
                        <Typography
                          variant="body2"
                          color={checkColorState(item.state)}
                        >
                          {item.state}
                        </Typography>
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      ) : null}
      <Dialog open={openConfigJob} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ marginBottom: -1 }}>
          {t('overseer_app.mapping.create_job', 'New Mapping Job')}
        </DialogTitle>
        <DialogContent>
          <Grid container item xs={12} spacing={2} padding={2}>
            <Typography>
              {t(
                'overseer_app.mapping.job_config_description',
                'Select a store and floor to start creating the mapping job:'
              )}
            </Typography>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                defaultValue={null}
                value={selectedStore}
                options={availableStores}
                loading={isLoadingAvailableStores}
                disabled={isLoadingAvailableStores}
                getOptionLabel={(option) => option?.store_id}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                groupBy={(option) => option.chain_name}
                onChange={(event, newValue) => {
                  setSelectedStore(newValue);
                  setSelectedFloor(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      'overseer_app.layouts.select_store',
                      'Select store'
                    )}
                  />
                )}
                noOptionsText={t(
                  'overseer_app.layouts.no_stores_found',
                  'No stores found'
                )}
                renderGroup={(params) => (
                  <div key={params.key}>
                    <Box
                      sx={{
                        position: 'sticky',
                        top: -8,
                        zIndex: 1,
                        backgroundColor: '#f5f5f5',
                        p: 1,
                      }}
                    >
                      <Typography variant="caption" color="textSecondary">
                        {params.group}
                      </Typography>
                    </Box>
                    {params.children}
                  </div>
                )}
              />
              <LinearProgress
                sx={{
                  width: '100%',
                  mx: 'auto',
                  top: '-5px',
                  visibility: isLoadingAvailableStores ? 'visible' : 'hidden',
                }}
                color="secondary"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                defaultValue={null}
                options={
                  availableFloors?.map?.((floor) => ({
                    label: '' + floor,
                    value: '' + floor,
                  })) || []
                }
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                loading={isLoadingAvailableFloors}
                disabled={isLoadingAvailableFloors || !selectedStore}
                onChange={(event, newValue) => setSelectedFloor(newValue)}
                value={selectedFloor}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      'overseer_app.layouts.select_floor',
                      'Select floor'
                    )}
                  />
                )}
                noOptionsText={t(
                  'overseer_app.layouts.no_floors_found',
                  'No floors found'
                )}
              />
              <LinearProgress
                sx={{
                  width: '100%',
                  mx: 'auto',
                  top: '-5px',
                  visibility: isLoadingAvailableFloors ? 'visible' : 'hidden',
                }}
                color="secondary"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            {t('overseer_app.layouts.cancel', 'Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={onSaveConfig}>
            {t('overseer_app.general.start', 'Start')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openConfirmDialog}
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={handleConfirm}
        onCancel={() => setOpenConfirmDialog(false)}
      />
    </>
  );
}
