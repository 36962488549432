import { Alert, Collapse, Grid, MenuItem, TextField } from "@mui/material";
import { forwardRef, useImperativeHandle, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AISLES_LAYER, ZONES_LAYER, POSES_LAYER, EXCLUSION_LAYER } from "../tools/useLayout";
import natsort from "natsort";
import { DRAWING_WAYS, LAYOUT_TOOLS } from "../tools/useLayoutAnnotations";

const DEFAULT_COLOR = null
export const EXCLUDED_LAYERS = [
    AISLES_LAYER,
    ZONES_LAYER,
    POSES_LAYER,
    EXCLUSION_LAYER,
]
const DEFAULT_VALUES = {
    layer: '',
    color: DEFAULT_COLOR,
    name: '',
    clientName: '',
}

export default forwardRef(function DialogCreationFree({
    addNewLayer,
    dialogData,
    isUniqueName,
    globalLayers,
    addNewPose
}, ref) {

    const [formState, setFormState] = useState(DEFAULT_VALUES);
    const [errors, setErrors] = useState({});
    const filteredLayers = useMemo(() => globalLayers?.filter(layer => !EXCLUDED_LAYERS.includes(layer)).sort(natsort({ insensitive: true })) || [], [globalLayers]);
    const { t } = useTranslation();
    const validateField = useCallback((field, value) => {
        const validationRules = {
            layer: { required: true },
            name: { required: true, unique: true, notAllowSpaces: true },
        };
        const rule = validationRules?.[field];
        if (rule && rule.required && (!value || value === ''))
            return 'This field is required';
        if (rule && rule.unique && !isUniqueName(value, formState?.layer))
            return 'This name is already in use';
        return null;
    }, [formState?.layer, isUniqueName]);


    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) errors[field] = error;
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) return false;
        return true;
    }, [formState, validateField]);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);

    const existsError = useMemo(() => Object.keys(errors).filter(key => !!errors[key]).length > 0, [errors]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return false;
        const { data, tool } = dialogData;
        const { name, clientName, layer } = formState;
        if (tool === LAYOUT_TOOLS.FREE_PIN) {
            const { x, y, ang } = data;
            addNewPose({ name, clientName, x, y, ang, layer });
            return true;
        }
        const types = {
            [LAYOUT_TOOLS.FREE_AREA]: DRAWING_WAYS.RECTANGLE,
            [LAYOUT_TOOLS.FREE_LINE]: DRAWING_WAYS.LINE,
        }
        const type = types[tool];
        if (!type) return false;
        const { start, end, drawing_way = null, points = null } = data;
        const bbox = [
            { x: start[0], y: start[1] },
            { x: end[0], y: end[1] }
        ]
        addNewLayer(layer, {
            name,
            type,
            ...(type === DRAWING_WAYS.RECTANGLE && {
                bbox,
                ...(drawing_way && { drawing_way }),
                ...(points && { points }),
            }),
            ...(type === DRAWING_WAYS.LINE && { map_points: bbox }),
            color: null,
            clientName,
        });
        return true;
    }, [validateAllFields, formState, dialogData, addNewPose, addNewLayer]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }), [onSave]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Collapse in={existsError}>
                    <Alert severity="error">
                        {t('overseer_app.layouts.creation.default_error', 'Some fields are required or must be unique to create a new annotation in this Layer.')}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.layer', 'Layer')} fullWidth required select value={formState?.layer} onChange={(e) => handleFieldChange('layer', e.target.value)} error={!!errors?.layer}>
                    {filteredLayers?.map((name) => (
                        <MenuItem key={name} value={name}>{name}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.annotation_name', 'Annotation name')} fullWidth required value={formState?.name} onChange={(e) => handleFieldChange('name', e.target.value)} error={!!errors?.name} helperText={t('overseer_app.layouts.creation.not_allow_spaces', 'This field does not allow spaces')} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.client_annotation_name', 'Client\'s annotation name')} fullWidth value={formState?.clientName} onChange={(e) => handleFieldChange('clientName', e.target.value)} />
            </Grid>
        </Grid>
    )
})