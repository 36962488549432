// instantiate services and wire up dependencies
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// zippedi tools js imports
import { ReduxLoginWeb as PrivateRoute } from '@zippeditoolsjs/web';

import Layout from './components/navbar/Layout';
import { DashboardViewProvider } from './context/DashboardView';
import { RobotViewProvider } from './context/RobotView';
import { SchedulerViewProvider } from './context/SchedulerView';
import AlertsTabs from './redux/containers/alerts/AlertsTabs';
import Scheduler from './redux/containers/fleetManagement/Scheduler';
import DashboardView from './redux/containers/home/DashboardView';
import LayoutsView from './redux/containers/layoutsView/LayoutsView';
import MappingView from './redux/containers/mappingView/MappingView';
import Link from './redux/containers/link/Link';
import Parameters from './redux/containers/parameters/Parameters';
import PipelineMonitoring from './redux/containers/pipelineMonitoring/PipelineMonitoring';
import SessionView from './redux/containers/pipelineMonitoring/SessionView';
import RobotView from './redux/containers/robotView/RobotView';
import Search from './redux/containers/search/Search';
import StoreConfig from './redux/containers/storeConfig/StoreConfig';
import StoreContacts from './redux/containers/storeContacts/StoreContacts';
import SnackAlert from './redux/containers/tools/SnackAlert';
import theme from './styleSheet';

import { useSelector, useDispatch } from 'react-redux';
import { getNavigationOptions } from 'core/slices/navigation';
import NotAllowedView from './components/tools/NotAllowed';
import { checkPermission } from './components/Utils';
import CircularLoader from './components/tools/CircularLoader';

function ProtectedRoute({ children, viewId }) {
  const navOptions = useSelector((state) => state.navigation.options);
  const actualView = checkPermission(navOptions, viewId);
  if (navOptions?.length && !actualView) {
    return <NotAllowedView />;
  } else if (navOptions?.length === 0) {
    return <CircularLoader />;
  }

  return children;
}

const { REACT_APP_AUTH_BASE_URL, REACT_APP_BASE_URL } = process.env;

const App = () => {
  const [themeName, setThemeName] = useState('light');
  const isDarkTheme = themeName === 'dark';
  const toggleTheme = () => setThemeName(isDarkTheme ? 'light' : 'dark');

  const { i18n } = useTranslation();
  const toggleTranslation = (lang) => i18n.changeLanguage(lang);

  useEffect(() => {
    // TODO: remove this line when we the option to change language is available
    i18n.changeLanguage('en');
  }, []);

  const dispatch = useDispatch();
  const options = useSelector((state) => state.navigation.options);

  useEffect(() => {
    if (!options.length) {
      dispatch(getNavigationOptions());
    }
  }, [dispatch, options]);

  return (
    <ThemeProvider theme={theme}>
      <SnackAlert />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PrivateRoute
          appUrlLogin={REACT_APP_AUTH_BASE_URL}
          tenattype={'int'}
          appBaseUrl={REACT_APP_BASE_URL}
        >
          <Router>
            <Layout
              isDarkTheme={isDarkTheme}
              toggleTheme={toggleTheme}
              toggleTranslation={toggleTranslation}
            >
              <Routes>
                <Route exact path="/search" element={<Search />} />
                {/* Pipeline Monitoring */}
                <Route
                  exact
                  path="/pipeline_processing"
                  element={
                    <ProtectedRoute viewId="Pipeline">
                      <PipelineMonitoring />
                    </ProtectedRoute>
                  }
                />
                {/* Pipeline Monitoring */}
                <Route
                  exact
                  path="/pipeline_processing/:session/:date"
                  element={
                    <ProtectedRoute viewId="Pipeline">
                      <SessionView />
                    </ProtectedRoute>
                  }
                />
                {/* Parameters */}
                <Route
                  exact
                  path="/parameters"
                  element={
                    <ProtectedRoute viewId="Parameters">
                      <Parameters />
                    </ProtectedRoute>
                  }
                />
                {/* Scheduler */}
                <Route
                  exact
                  path="/scheduler"
                  element={
                    <ProtectedRoute viewId="Scheduler">
                      <SchedulerViewProvider>
                        <Scheduler />
                      </SchedulerViewProvider>
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/robot"
                  element={
                    <RobotViewProvider>
                      <RobotView />
                    </RobotViewProvider>
                  }
                />
                {/* Robot */}
                <Route
                  exact
                  path="/robot/:store"
                  element={
                    <RobotViewProvider>
                      <RobotView />
                    </RobotViewProvider>
                  }
                />
                {/* Robot */}
                <Route
                  exact
                  path="/robot/:store/:robot"
                  element={
                    <RobotViewProvider>
                      <RobotView />
                    </RobotViewProvider>
                  }
                />
                {/* Alerts */}
                <Route
                  exact
                  path="/alerts"
                  element={
                    <ProtectedRoute viewId="Alerts">
                      <AlertsTabs />
                    </ProtectedRoute>
                  }
                ></Route>
                {/* Dashboard */}
                <Route
                  exact
                  path="/"
                  element={
                    <DashboardViewProvider>
                      <DashboardView />
                    </DashboardViewProvider>
                  }
                />
                {/* Store contacts */}
                <Route
                  exact
                  path="/store/contacts"
                  element={
                    <ProtectedRoute viewId="Store Contacts">
                      <StoreContacts />
                    </ProtectedRoute>
                  }
                />
                {/* Link */}
                <Route path="/share" element={<Link />} />
                {/* Store config */}
                <Route
                  exact
                  path="/store/config"
                  element={
                    <ProtectedRoute viewId="Store Config">
                      <StoreConfig />
                    </ProtectedRoute>
                  }
                />
                {/* Layouts (Beta) */}
                <Route
                  exact
                  path="/layouts"
                  element={
                    <ProtectedRoute viewId="Layouts (Beta)">
                      <LayoutsView />
                    </ProtectedRoute>
                  }
                />
                {/* Mapping jobs */}
                <Route exact path="/mapping" element={<MappingView />} />
              </Routes>
            </Layout>
          </Router>
        </PrivateRoute>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default withTranslation()(App);
