import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Grid, Card, CardContent, Autocomplete, TextField, LinearProgress, Box, Typography } from '@mui/material';
import useLayout, { EDITABLE_LAYERS } from '../tools/useLayout';
import useLayoutAnnotations, { LAYOUT_TOOLS } from '../tools/useLayoutAnnotations';
import { useTranslation } from 'react-i18next';
import AnnotationBar from './AnnotationBar';
import DialogCreation from './DialogCreation';
import DialogCreationZone from './DialogCreationZone';
import DialogCreationAisle from './DialogCreationAisle';
import DialogCreationExclusion from './DialogCreationExclusion';
import DialogCreationAisleSide from './DialogCreationAisleSide';
import DialogConfirmPose from './DialogConfirmPose';
import FallbackInformation from './FallbackInformation';
import SettingsButton from './SettingsButton';
import MapMetadata from './MapMetadata';
import DialogNewMap from './DialogNewMap';
import Sidebar from './Sidebar';
import { LoadingButton } from '@mui/lab';
import DialogEdit from './DialogEdit';
import DialogEditAisle from './DialogEditAisle';
import DialogEditZone from './DialogEditZone';
import DialogEditAisleSide from './DialogEditAisleSide';
import DialogCreationFree from './DialogCreationFree';
import DialogEditAnnotation from './DialogEditAnnotation';

export default function LayoutsView({
    getAvailableStores,
    availableStores,
    isLoadingAvailableStores,
    getAvailableFloors,
    availableFloors,
    isLoadingAvailableFloors,
    getGlobalLayers,
    globalLayers,
    isLoadingGlobalLayers,
    getLayoutVersions,
    isLoadingLayoutVersions,
    getStoreMapLayout,
    isLoadingStoreMapLayout,
    storeMapLayout,
    getStoreLayout,
    isLoadingStoreLayout,
    storeLayout,
    isSendingStoreLayout,
    sendLayoutStore,
    isLoadingSendStoreLayout,
    postStoreLayout,
    setSnackAlert,
    ...rest
}) {
    const [selectedStore, setSelectedStore] = useState(null);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [newMapDialogOpen, setNewMapDialogOpen] = useState(false);
    const [useEmptyLayout, setUseEmptyLayout] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dialogEdit, setDialogEdit] = useState(null);
    const [dialogEditOpen, setDialogEditOpen] = useState(false);
    const currentLayout = useMemo(() => useEmptyLayout || !storeMapLayout?.id ? { layout: {} } : storeLayout, [useEmptyLayout, storeLayout, storeMapLayout]);
    const timeoutRef = useRef(null);
    const { addNewPose,
        storeMapCanvasRef,
        layers,
        colissionLayers,
        toggleParentLayer,
        toggleChildLayer,
        addNewLayer,
        useClientAisles,
        setUseClientAisles,
        modifiedLayout,
        hasBeenModified,
        aislesColissioned,
        addNewZone,
        editAnnotation,
        existsAnyWarning,
        warnings,
        deleteAnnotation,
        useRealBbox,
        setUseRealBbox
    } = useLayout(storeMapLayout, currentLayout);
    const {
        activeTool,
        canvasDrawRef,
        setActiveTool,
        updateDrawColor,
        resetDraw
    } = useLayoutAnnotations(storeMapCanvasRef, storeMapLayout?.metadata, colissionLayers);
    const [dialogConfirmPoseOpen, setDialogConfirmPoseOpen] = useState(false);
    const [dialogConfirmPoseData, setDialogConfirmPoseData] = useState(null);

    const showStoreMap = useMemo(() => selectedStore && selectedFloor, [selectedStore, selectedFloor]);

    const { t } = useTranslation();

    useEffect(() => {
        getAvailableStores();
        getGlobalLayers();
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setActiveTool(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore, selectedFloor]);

    useEffect(() => {
        if (!selectedFloor || !selectedStore) return;
        if (storeMapLayout?.id) {
            if (storeMapLayout?.new_map) {
                setNewMapDialogOpen(true);
            } else {
                setUseEmptyLayout(false);
                if (storeLayout?.map_id !== storeMapLayout?.id || hasBeenModified) {
                    getStoreLayout({
                        store: selectedStore?.value,
                        floor: selectedFloor?.value,
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeMapLayout]);

    const allowLastLayout = useCallback(() => {
        if (storeMapLayout?.new_map) {
            setNewMapDialogOpen(false);
            setUseEmptyLayout(false);
            getStoreLayout({
                store: selectedStore?.value,
                floor: selectedFloor?.value,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFloor, selectedStore, storeMapLayout?.new_map]);

    const cancelLastLayout = useCallback(() => {
        setNewMapDialogOpen(false);
        setUseEmptyLayout(true);
    }, []);

    useEffect(() => {
        if (selectedStore) {
            getAvailableFloors({
                store: selectedStore.value,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore]);

    useEffect(() => {
        if (selectedStore && availableFloors?.length === 1) {
            setSelectedFloor({ label: "" + availableFloors[0], value: "" + availableFloors[0] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableFloors]);

    useEffect(() => {
        if (selectedFloor && selectedStore) {
            getStoreMapLayout({
                store: selectedStore.value,
                floor: selectedFloor.value,
            });
            getLayoutVersions({
                store: selectedStore.value,
                floor: selectedFloor.value,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFloor]);


    const handleNewAnnotation = useCallback((tool, data) => {
        setDialogData({
            tool: tool,
            data: data
        });
        setDialogOpen(true);
    }, []);

    const handleNewPose = useCallback((data) => {
        setDialogConfirmPoseData(data);
        setDialogConfirmPoseOpen(true);
    }, []);

    const callbackActions = useMemo(() => ({
        [LAYOUT_TOOLS.ZONE]: (data) => handleNewAnnotation(LAYOUT_TOOLS.ZONE, data),
        [LAYOUT_TOOLS.AISLE]: (data) => handleNewAnnotation(LAYOUT_TOOLS.AISLE, data),
        [LAYOUT_TOOLS.EXCLUSION_ZONE]: (data) => handleNewAnnotation(LAYOUT_TOOLS.EXCLUSION_ZONE, data),
        [LAYOUT_TOOLS.AISLE_SIDE]: (data) => handleNewAnnotation(LAYOUT_TOOLS.AISLE_SIDE, data),
        [LAYOUT_TOOLS.HOME]: (data) => handleNewPose(data),
        [LAYOUT_TOOLS.FREE_AREA]: (data) => handleNewAnnotation(LAYOUT_TOOLS.FREE_AREA, data),
        [LAYOUT_TOOLS.FREE_LINE]: (data) => handleNewAnnotation(LAYOUT_TOOLS.FREE_LINE, data),
        [LAYOUT_TOOLS.FREE_PIN]: (data) => handleNewAnnotation(LAYOUT_TOOLS.FREE_PIN, data),
    }), [handleNewAnnotation, handleNewPose]);

    const closeDialog = useCallback(() => {
        resetDraw();
        setDialogOpen(false);
    }, [resetDraw]);

    const closeDialogConfirmPose = useCallback(() => {
        resetDraw();
        setDialogConfirmPoseOpen(false);
    }, [resetDraw]);

    const toggleFunctions = useMemo(() => ({
        parent: toggleParentLayer,
        child: toggleChildLayer
    }), [toggleParentLayer, toggleChildLayer]);

    const currentTool = useMemo(() => {
        return dialogData?.tool || null;
    }, [dialogData]);

    const dialogContentComponents = useMemo(() => ({
        [LAYOUT_TOOLS.ZONE]: <DialogCreationZone updateDrawColor={updateDrawColor} aislesColissioned={aislesColissioned} addNewZone={addNewZone} />,
        [LAYOUT_TOOLS.AISLE]: <DialogCreationAisle colissionLayers={colissionLayers} />,
        [LAYOUT_TOOLS.EXCLUSION_ZONE]: <DialogCreationExclusion />,
        [LAYOUT_TOOLS.AISLE_SIDE]: <DialogCreationAisleSide colissionLayers={colissionLayers} layers={layers} />,
        [LAYOUT_TOOLS.FREE_AREA]: <DialogCreationFree />,
        [LAYOUT_TOOLS.FREE_LINE]: <DialogCreationFree />,
        [LAYOUT_TOOLS.FREE_PIN]: <DialogCreationFree addNewPose={addNewPose} />,
    }), [colissionLayers, updateDrawColor, layers, aislesColissioned, addNewZone, addNewPose]);

    const dialogEditComponents = useMemo(() => ({
        [EDITABLE_LAYERS.CAPTURE]: <DialogEditAisle />,
        [EDITABLE_LAYERS.ZONES]: <DialogEditZone />,
        [EDITABLE_LAYERS.AISLE_SIDE]: <DialogEditAisleSide />,
    }), []);

    const isLoading = useMemo(() => isLoadingStoreMapLayout || isLoadingStoreLayout, [isLoadingStoreMapLayout, isLoadingStoreLayout])

    useEffect(() => {
        if (activeTool && isLoading) {
            setActiveTool(null);
        } else if (!activeTool && storeMapLayout && !isLoading && selectedStore && selectedFloor) {
            setActiveTool(LAYOUT_TOOLS.MOVE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, storeMapLayout]);

    useEffect(() => {
        if (isSubmitting && !isLoadingSendStoreLayout) {
            setIsSubmitting(false);
            const snackMessage = sendLayoutStore?.status === 200 ?
                { severity: 'success', message: t('overseer_app.layouts.creation.layout_saved', 'Layout saved') } :
                { severity: 'error', message: t('overseer_app.layouts.creation.error_saving_layout', 'Error saving layout') };
            setSnackAlert({ open: true, ...snackMessage });
            if (sendLayoutStore?.status === 200) {
                getStoreMapLayout({
                    store: selectedStore.value,
                    floor: selectedFloor.value,
                });
                getLayoutVersions({
                    store: selectedStore.value,
                    floor: selectedFloor.value,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingSendStoreLayout]);

    const handleSaveChanges = useCallback(() => {
        setIsSubmitting(true);
        postStoreLayout({
            store: selectedStore?.value,
            floor: selectedFloor?.value,
            layout: JSON.stringify(modifiedLayout?.layout),
            map_id: storeMapLayout?.id,
        });
    }, [selectedStore, selectedFloor, modifiedLayout, postStoreLayout, storeMapLayout]);

    const handleDialogEdit = useCallback((data) => {
        setDialogEdit(data);
        setDialogEditOpen(true);
    }, []);

    const handleCloseDialogEdit = useCallback(() => {
        timeoutRef.current = setTimeout(() => setDialogEdit(null), 300);
        setDialogEditOpen(false);
    }, []);

    const parsedStores = useMemo(() => availableStores?.stores?.map(store => ({ ...store, label: store.store_id, value: store.store_id })) || [], [availableStores]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid container item xs={12} sm={7} spacing={2}>
                    <Grid item xs={6} sm={4}>
                        <Autocomplete
                            fullWidth
                            defaultValue={null}
                            value={selectedStore}
                            options={parsedStores}
                            loading={isLoadingAvailableStores}
                            disabled={isLoadingAvailableStores || isLoadingStoreMapLayout}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            groupBy={(option) => option.chain_name}
                            onChange={(event, newValue) => {
                                setSelectedStore(newValue);
                                setSelectedFloor(null);
                            }}
                            renderInput={(params) => <TextField {...params} label={t('overseer_app.layouts.select_store', 'Select store')} />}
                            noOptionsText={t('overseer_app.layouts.no_stores_found', 'No stores found')}
                            renderGroup={(params) => (
                                <div key={params.key}>
                                    <Box sx={{ position: 'sticky', top: -8, zIndex: 1, backgroundColor: '#f5f5f5', p: 1 }}>
                                        <Typography variant="caption" color="textSecondary">{params.group}</Typography>
                                    </Box>
                                    {params.children}
                                </div>
                            )}
                        />
                        <LinearProgress
                            sx={{ width: '100%', mx: 'auto', top: '-5px', visibility: isLoadingAvailableStores ? 'visible' : 'hidden' }}
                            color="secondary"
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Autocomplete
                            fullWidth
                            defaultValue={null}
                            options={availableFloors?.map?.(floor => ({ label: "" + floor, value: "" + floor })) || []}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            loading={isLoadingAvailableFloors}
                            disabled={isLoadingAvailableFloors || !selectedStore || isLoadingStoreMapLayout}
                            onChange={(event, newValue) => setSelectedFloor(newValue)}
                            value={selectedFloor}
                            renderInput={(params) => <TextField {...params} label={t('overseer_app.layouts.select_floor', 'Select floor')} />}
                            noOptionsText={t('overseer_app.layouts.no_floors_found', 'No floors found')}
                        />
                        <LinearProgress
                            sx={{ width: '100%', mx: 'auto', top: '-5px', visibility: isLoadingAvailableFloors ? 'visible' : 'hidden' }}
                            color="secondary"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <AnnotationBar activeTool={activeTool} setActiveTool={setActiveTool} callbackActions={callbackActions} disabled={!selectedStore || !selectedFloor || isLoading} />
                </Grid>
                <Grid item xs={9.5} sx={{ height: '80vh', maxHeight: '80vh' }}>
                    <Card sx={{ height: '100%', width: '100%', pb: 0, position: 'relative' }}>
                        <CardContent sx={{
                            height: '100%', width: '100%', position: 'relative', overflow: 'none', borderRadius: 'inherit', padding: '0', margin: '0', '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}>
                            {isLoading || newMapDialogOpen ?
                                <>
                                    <LinearProgress sx={{ width: '100%', top: '0', left: '0', position: 'absolute' }} color="secondary" />
                                    <FallbackInformation showLoading>{t('overseer_app.layouts.loading_store_map', 'Loading store map...')}</FallbackInformation>
                                </>
                                :
                                showStoreMap ?
                                    storeMapLayout?.map_img ?
                                        <img src={'data:image/jpeg;base64,' + storeMapLayout.map_img} alt="Bucket Map Layout" style={{
                                            position: 'absolute',
                                            top: '0',
                                            zIndex: 0,
                                            userSelect: 'none',
                                            pointerEvents: 'none'
                                        }} />
                                        :
                                        <FallbackInformation>{t('overseer_app.layouts.error_getting_store_map', 'Error getting store map')}</FallbackInformation>
                                    :
                                    <FallbackInformation>{t('overseer_app.layouts.need_to_select_store', 'Select a store and floor to view the map')}</FallbackInformation>
                            }
                            <canvas ref={canvasDrawRef} id="draw-canvas" style={{ zIndex: 3, position: 'absolute', top: '0', userSelect: 'none', pointerEvents: 'none', display: !(isLoading || newMapDialogOpen) && showStoreMap && storeMapLayout?.map_img ? 'block' : 'none' }} />
                            <canvas ref={storeMapCanvasRef} id="map" style={{ zIndex: 2, position: 'absolute', top: '0', userSelect: 'none', display: !(isLoading || newMapDialogOpen) && showStoreMap && storeMapLayout?.map_img ? 'block' : 'none' }} />
                        </CardContent>
                        {showStoreMap && storeMapLayout?.map_img && !isLoading && !newMapDialogOpen && <SettingsButton useClientAisles={useClientAisles} setUseClientAisles={setUseClientAisles} useRealBbox={useRealBbox} setUseRealBbox={setUseRealBbox} />}
                    </Card>
                </Grid>
                <Grid container item xs={2.5} sx={{ height: '80vh', maxHeight: '80vh', gap: 2, flexWrap: 'nowrap' }} direction="column">
                    <Box sx={{ flexGrow: 1, minHeight: 0, display: 'flex', flexDirection: 'column', pb: 0 }}>
                        <Sidebar isLoading={isLoading} store={selectedStore?.value} floor={selectedFloor?.value} currentVersion={currentLayout?.id} layers={layers} toggles={toggleFunctions} isFullLoading={isLoading || !showStoreMap || isLoadingLayoutVersions || newMapDialogOpen} disabled={!selectedStore || !selectedFloor} setDialogEdit={handleDialogEdit} existWarnings={existsAnyWarning} warnings={warnings} deleteAnnotation={deleteAnnotation} />
                    </Box>
                    <Box sx={{ flexShrink: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <MapMetadata disabled={isLoading || !showStoreMap || isLoadingLayoutVersions || newMapDialogOpen} metadata={storeMapLayout?.metadata} newMap={storeMapLayout?.new_map && !isLoading} creationDate={storeMapLayout?.created_at} />
                        <LoadingButton variant="contained" color="primary" fullWidth onClick={handleSaveChanges} loading={isLoadingSendStoreLayout} disabled={!hasBeenModified || isLoading || existsAnyWarning}>
                            {t('overseer_app.parameters.Save_changes', 'Save changes')}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid >
            <DialogCreation open={dialogOpen} onClose={closeDialog} addNewLayer={addNewLayer} dialogData={dialogData} layers={layers}>
                {dialogContentComponents?.[currentTool] || null}
            </DialogCreation>
            <DialogConfirmPose open={dialogConfirmPoseOpen} onClose={closeDialogConfirmPose} data={dialogConfirmPoseData} addNewPose={addNewPose} />
            <DialogNewMap open={newMapDialogOpen} onClose={cancelLastLayout} onSubmit={allowLastLayout} />
            <DialogEdit open={dialogEditOpen} onClose={handleCloseDialogEdit} data={dialogEdit?.data} modifiedLayout={modifiedLayout?.layout} colissionLayers={colissionLayers} editAnnotation={editAnnotation} layer={dialogEdit?.layer} >
                {dialogEditComponents?.[dialogEdit?.layer] || <DialogEditAnnotation />}
            </DialogEdit>
        </>
    );
}
