import { forwardRef, useMemo, useState, useCallback, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { EXCLUDED_LAYERS } from './DialogCreationFree';
import { useSelector } from 'react-redux';
import natsort from 'natsort';
import { Grid, Collapse, Alert, TextField, MenuItem, } from '@mui/material';

import { boldString } from '../Utils';
import { EDITABLE_LAYERS } from '../tools/useLayout';
export default forwardRef(function DialogEditAnnotation({
    data,
    modifiedLayout,
    isUniqueName,
    editAnnotation,
    layer
}, ref) {
    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const originalValues = useMemo(() => modifiedLayout?.[layer]?.[data?.name] || {}, [data, layer]);

    const defaultValues = useMemo(() => ({
        name: data?.name || '',
        clientName: originalValues?.client_name || '',
        layer: layer || '',
    }), [originalValues, data, layer]);

    const { globalLayers } = useSelector(state => state.layouts);
    const filteredLayers = useMemo(() => globalLayers?.layers?.map(({ name }) => name)?.filter(layer => !EXCLUDED_LAYERS.includes(layer)).sort(natsort({ insensitive: true })) || [], [globalLayers]);

    const [formState, setFormState] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [existingName, setExistingName] = useState(null);

    const isUnique = useMemo(() => formState?.name?.toLowerCase() === data?.name?.toLowerCase() ? true : isUniqueName(formState?.name, formState?.layer), [formState?.name, isUniqueName, data?.name, formState?.layer]);
    const existsError = useMemo(() => Object.keys(errors).filter(key => !!errors[key]).length > 0, [errors]);

    const validateField = useCallback((field, value) => {
        const validationRules = {
            name: { required: true, notAllowSpaces: true },
            layer: { required: true },
        };
        const rule = validationRules?.[field];
        if (rule && rule.required && (!value || value === '' || value.trim() === ''))
            return 'This field is required';
        if (rule && rule.notAllowSpaces && value?.includes(' '))
            return 'This field does not allow spaces';
        return null;
    }, []);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);

    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) errors[field] = error;
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) return false;
        return true;
    }, [formState, validateField]);

    const makeChanges = useCallback(() => {
        editAnnotation(data?.layer, {
            _originalName: defaultValues?.name,
            _existsConflict: !isUnique,
            ...formState,
        });
        return true;
    }, [editAnnotation, formState, defaultValues, isUnique, data?.layer]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return null;
        return {
            needConfirm: !isUnique,
            callback: makeChanges,
            existingAnnotation: existingName,
        }
    }, [isUnique, makeChanges, validateAllFields, existingName]);

    useEffect(() => {
        if (!isUnique) {
            setExistingName(Object.keys(modifiedLayout?.[data?.layer] || {}).find(layer => layer.toLowerCase() === formState?.name?.toLowerCase()) || formState?.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUnique]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }));

    const TEXTS = useMemo(() => ({
        [EDITABLE_LAYERS.EXCLUSION_ZONES]: {
            name: t('overseer_app.layouts.creation.exclusion_zone_name', 'Exclusion zone name'),
            clientName: t('overseer_app.layouts.creation.client_exclusion_zone_name', 'Client\'s exclusion zone name'),
        },
    }), [t]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                <Collapse in={!isUnique}>
                    <Alert severity="warning">
                        {boldString(t('overseer_app.layouts.edition.conflict_text', 'There is already an annotation with the same name in this layer (**{{item}}**). A **warning** will be assigned to the existing layer with this name, and it will need to be **reviewed**.', { item: existingName }))}
                    </Alert>
                </Collapse>
                <Collapse in={existsError} sx={{ mt: !isUnique && existsError ? 1 : 0 }}>
                    <Alert severity="error">
                        {t('overseer_app.layouts.edition.default_error', 'Some fields are required to modify an annotation in this Layer.')}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.layer', 'Layer')} fullWidth required select value={formState?.layer} disabled={formState?.layer === EDITABLE_LAYERS.EXCLUSION_ZONES ? true : undefined}>
                    {formState?.layer === EDITABLE_LAYERS.EXCLUSION_ZONES ? <MenuItem key={formState?.layer} value={formState?.layer}>{formState?.layer}</MenuItem> : filteredLayers.map(layer => <MenuItem key={layer} value={layer}>{layer}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField label={TEXTS?.[formState?.layer]?.name || t('overseer_app.layouts.creation.annotation_name', 'Annotation name')} fullWidth required value={formState?.name} onChange={(e) => handleFieldChange('name', e.target.value)} error={!!errors?.name} helperText={t('overseer_app.layouts.edition.not_allow_spaces', 'This field does not allow spaces')} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={TEXTS?.[formState?.layer]?.clientName || t('overseer_app.layouts.creation.client_annotation_name', 'Client\'s annotation name')} fullWidth value={formState?.clientName} onChange={(e) => handleFieldChange('clientName', e.target.value)} />
            </Grid>
        </Grid>
    )
})