import { IconButton, Popover, Box, FormControlLabel, Switch, Badge } from '@mui/material';
import { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function SettingsButton({ useClientAisles, setUseClientAisles, useRealBbox, setUseRealBbox }) {
    const [openSettings, setOpenSettings] = useState(null);
    const buttonRef = useRef(null);
    const toggleUseClientAisles = useCallback(() => {
        setUseClientAisles(prev => !prev);
    }, [setUseClientAisles]);

    const toggleUseRealBbox = useCallback(() => {
        setUseRealBbox(prev => !prev);
    }, [setUseRealBbox]);

    useEffect(() => {
        if (buttonRef?.current && !openSettings) {
            buttonRef.current.blur();
        }
    }, [openSettings]);

    const isAnyActive = useMemo(() => (useClientAisles || useRealBbox), [useClientAisles, useRealBbox]);
    return (
        <>
            <IconButton sx={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1000 }} onClick={(e) => setOpenSettings(e.currentTarget)} id="map-settings-button" ref={buttonRef}>
                {isAnyActive ? <Badge variant="dot" color="primary">
                    <IconComponent iconName="settings" />
                </Badge> : <IconComponent iconName="settings" />}
            </IconButton>
            <Popover
                open={!!openSettings}
                onClose={() => setOpenSettings(null)}
                anchorEl={openSettings}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <FormControlLabel checked={useClientAisles} onChange={toggleUseClientAisles} control={<Switch size='small' />} label="Aisle names by client" sx={{ fontSize: '10px' }} />
                    <FormControlLabel checked={useRealBbox} onChange={toggleUseRealBbox} control={<Switch size='small' />} label="Use real bbox" sx={{ fontSize: '10px' }} />
                </Box>
            </Popover>
        </>
    );
}