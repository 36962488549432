import { Grid, TextField, MenuItem } from "@mui/material";
import { forwardRef, useMemo, useState, useCallback, useImperativeHandle } from "react";
import { AISLES_LAYER, EDITABLE_LAYERS } from "../tools/useLayout";
import { useTranslation } from "react-i18next";
import { isValidJson } from "../tools/utils";
import { ORIENTATIONS } from "../tools/useLayoutAnnotations";

export default forwardRef(function DialogEditAisleSide({
    data,
    modifiedLayout,
    editAnnotation,
}, ref) {
    const { t } = useTranslation();
    const originalValues = useMemo(() => modifiedLayout?.[AISLES_LAYER]?.[data?.parent]?.[data?.name] || {}, [data, modifiedLayout]);

    const parsedName = useMemo(() => {
        const [aisle, orientation, part = null] = data?.name?.split('-');
        return { aisle, orientation, part };
    }, [data]);
    const defaultValues = useMemo(() => ({
        aisle: parsedName?.aisle || '',
        orientation: parsedName?.orientation || '',
        part: parsedName?.part || '',
        params: isValidJson(originalValues?.params) ? JSON.stringify(JSON.parse(originalValues.params), null, 2) : '{}',
        clientName: originalValues?.client_name || '',
    }), [parsedName, originalValues]);

    const [formState, setFormState] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const validateField = useCallback((field, value) => {
        const rules = {
            params: { mustBeJson: true },
        };
        const rule = rules?.[field];
        if (!rule) return null;
        if (rule.mustBeJson && !isValidJson(value))
            return 'This field must be a valid JSON object';
        return null;
    }, []);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);

    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) errors[field] = error;
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) return false;
        return true;
    }, [formState, validateField]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return null;
        return {
            needConfirm: false,
            callback: () => {
                editAnnotation(EDITABLE_LAYERS.AISLE_SIDE, {
                    _originalName: data?.name,
                    params: JSON.stringify(JSON.parse(formState?.params)),
                    client_name: formState?.clientName,
                    parent: data?.parent,
                });
            },
        }
    }, [validateAllFields, editAnnotation, data, formState]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={3}>
                <TextField label={t('overseer_app.layouts.aisle', 'Aisle')} fullWidth required select value={parsedName?.aisle} disabled>
                    <MenuItem value={parsedName?.aisle}>{parsedName?.aisle}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField required label={t('overseer_app.layouts.aisle_orientation', 'Orientation')} fullWidth value={parsedName?.orientation} select disabled>
                    {ORIENTATIONS.map(orientation => (
                        <MenuItem value={orientation} key={orientation} >{orientation}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={5}>
                <TextField label={t('overseer_app.layouts.aisle_part', 'Aisle Part')} fullWidth value={parsedName?.part || ''} disabled />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.linear_client_name', 'Client\'s linear name')} fullWidth value={formState?.clientName} onChange={(e) => handleFieldChange('clientName', e.target.value)} error={!!errors.clientName} />
            </Grid>
            <Grid item xs={12}>
                <TextField multiline minRows={2} label={t('overseer_app.layouts.parameters', 'Parameters')} fullWidth value={formState?.params} onChange={(e) => handleFieldChange('params', e.target.value)} error={!!errors.params} helperText={t('overseer_app.layouts.creation.parameters_helper', 'This field must be a valid JSON object')} />
            </Grid>
        </Grid>
    )
})