import { useImperativeHandle, forwardRef, useCallback, useMemo, useState, useEffect } from "react";
import { Grid, TextField, Checkbox, MenuItem, Box, Divider, FormLabel, RadioGroup, FormControlLabel, Radio, Collapse, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EDITABLE_LAYERS, ZONES_LAYER } from "../tools/useLayout";
import { boldString } from "../Utils";
import CircleColor from "./CircleColor";


export default forwardRef(function DialogEditAisle({
    data,
    modifiedLayout,
    colissionLayers,
    isUniqueName,
    editAnnotation,
}, ref) {
    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const originalValues = useMemo(() => modifiedLayout?.[EDITABLE_LAYERS.CAPTURE]?.[data?.name] || {}, [data]);

    const defaultValues = useMemo(() => ({
        name: data?.name || '',
        clientName: originalValues?.client_name || '',
        excluded: originalValues?.excluded || false,
        zone: originalValues?.zone || '',
        aisleType: originalValues?.aisle_type || 'aisle',
    }), [originalValues, data]);

    const [formState, setFormState] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [existingAisle, setExistingAisle] = useState(null);

    const collisionZones = useMemo(() => {
        if (!originalValues?.bbox) return [];
        return colissionLayers(ZONES_LAYER, { start: originalValues?.bbox?.[0], end: originalValues?.bbox?.[1] });
    }, [originalValues, colissionLayers]);
    const isUnique = useMemo(() => formState?.name?.toLowerCase() === data?.name?.toLowerCase() ? true : isUniqueName(formState?.name, EDITABLE_LAYERS.CAPTURE), [formState?.name, isUniqueName, data?.name]);
    const existsError = useMemo(() => Object.keys(errors).filter(key => !!errors[key]).length > 0, [errors]);


    const validateField = useCallback((field, value) => {
        const validationRules = {
            name: { required: true, notAllowSpaces: true },
            zone: { required: collisionZones.length >= 1 },
            aisleType: { required: true },
        };
        const rule = validationRules?.[field];
        if (rule && rule.required && (!value || value === '' || value.trim() === ''))
            return 'This field is required';
        if (rule && rule.notAllowSpaces && value?.includes(' '))
            return 'This field does not allow spaces';
        return null;
    }, [collisionZones]);

    const handleFieldChange = useCallback((field, value) => {
        setErrors(prev => ({ ...prev, [field]: validateField(field, value) || undefined }));
        setFormState(prev => ({ ...prev, [field]: value }));
    }, [validateField]);


    const validateAllFields = useCallback(() => {
        const errors = {};
        Object.keys(formState).forEach(field => {
            const error = validateField(field, formState[field]);
            if (error) errors[field] = error;
        });
        setErrors(errors);
        if (Object.keys(errors).length > 0) return false;
        return true;
    }, [formState, validateField]);

    const makeChanges = useCallback(() => {
        editAnnotation(EDITABLE_LAYERS.CAPTURE, {
            _originalName: defaultValues?.name,
            _existsConflict: !isUnique,
            ...formState,
        });
        return true;
    }, [editAnnotation, formState, defaultValues, isUnique]);

    const onSave = useCallback(() => {
        const isValid = validateAllFields();
        if (!isValid) return null;
        return {
            needConfirm: !isUnique,
            callback: makeChanges,
            existingAnnotation: existingAisle,
        }
    }, [isUnique, makeChanges, validateAllFields, existingAisle]);

    useImperativeHandle(ref, () => ({
        callbackOnSave: onSave
    }));

    useEffect(() => {
        if (!isUnique) {
            setExistingAisle(Object.keys(modifiedLayout?.[EDITABLE_LAYERS.CAPTURE] || {}).find(layer => layer.toLowerCase() === formState?.name?.toLowerCase()) || formState?.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUnique]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                <Collapse in={!isUnique}>
                    <Alert severity="warning">
                        {boldString(t('overseer_app.layouts.edition.conflict_text', 'There is already an annotation with the same name in this layer (**{{item}}**). A **warning** will be assigned to the existing layer with this name, and it will need to be **reviewed**.', { item: existingAisle }))}
                    </Alert>
                </Collapse>
                <Collapse in={existsError} sx={{ mt: !isUnique && existsError ? 1 : 0 }}>
                    <Alert severity="error">
                        {t('overseer_app.layouts.edition.default_error', 'Some fields are required to modify an annotation in this Layer.')}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={6}>
                <TextField label={t('overseer_app.layouts.layer', 'Layer')} fullWidth required select value={EDITABLE_LAYERS.CAPTURE} disabled>
                    <MenuItem value={EDITABLE_LAYERS.CAPTURE}>{EDITABLE_LAYERS.CAPTURE}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField label={t('overseer_app.layouts.zone', 'Zone')} fullWidth required={collisionZones.length >= 1} select value={formState?.zone} disabled={!!(collisionZones?.length === 0 || (defaultValues?.zone && collisionZones.length === 1))} error={!!errors?.zone} onChange={(e) => handleFieldChange('zone', e.target.value)}>
                    {collisionZones.map(({ layer, color }) => (
                        <MenuItem value={layer} key={layer}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                                {layer}
                                <CircleColor color={color} />
                            </Box>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.aisle_name', 'Aisle name')} fullWidth required value={formState?.name} onChange={(e) => handleFieldChange('name', e.target.value)} error={!!errors?.name} helperText={t('overseer_app.layouts.edition.not_allow_spaces', 'This field does not allow spaces')} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t('overseer_app.layouts.creation.client_aisle_name', 'Client\'s aisle name')} fullWidth value={formState?.clientName} onChange={(e) => handleFieldChange('clientName', e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <FormLabel required>{t('overseer_app.layouts.creation.select_aisle_type', 'Select a type')}</FormLabel>
                <RadioGroup row value={formState?.aisleType} onChange={(e) => handleFieldChange('aisleType', e.target.value)}>
                    <FormControlLabel value="aisle" control={<Radio size="small" />} label={t('overseer_app.layouts.aisle', 'Aisle')} />
                    <FormControlLabel value="fixture" control={<Radio size="small" />} label={t('overseer_app.layouts.fixture', 'Fixture')} />
                </RadioGroup>
                <FormControlLabel control={<Checkbox size="small" checked={formState?.excluded} onChange={(e) => handleFieldChange('excluded', e.target.checked)} />} label={t('overseer_app.layouts.edition.excluded', 'Exclude aisle from capture')} />
            </Grid>
        </Grid>
    )
})