import { Accordion, AccordionSummary, AccordionDetails, Typography, Divider, Box, Chip } from '@mui/material'
import { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatLanguageDate } from "../tools/utils";

export default function MapMetadata({ disabled, metadata, newMap, creationDate }) {
    const [open, setOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const { isLoadingStoreMapLayout } = useSelector(state => state.layouts);

    useEffect(() => {
        if (disabled) {
            setOpen(false);
        }
    }, [disabled]);
    return (
        <Accordion disabled={disabled} expanded={open} onChange={(event, newValue) => setOpen(newValue)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderBottom: 1, borderColor: 'divider', alignItems: 'center' }}>
                <Typography>{t('overseer_app.layouts.map_metadata.title', 'Map metadata')}</Typography>
                {newMap && !disabled && !isLoadingStoreMapLayout && <Chip label={t('overseer_app.layouts.new_map', 'New map')} color="primary" size="small" sx={{ ml: 'auto', mr: 1 }} />}
            </AccordionSummary>
            <AccordionDetails sx={{ mt: 0 }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{t('overseer_app.layouts.map_metadata.creation_date', 'Creation date')}</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>{formatLanguageDate(creationDate, i18n.language)} </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{t('overseer_app.layouts.map_metadata.origin', 'Origin')}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>x: </Typography>
                        <Typography variant="body2">{metadata?.origin?.x || 0.0}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>y: </Typography>
                        <Typography variant="body2">{metadata?.origin?.y || 0.0}</Typography>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}