import { connect } from 'react-redux';
import MappingView from '../../../components/mappingView/MappingView';
import { setSnackAlert } from 'core/slices/snackAlert';
import {
  getMappingJobs,
  postMappingJob,
  deleteMappingJob,
  patchMappingJob,
} from 'core/slices/mappingJobs';
import {
  getLocalBucketMapLayout,
  getMappingJobsList,
  isLoadingMappingJobsList,
  getLocalAvailableStores,
  getLocalAvailableFloors,
  isLoadingAvailableStores,
  isLoadingAvailableFloors,
  isLoadingBucketMapLayout,
  postMappingJobResponse,
  isLoadingPostMappingJob,
  getDeleteJobResponse,
  isLoadingDeleteJob,
  getJobUpdateResponse,
  isLoadingJobUpdate,
} from 'core/selectors';
import {
  getAvailableFloors,
  getAvailableStores,
  getBucketMapLayout,
} from 'core/slices/layouts';

export default connect(
  (state) => ({
    mappingJobsList: getMappingJobsList(state),
    loadingMappingJobs: isLoadingMappingJobsList(state),
    bucketMapLayout: getLocalBucketMapLayout(state),
    availableStoresDict: getLocalAvailableStores(state),
    isLoadingAvailableStores: isLoadingAvailableStores(state),
    availableFloorsList: getLocalAvailableFloors(state),
    isLoadingAvailableFloors: isLoadingAvailableFloors(state),
    isLoadingBucketMapLayout: isLoadingBucketMapLayout(state),
    postMappingJobResponse: postMappingJobResponse(state),
    isLoadingPostMappingJob: isLoadingPostMappingJob(state),
    deleteJobResponse: getDeleteJobResponse(state),
    isLoadingDeleteJob: isLoadingDeleteJob(state),
    jobUpdateResponse: getJobUpdateResponse(state),
    isLoadingJobUpdate: isLoadingJobUpdate(state),
  }),
  {
    setSnackAlert,
    getMappingJobs,
    getBucketMapLayout,
    getAvailableStores,
    getAvailableFloors,
    postMappingJob,
    deleteMappingJob,
    patchMappingJob,
  }
)(MappingView);
