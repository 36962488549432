export default function DataSourceService({ baseUrl, http }) {
  const PATH_PIPELINE_MONITORING = '/pipeline_processing';
  const PATH_METRIC_BASED_ALERTS = '/metric_based_alerts';
  const PATH_BALDUR_API = '/baldur-api';
  const PATH_ACTIONS = '/actions';
  const PATH_CAMERAS = '/cameras';
  const PATH_API = '/api';
  const PATH_CLI_API = '/cli-api';
  const PATH_CMI_API = '/cmi-api';

  const PATH_PROCESS_FLOW = `${PATH_PIPELINE_MONITORING}/process_flow`;
  const PATH_JOBS_COMPLETED = `${PATH_PIPELINE_MONITORING}/jobs_completed`;
  const PATH_PROCESSES_DATA = `${PATH_PIPELINE_MONITORING}/processes_data`;
  const PATH_TOTAL_AISLES = `${PATH_PIPELINE_MONITORING}/total_aisles`;
  const PATH_OBJECT_NAMES = `${PATH_PIPELINE_MONITORING}/object_names`;
  const PATH_BATCHES_RECEIVED = `${PATH_PIPELINE_MONITORING}/batches_received`;
  const PATH_BATCHES_RECEIVED_BY_AISLE = `${PATH_PIPELINE_MONITORING}/batches_received_by_aisle`;
  const PATH_SESSION_PROGRESS_TABLE = `${PATH_PIPELINE_MONITORING}/v1/session_progress_table`;

  const PATH_STORE_DATA = `${PATH_PIPELINE_MONITORING}/store_data`;
  const PATH_SERVICE_STATISTICS = `${PATH_PIPELINE_MONITORING}/service_statistics`;
  const PATH_NAVIGATION_DATA = `${PATH_PIPELINE_MONITORING}/navigation_data`;
  const PATH_CLOUD_RUN_SERVICE_METRICS = `${PATH_PIPELINE_MONITORING}/service_data`;
  const PATH_SESSION_PROGRESS = `${PATH_PIPELINE_MONITORING}/session_progress`;
  const PATH_SERVICE_METADATA = `${PATH_PIPELINE_MONITORING}/service_metadata`;
  const PATH_VERTEX_METADATA = `${PATH_PIPELINE_MONITORING}/vertex_metadata`;
  const PATH_TASKS_METADATA = `${PATH_PIPELINE_MONITORING}/tasks_metadata`;
  const PATH_TASKS_PROGRESS = `${PATH_PIPELINE_MONITORING}/tasks_progress`;
  //const PATH_SERVICE_LOGS = `${PATH_PIPELINE_MONITORING}/service_logs`;
  const PATH_BATCH_LATENCY = `${PATH_PIPELINE_MONITORING}/batch_latency`;
  const PATH_JOBS_LATENCIES = `${PATH_PIPELINE_MONITORING}/jobs_latencies`;
  const PATH_SIGN_BLOB = `${PATH_PIPELINE_MONITORING}/sign_blob`;

  const PATH_SESSION_DATA = `${PATH_PIPELINE_MONITORING}/session_data`;

  const PATH_FINISH_SESSION = `${PATH_PIPELINE_MONITORING}/finish_session`;
  const PATH_PP_NODES = `${PATH_PIPELINE_MONITORING}/v1/nodes`;

  const PATH_USER = '/user_info';
  const PATH_NAVIGATION_OPTIONS = '/navigation_options';

  const PATH_SERVICE_ALERT_POLICIES = `${PATH_METRIC_BASED_ALERTS}/service_alert_policies`;
  const PATH_ALL_POLICIES = `${PATH_METRIC_BASED_ALERTS}/all_policies`;
  const PATH_INCIDENT = `${PATH_METRIC_BASED_ALERTS}/incident`;
  const PATH_ALERT_POLICY_LIST = `${PATH_METRIC_BASED_ALERTS}/project`;
  const PATH_POLICY = `${PATH_METRIC_BASED_ALERTS}/project`;

  // Backend
  const PATH_BACKEND_CHAINS = '/chains';
  const PATH_BACKEND_ROBOTS = '/robots';
  const PATH_BACKEND_POSSIBLE_BROKERS = '/possible_brokers';
  const PATH_BACKEND_STORE = '/store_info';

  const PATH_BALDUR_ROBOTS = `/robots_by_chains`;
  const PATH_SESSION_STATE = '/sessions/state';

  // Scheduler
  const PATH_SESSIONS_ROBOT = `/sessions`;

  // Sessions
  const PATH_SESSIONS = `${PATH_BALDUR_API}/sessions`;
  const PATH_DELETE_SESSIONS = `${PATH_BALDUR_API}/sessions/delete`;

  // Alerts Widget
  const PATH_HISTORIC_ALERTS = `/historic_alerts`;
  const PATH_ACTIVE_ALERTS = `/active_alerts`;

  // Hardware Alerts Widget
  const PATH_ACTIVE_HARDWARE_ALERTS = '/active-hardware-alerts';
  const PATH_HISTORIC_HARDWARE_ALERTS = '/historic-hardware-alerts';

  // Actions
  const PATH_ACTIONS_HISTORY = `${PATH_ACTIONS}/history`;

  // API
  const PATH_MAP_STORE = `${PATH_API}/v1/map_store`;

  //Hardware Components Widget
  const PATH_HARDWARE_COMPONENTS = '/hardware_components';

  const PATH_ROBOT_ACTIONS = '/command-actions';
  const PATH_USER_ACTIONS = '/user-actions';
  const PATH_ROBOTS_TAKEN = '/robots-taken';
  const PATH_TAKE_ROBOT = '/take-robot';
  const PATH_LEAVE_ROBOT = '/leave-robot';
  const PATH_USER_ACTIONS_REASON = '/user_action_reason';
  const PATH_ROBOTS_BY_STATUS = '/robots-by-status';

  // Robot status history
  const PATH_ROBOT_STATUS_HISTORY = `${PATH_BACKEND_ROBOTS}/status_history`;

  // Servicing
  const PATH_SERVICING = '/servicing';
  const PATH_ROBOT_COMPONENTS = '/robot-components';

  // Servicing History Widget
  const PATH_SERVICING_HISTORY = '/servicing_history';
  const PATH_CHANGE_MOTIVES = '/change_motives';

  // Groups
  const PATH_GROUP = `/groups`;

  // Alerts Manager
  const PATH_ALERTS = `/alert_management`;
  const PATH_AVAILABLE_ALERTS = `/available_alerts`;
  const PATH_GROUP_ALERTS = '/group_alerts';

  // Cameras Widget
  const PATH_FOOTAGE_BUCKET = `${PATH_CAMERAS}/footage_bucket`;
  const PATH_RESOLUTION_OPTIONS = `${PATH_CAMERAS}/resolution_options`;
  const PATH_SPEED_OPTIONS = `${PATH_CAMERAS}/speed_options`;

  // Cloud Logging Interface
  const PATH_CLI_FILTERS = `${PATH_CLI_API}/cli_filters`;
  // Cloud Monitoring Interface
  const PATH_CMI_POLICY = `${PATH_CMI_API}/policy`;

  const PATH_PRODUCTS_SEARCH = '/products/search';
  // Data chart
  const PATH_DATA_CHART = '/chart_data';

  // Robot Configuration
  const PATH_ROBOT_CONFIGURATION = '/robot_configuration';

  // Monitoring
  const PATH_WORKSHIFT = '/workshift';
  const PATH_MONITOR_ACTIVITY = '/monitor-activity';
  const PATH_MONITOR_STATUS = '/monitor-status';
  const PATH_ROBOT_STATE = '/robot-state';
  const PATH_ALL_STATUS = '/all-status';
  const PATH_STATE_HISTORY = '/state-history';
  const PATH_STATE_MOTIVES = '/state-motives';

  // Events
  const PATH_EVENT_INCIDENTS = '/events/incidents';
  const PATH_EVENT_INCIDENTS_STATS = '/events/incidents/stats';

  const PATH_FEATURE_PERMISSION = '/feature/permission';
  const PATH_MAP_SIGNAL = '/map-signal';
  const PATH_EVENTS_TIMELINE_TRACE = '/events/historical/trace';
  const PATH_MAPPING_ALERTS = '/mapping-alerts';

  // Metrics
  const PATH_METRICS_OPTIONS = '/metrics/list';
  const PATH_SPECIFIC_METRIC = '/metrics/specific-metric';
  const PATH_REALTIME_DATA = '/metrics/realtime-metric';

  const PATH_MONITOR_METRICS_DATA = '/active-monitors';
  const NAVIGATION_STATS = '/api/v1/navigation/stats';
  const PATH_NAVIGATION_STATUS = '/navigation-status';

  // ClickUp
  const PATH_CLICKUP_TASK = '/clickup/task';
  const PATH_CLICKUP_TEAMS = '/clickup/teams';

  // Layouts
  const PATH_MAP_LAYOUT = '/robot-setup/map-layout';
  const PATH_BUCKET_MAP_LAYOUT = '/robot-setup/bucket_map_layout';

  // Robot Status
  const PATH_ROBOT_STATUS = '/robot-status';
  const PATH_DOWNTIME_MOTIVES = '/downtime-motives';
  const PATH_ROBOT_STATES = '/robot-states-data';
  const PATH_ROBOT_COUNT = '/robot-daily-count';
  const PATH_ROBOT_STATUSES = '/robot-statuses';

  const PATH_CALLER_MOTIVES = '/call-motives';

  const STORE_COUNTRY = '/countries';

  // Store
  const PATH_CALLER_ACTIONS = '/store/call';
  const STORE_CONTACTS = '/store/contacts';
  const STORE_UTC_OFFSET = '/store/utc-offset';
  const STORE_CONFIG_PATH = '/store/management';

  const COUNTRIES_PATH = '/store/countries';
  const GMAPS_KEY_PATH = '/gmaps/key';

  // Layouts View
  const AVAILABLE_STORES_PATH = '/api/v1/active_stores';
  const AVAILABLE_FLOORS_PATH = '/api/v1/store_floor';
  const GLOBAL_LAYERS_PATH = '/api/v1/layers';
  const LAYOUT_VERSIONS_PATH = '/api/v1/versions';
  const STORE_LAYOUT_MAP_PATH = '/api/v1/layouts/map';
  const STORE_LAYOUT_PATH = '/api/v1/layouts';

  // Mapping Jobs
  const MAPPING_JOBS_PATH = '/mapping-jobs';

  return {
    getProcessFlow,
    getJobsCompleted,
    getProcessesData,
    getTotalAisles,
    getObjectNames,
    getBatchesReceived,
    getBatchesReceivedByAisle,
    getStoreData,
    getUserInfo,
    getNavigationData,
    getNavigationOption,
    getServiceMetrics,
    getSessionProgress,
    getServiceMetadata,
    postServiceMetadata,
    updateServiceTraffic,
    getVertexMetadata,
    updateVertexMetadata,
    updateVertexTraffic,
    getTasksMetadata,
    updateTasksMetadata,
    finishSession,
    getPPNodes,
    getSessionProgressTable,
    getTasksProgress,
    getServiceLogs,
    getAlerts,
    getAlertPolicies,
    addAlertPolicy,
    deleteAlertPolicy,
    editAlertPolicy,
    getSessionData,
    getAllPolicies,
    getChains,
    getRobots,
    getRobotsBaldur,
    getBatchLatency,
    getJobsLatencies,
    updateSession,
    deleteSessions,
    signBlob,
    getHistoricAlerts,
    getActiveAlerts,
    getHardwareComponents,
    getRobotActions,
    postUserAction,
    getRobotsTaken,
    getRobotsByStatus,
    getRobotStatusHistory,
    postTakeRobot,
    postLeaveRobot,
    getDefaultHardwareComponents,
    getServicingHistory,
    getChangeMotives,
    patchChangeReasons,
    getActionsHistory,
    getStoreMap,
    postNewMap,
    postServicing,
    getRobotComponents,
    getGroupsData,
    postGroupsData,
    deleteGroup,
    updateGroup,
    getAllCreatedAlerts,
    getAvailableAlerts,
    getGroupAlertsRelation,
    postGroupAlertsRelation,
    deleteGroupAlertRelation,
    getFootageBucket,
    getResolutionOptions,
    getSpeedOptions,
    createNewAlert,
    updateAlert,
    deleteAlert,
    getCLIFilters,
    getProducts,
    getBroker,
    getStore,
    getDataChart,
    getCMIPolicies,
    getRobotsStatus,
    updateRobotsStatus,
    getWorkshiftInfo,
    postWorkshift,
    postMonitorActivity,
    postMonitorStatus,
    getEventsList,
    updateEventStatus,
    getFeaturePermission,
    getMapSignal,
    getMappingAlerts,
    getTimelineTrace,
    getMetricsOptionsList,
    getSpecificMetricData,
    getRealTimeData,
    getMonitorMetricsData,
    getNavigationStats,
    getNavigationStatus,
    postClickUpTask,
    getClickUpTeamsList,
    getRobotState,
    getAllStatus,
    updateRobotState,
    getRobotStateHistory,
    getAllRobotMotives,
    getActiveHardwareAlerts,
    getHardwareAlertsHistory,
    postActionReason,
    getActionReasons,
    getRobotLayout,
    getBucketMapLayout,
    getSessionsState,
    getRobotsOverview,
    getDowntimeMotives,
    getRobotStateData,
    getRobotDailyData,
    getRobotStatuses,
    postCallerAction,
    getCallerActionHistory,
    getStoreCountry,
    getStoreContacts,
    putStoreContacts,
    getCallMotives,
    getStoresUtcOffset,
    getStoreConfigs,
    postNewStoreConfig,
    updateStoreConfig,
    deleteStore,
    getCountries,
    getMapsKey,
    getEventsStats,
    getAvailableStores,
    getAvailableFloors,
    getGlobalLayers,
    getLayoutVersions,
    getStoreMapLayout,
    getStoreLayout,
    postStoreLayout,
    getMappingJobs,
    postNewMappingJob,
    deleteMappingJob,
    updateMappingJob,
  };

  function getProcessFlow() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PROCESS_FLOW}`,
    });
  }

  function getJobsCompleted({ date, store, robot }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_JOBS_COMPLETED}`,
      query: { date, store, robot },
    });
  }

  function getProcessesData(chain_code, store) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PROCESSES_DATA}`,
      query: { chain_code, store },
    });
  }
  function getTotalAisles({ date, store, robot }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TOTAL_AISLES}`,
      query: { date, store, robot },
    });
  }
  function getObjectNames() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_OBJECT_NAMES}`,
    });
  }
  function getBatchesReceived({ date, store, robot }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BATCHES_RECEIVED}`,
      query: { date, store, robot },
    });
  }
  function getBatchesReceivedByAisle({ date, store, robot }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BATCHES_RECEIVED_BY_AISLE}`,
      query: { date, store, robot },
    });
  }
  function getStoreData() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STORE_DATA}`,
    });
  }

  function getUserInfo(username) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER}`,
      query: {
        username,
      },
    });
  }

  function getNavigationData({ date, store, robot }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_NAVIGATION_DATA}`,
      query: { date, store, robot },
    });
  }

  function signBlob({ store, blobPath }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SIGN_BLOB}`,
      body: { store, blob: blobPath },
    });
  }

  function getNavigationOption() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_NAVIGATION_OPTIONS}`,
    });
  }

  function getServiceMetrics(
    metrics_type,
    hours,
    is_vertex,
    service_name,
    project_id
  ) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CLOUD_RUN_SERVICE_METRICS}`,
      query: { metrics_type, hours, is_vertex, service_name, project_id },
    });
  }

  function getSessionProgress(session, process_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SESSION_PROGRESS}`,
      query: { session, process_id },
    });
  }

  function getServiceMetadata(service_name, project_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SERVICE_METADATA}`,
      query: { service_name, project_id },
    });
  }

  function postServiceMetadata(service_name, project_id, changes, revision_id) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SERVICE_METADATA}`,
      body: { service_name, project_id, changes, revision_id },
    });
  }

  function updateServiceTraffic(service_name, project_id, traffic) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_SERVICE_METADATA}`,
      body: { service_name, project_id, traffic },
    });
  }

  function getVertexMetadata(process_id, chain) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_VERTEX_METADATA}`,
      query: { process_id, chain },
    });
  }

  function updateVertexMetadata(process_id, chain, model_id, changes) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_VERTEX_METADATA}`,
      body: { process_id, chain, model_id, changes },
    });
  }

  function updateVertexTraffic(process_id, chain, traffic) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_VERTEX_METADATA}`,
      body: { process_id, chain, traffic },
    });
  }

  function getTasksMetadata(service_name, project_id, task_name) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TASKS_METADATA}`,
      query: { service_name, project_id, task_name },
    });
  }

  function updateTasksMetadata(service_name, project_id, task_name, changes) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_TASKS_METADATA}`,
      body: { service_name, project_id, task_name, changes },
    });
  }

  function finishSession({ session, object_type }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_FINISH_SESSION}`,
      body: { session, object_type },
    });
  }

  function getPPNodes(payload = { session: '', hard_finished_sessions: '' }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_PP_NODES}`,
      body: payload,
    });
  }

  function getSessionProgressTable({ date, store, robot, chain_code }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SESSION_PROGRESS_TABLE}`,
      query: { date, store, robot, chain_code },
    });
  }

  function getTasksProgress({
    store,
    session_id,
    queue_name,
    project_id,
    service_name,
  }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TASKS_PROGRESS}`,
      query: { store, session_id, queue_name, project_id, service_name },
    });
  }

  function getServiceLogs({ project_id, service_name, quantity }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SERVICE_LOGS}`,
      query: { project_id, service_name, quantity },
    });
  }

  function getAlerts({ store, robot, metric_type }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_INCIDENT}`,
      query: { store, robot, metric_type },
    });
  }

  function getAlertPolicies({ project_id, service_name, metrics_type }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SERVICE_ALERT_POLICIES}`,
      query: { project_id, service_name, metrics_type },
    });
  }

  function addAlertPolicy({ project_id, policy_data }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_ALERT_POLICY_LIST}/${project_id}/policy`,
      body: policy_data,
    });
  }

  function deleteAlertPolicy({ project_id, policy_name }) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_POLICY}/${project_id}/policy/${policy_name}`,
    });
  }

  function editAlertPolicy({ project_id, policy_data, policy_name }) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_POLICY}/${project_id}/policy/${policy_name}`,
      body: policy_data,
    });
  }

  function getSessionData({ session }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SESSION_DATA}`,
      query: { session },
    });
  }

  function getAllPolicies() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ALL_POLICIES}`,
    });
  }

  // Backend
  function getChains() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BACKEND_CHAINS}`,
    });
  }

  function getRobots(chains) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BACKEND_ROBOTS}`,
      query: { chains },
    });
  }

  function getBroker(storeCode) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BACKEND_POSSIBLE_BROKERS}`,
      query: { store_code: storeCode },
    });
  }

  function getStore(storeCode) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BACKEND_STORE}`,
      query: { store: storeCode },
    });
  }

  // Baldur
  function getRobotsBaldur({
    chains,
    robotCode: robot_code,
    robot_status,
    include_basemap_info,
  }) {
    const query = {};

    if (chains !== undefined) {
      query.chains = JSON.stringify(chains);
    }
    if (robot_code !== undefined) {
      query.robot_code = robot_code;
    }
    if (robot_status !== undefined) {
      query.robot_status = JSON.stringify(robot_status);
    }
    if (include_basemap_info !== undefined) {
      query.include_basemap_info = include_basemap_info;
    }

    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BALDUR_ROBOTS}`,
      query,
    });
  }

  function getBatchLatency({ project_id, object_id, hours, session }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BATCH_LATENCY}`,
      query: { project_id, object_id, hours, session },
    });
  }

  function getJobsLatencies({ project_id, processes_list, hours, session }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_JOBS_LATENCIES}`,
      body: { project_id, processes_list, hours, session },
    });
  }

  //Scheduler
  function updateSession(
    sessions_id,
    oh_mode,
    local_start_time,

    local_start_date,
    aisles,
    week_days
  ) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS}`,
      body: {
        sessions_id,
        oh_mode,
        local_start_time,
        local_start_date,
        aisles,
        week_days,
      },
    });
  }

  function deleteSessions(sessions_id, date) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_DELETE_SESSIONS}`,
      body: { sessions_id, date },
    });
  }

  function getHistoricAlerts({ robot_id }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_HISTORIC_ALERTS}`,
      query: { robot_id },
    });
  }
  function getActiveAlerts({ robot_id }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ACTIVE_ALERTS}`,
      query: { robot_id },
    });
  }

  function getHardwareComponents({ robot_id }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_HARDWARE_COMPONENTS}`,
      query: { robot_id },
    });
  }

  function getRobotActions() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_ACTIONS}`,
    });
  }

  function postUserAction(action, robot, extra_info) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_USER_ACTIONS}`,
      body: { action, robot, extra_info },
    });
  }

  function postActionReason(modalForm) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_USER_ACTIONS_REASON}`,
      body: modalForm,
    });
  }

  function getActionReasons(form) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER_ACTIONS_REASON}`,
      query: form,
    });
  }

  function getRobotsTaken(robot = null) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOTS_TAKEN}`,
      query: { robot },
    });
  }

  function getRobotsByStatus({ chain, status }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOTS_BY_STATUS}`,
      query: { chain, status },
    });
  }

  function getRobotStatusHistory({ robotCode, hoursBefore }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_STATUS_HISTORY}`,
      query: { robot_code: robotCode, hours_before: hoursBefore },
    });
  }

  function postTakeRobot(robot, custom_timeout, kick_user) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_TAKE_ROBOT}`,
      body: { robot, custom_timeout, kick_user },
    });
  }

  function postLeaveRobot(robot) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_LEAVE_ROBOT}`,
      body: { robot },
    });
  }

  function getDefaultHardwareComponents({ robot_id, is_factory_hardware }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_HARDWARE_COMPONENTS}`,
      query: { robot_id, is_factory_hardware },
    });
  }

  function getServicingHistory({ robot_id }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SERVICING_HISTORY}`,
      query: { robot_id },
    });
  }

  function getChangeMotives() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CHANGE_MOTIVES}`,
    });
  }

  function patchChangeReasons({
    servicing_id,
    change_motives,
    change_reasons,
  }) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_SERVICING_HISTORY}`,
      body: { servicing_id, change_motives, change_reasons },
    });
  }

  // Actions
  function getActionsHistory(store, robotCode) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ACTIONS_HISTORY}`,
      query: robotCode ? { store, robot_code: robotCode } : { store },
    });
  }

  // Planograms
  function getStoreMap({ robot_code }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_MAP_STORE}`,
      query: { robot_code },
    });
  }

  function postNewMap(fileForm) {
    return http.postAuthenticated({
      content: 'raw',
      url: `${baseUrl}${PATH_MAP_STORE}`,
      body: fileForm,
    });
  }

  function getRobotComponents(robot_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_COMPONENTS}`,
      query: { robot_code },
    });
  }

  function postServicing(
    component_name,
    component_id,
    component_model,
    component_serial_number,
    robot_code,
    date,
    motive,
    metadata,
    comments
  ) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SERVICING}`,
      body: {
        component_name,
        component_id,
        component_model,
        component_serial_number,
        robot_code,
        date,
        motive,
        metadata,
        comments,
      },
    });
  }

  // Groups
  function getGroupsData() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_GROUP}`,
    });
  }

  function postGroupsData(group_name) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_GROUP}`,
      body: { group_name },
    });
  }

  function deleteGroup(group_id) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_GROUP}`,
      body: { group_id },
    });
  }

  function updateGroup(group_id, group_name) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_GROUP}`,
      body: { group_id, group_name },
    });
  }

  //Alerts manager
  function getAllCreatedAlerts() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ALERTS}`,
    });
  }

  function getAvailableAlerts() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_AVAILABLE_ALERTS}`,
    });
  }

  function createNewAlert(alertData) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_ALERTS}`,
      body: { alertData },
    });
  }

  function updateAlert(name, title_name, icon, description, old_name) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_ALERTS}`,
      body: { name, title_name, icon, description, old_name },
    });
  }

  function deleteAlert(alert_name) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_ALERTS}`,
      body: { alert_name },
    });
  }

  function getGroupAlertsRelation() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_GROUP_ALERTS}`,
    });
  }

  function postGroupAlertsRelation(alerts_list, group_id) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_GROUP_ALERTS}`,
      body: { alerts_list, group_id },
    });
  }

  function deleteGroupAlertRelation(alerts_list) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_GROUP_ALERTS}`,
      body: { alerts_list },
    });
  }

  // Cameras
  function getFootageBucket() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_FOOTAGE_BUCKET}`,
    });
  }

  function getResolutionOptions() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_RESOLUTION_OPTIONS}`,
    });
  }

  function getSpeedOptions() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SPEED_OPTIONS}`,
    });
  }

  // Cloud Logging Interface
  function getCLIFilters({ project_name, metric_kind, metric_name }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CLI_FILTERS}`,
      query: { project_name, metric_kind, metric_name },
    });
  }

  function getProducts(name, store, limit) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PRODUCTS_SEARCH}`,
      query: { name, store, limit },
    });
  }
  // Data chart alerts
  function getDataChart(
    service_name,
    project_id,
    metric_type,
    delta_time,
    alignment_period
  ) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_DATA_CHART}`,
      query: {
        service_name,
        project_id,
        metric_type,
        delta_time,
        alignment_period,
      },
    });
  }
  // Cloud Monitoring Interface
  function getCMIPolicies({ project_name, policy_name }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CMI_POLICY}`,
      query: { project_name, policy_name },
    });
  }

  // Robot status configuration
  function getRobotsStatus() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_CONFIGURATION}`,
      query: {},
    });
  }

  function updateRobotsStatus({ status_id, chains }) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_CONFIGURATION}`,
      body: { status_id, chains },
    });
  }

  function getWorkshiftInfo(user_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WORKSHIFT}`,
      query: {
        user_id,
      },
    });
  }

  function postWorkshift(user_id, action, events_list) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_WORKSHIFT}`,
      body: { user_id, action, events_list },
    });
  }

  function postMonitorActivity(
    user_id,
    events_list,
    workshift_id,
    activity_type
  ) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_MONITOR_ACTIVITY}`,
      body: { user_id, events_list, workshift_id, activity_type },
    });
  }

  function postMonitorStatus(user_id, active_status) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_MONITOR_STATUS}`,
      body: { user_id, active_status },
    });
  }

  function getEventsList(all_active_events, time_range, assigned_to_user) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_EVENT_INCIDENTS}`,
      query: { all_active_events, time_range, assigned_to_user },
    });
  }

  function getEventsStats() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_EVENT_INCIDENTS_STATS}`,
      query: {},
    });
  }

  function updateEventStatus(robot_event_id, new_status, is_escalate) {
    let body = { robot_event_id, new_status };
    if (is_escalate !== null) body['is_escalate'] = is_escalate;
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_EVENT_INCIDENTS}`,
      body: body,
    });
  }

  function getFeaturePermission(feature) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_FEATURE_PERMISSION}`,
      query: { feature },
    });
  }

  function getMapSignal(store_code, map_data, signal_type) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_MAP_SIGNAL}`,
      query: {
        store_code,
        map_data,
        signal_type,
      },
    });
  }

  function getMappingAlerts(robot_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_MAPPING_ALERTS}`,
      query: { robot_code },
    });
  }

  function getTimelineTrace(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_EVENTS_TIMELINE_TRACE}`,
      query: payload,
    });
  }

  // Metrics
  function getMetricsOptionsList() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_METRICS_OPTIONS}`,
    });
  }

  function getSpecificMetricData(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SPECIFIC_METRIC}`,
      query: payload,
    });
  }

  function getRealTimeData() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_REALTIME_DATA}`,
    });
  }

  function getMonitorMetricsData(time_range, personal_info) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_MONITOR_METRICS_DATA}`,
      query: { personal_info, time_range },
    });
  }

  function getNavigationStats(planned_for, navigation_status) {
    return http.getAuthenticated({
      url: `${baseUrl}${NAVIGATION_STATS}`,
      query: { planned_for, navigation_status },
    });
  }

  function getNavigationStatus(view) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_NAVIGATION_STATUS}`,
      query: { view },
    });
  }

  function postClickUpTask(
    robotEventId,
    destination,
    comments,
    storeCode,
    storeName,
    chainName,
    robotCode,
    alerts,
    mainAlert,
    robotUrl
  ) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_CLICKUP_TASK}`,
      body: {
        robotEventId,
        destination,
        comments,
        storeCode,
        storeName,
        chainName,
        robotCode,
        alerts,
        mainAlert,
        robotUrl,
      },
    });
  }

  function getClickUpTeamsList() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CLICKUP_TEAMS}`,
    });
  }

  function getAllStatus() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ALL_STATUS}`,
    });
  }

  function getRobotState(robot_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_STATE}`,
      query: { robot_code },
    });
  }

  function updateRobotState(robot_code, state_id, motive, comment = '') {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_STATE}`,
      body: { robot_code, state_id, motive, comment },
    });
  }

  function getRobotStateHistory(robot_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STATE_HISTORY}`,
      query: { robot_code },
    });
  }

  function getAllRobotMotives() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STATE_MOTIVES}`,
    });
  }

  function getActiveHardwareAlerts(robot_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ACTIVE_HARDWARE_ALERTS}`,
      query: { robot_code },
    });
  }

  function getHardwareAlertsHistory(robot_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_HISTORIC_HARDWARE_ALERTS}`,
      query: { robot_code },
    });
  }

  function getRobotLayout(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_MAP_LAYOUT}`,
      query: payload,
    });
  }

  function getBucketMapLayout(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BUCKET_MAP_LAYOUT}`,
      query: payload,
    });
  }

  function getRobotsOverview() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_STATUS}`,
    });
  }

  function getDowntimeMotives() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_DOWNTIME_MOTIVES}`,
    });
  }

  function getRobotStateData() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_STATES}`,
    });
  }

  function getSessionsState(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SESSION_STATE}`,
      query: payload,
    });
  }

  function getRobotDailyData(states) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_COUNT}`,
      query: { states },
    });
  }

  function getRobotStatuses() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_STATUSES}`,
    });
  }

  function getCallerActionHistory(store) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CALLER_ACTIONS}`,
      query: { store },
    });
  }

  function postCallerAction(
    store_code,
    phone_number,
    store_contact_id,
    alert_description,
    motive
  ) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_CALLER_ACTIONS}`,
      body: {
        store_code,
        phone_number,
        store_contact_id,
        alert_description,
        motive,
      },
    });
  }

  function getStoreCountry(store_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${STORE_COUNTRY}`,
      query: { store_code },
    });
  }

  // Store
  function getStoreContacts() {
    return http.getAuthenticated({
      url: `${baseUrl}${STORE_CONTACTS}`,
    });
  }

  function putStoreContacts(store_contact_id, store_id, phones, emails) {
    return http.putAuthenticated({
      url: `${baseUrl}${STORE_CONTACTS}`,
      body: { store_contact_id, store_id, phones, emails },
    });
  }

  function getCallMotives() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CALLER_MOTIVES}`,
    });
  }

  function getStoresUtcOffset(storeCoordinates) {
    return http.getAuthenticated({
      url: `${baseUrl}${STORE_UTC_OFFSET}`,
      query: { store_coordinates: storeCoordinates },
    });
  }

  function getStoreConfigs() {
    return http.getAuthenticated({
      url: `${baseUrl}${STORE_CONFIG_PATH}`,
    });
  }

  function postNewStoreConfig(storeConfig) {
    return http.postAuthenticated({
      url: `${baseUrl}${STORE_CONFIG_PATH}`,
      body: { storeConfig: JSON.stringify(storeConfig) },
    });
  }

  function updateStoreConfig(code, changes) {
    return http.patchAuthenticated({
      url: `${baseUrl}${STORE_CONFIG_PATH}`,
      body: { code, changes: JSON.stringify(changes) },
    });
  }

  function deleteStore(store_code) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${STORE_CONFIG_PATH}`,
      body: { store_code },
    });
  }

  function getCountries() {
    return http.getAuthenticated({
      url: `${baseUrl}${COUNTRIES_PATH}`,
    });
  }

  function getMapsKey() {
    return http.getAuthenticated({
      url: `${baseUrl}${GMAPS_KEY_PATH}`,
    });
  }

  function getAvailableStores() {
    return http.getAuthenticated({
      url: `${baseUrl}${AVAILABLE_STORES_PATH}`,
    });
  }
  function getAvailableFloors(store) {
    return http.getAuthenticated({
      url: `${baseUrl}${AVAILABLE_FLOORS_PATH}`,
      query: { store_code: store.store },
    });
  }

  function getGlobalLayers() {
    return http.getAuthenticated({
      url: `${baseUrl}${GLOBAL_LAYERS_PATH}`,
    });
  }

  function getLayoutVersions(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${LAYOUT_VERSIONS_PATH}`,
      query: payload,
    });
  }

  function getStoreMapLayout(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${STORE_LAYOUT_MAP_PATH}`,
      query: payload,
    });
  }

  function getStoreLayout(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${STORE_LAYOUT_PATH}`,
      query: payload,
    });
  }

  function postStoreLayout(payload) {
    return http.postAuthenticated({
      url: `${baseUrl}${STORE_LAYOUT_PATH}`,
      body: payload,
    });
  }

  function getMappingJobs(state) {
    return http.getAuthenticated({
      url: `${baseUrl}${MAPPING_JOBS_PATH}`,
      query: state,
    });
  }

  function postNewMappingJob(robot_id, zones) {
    return http.postAuthenticated({
      url: `${baseUrl}${MAPPING_JOBS_PATH}`,
      body: { robot_id, zones },
    });
  }

  function deleteMappingJob(job_id) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${MAPPING_JOBS_PATH}`,
      body: { job_id },
    });
  }

  function updateMappingJob(job_id, values) {
    return http.patchAuthenticated({
      url: `${baseUrl}${MAPPING_JOBS_PATH}`,
      body: { job_id, values },
    });
  }
}
