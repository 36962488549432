import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSnackAlert } from "core/slices/snackAlert";
import { transformPixels2Robot, calculateBoundingBox, calculatePolygonBoundingBox } from '../Utils';
import useCanvas from "./useCanvas";
import { drawRectangleOnCanvas, canvasDrawOnLine, clearCanvas, drawPinOnCanvas, drawOrientedRectangle, canvasDrawMultipleLines, drawPolygon } from '../utils/canvasFunctions';
import { useTranslation } from "react-i18next";
import { AISLES_LAYER } from "./useLayout";

export const ORIENTATIONS = ['N', 'S', 'E', 'W', 'P', 'I'];
export const LAYOUT_TOOLS = {
    MOVE: 'move',
    ZONE: 'zones',
    AISLE: 'aisles',
    EXCLUSION_ZONE: 'exclusion_zones',
    AISLE_SIDE: 'aisle_side',
    HOME: 'home',
    FREE_AREA: 'free_area',
    FREE_LINE: 'free_line',
    FREE_PIN: 'free_pin',
}

export const DRAWING_WAYS = {
    RECTANGLE: 'rectangle',
    LINE: 'line',
    POSE: 'pose',
    BBOX: 'bbox',
    ORIENTED_BBOX: 'oriented_bbox',
    POLYGON: 'polygon',
}

export const DRAWING_WAYS_TOOLS = {
    [LAYOUT_TOOLS.ZONE]: [
        DRAWING_WAYS.BBOX,
        DRAWING_WAYS.ORIENTED_BBOX,
    ],
    [LAYOUT_TOOLS.AISLE]: [
        DRAWING_WAYS.BBOX,
        DRAWING_WAYS.ORIENTED_BBOX,
    ],
    [LAYOUT_TOOLS.EXCLUSION_ZONE]: [
        DRAWING_WAYS.BBOX,
        DRAWING_WAYS.ORIENTED_BBOX,
    ],
    [LAYOUT_TOOLS.FREE_AREA]: [
        DRAWING_WAYS.BBOX,
        DRAWING_WAYS.ORIENTED_BBOX,
        DRAWING_WAYS.POLYGON,
    ],
}

const SCROLL_SPEED_FACTOR = 0.1;

const MIN_DISTANCE_BETWEEN_POINTS = 50; // TODO: Check Area or distance between points to draw a zone / aisle / etc

function isDistanceBetweenPoints(point1, point2) {
    const distance = Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
    return distance > MIN_DISTANCE_BETWEEN_POINTS;
}

export default function useLayoutAnnotations(storeMapCanvasRef, mapMetadata, colissionLayers) {
    const [canvasDrawRef, canvasDraw] = useCanvas();
    const [temporalDraw, setTemporalDraw] = useState(null);
    const [activeTool, setActiveTool] = useState(null);
    const [drawColor, setDrawColor] = useState('#FD7E14');

    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (canvasDrawRef?.current && mapMetadata) {
            const canvas = canvasDrawRef.current;
            canvas.width = mapMetadata.width;
            canvas.height = mapMetadata.height;
            const disableContextMenu = (event) => {
                event.preventDefault();
            };
            canvas.style.webkitTouchCallout = 'none';
            canvas.style.webkitUserSelect = 'none';
            canvas.style.userSelect = 'none';
            canvas.style.WebkitUserDrag = 'none';
            canvas?.addEventListener('contextmenu', disableContextMenu);
            return () => {
                canvas?.removeEventListener('contextmenu', disableContextMenu);
            }
        }
    }, [canvasDrawRef, mapMetadata]);

    const transformToRobot = useCallback((data) => {
        if (!mapMetadata) return;
        const origin = [mapMetadata?.origin?.x, mapMetadata?.origin?.y];
        const resolution = mapMetadata?.resolution;
        const originalHeight = mapMetadata?.height
        return transformPixels2Robot(data.x, data.y, origin, resolution, originalHeight);
    }, [mapMetadata]);

    const autoScrollMovement = useCallback((isDraggingRef, isOutsideRef, outsidePositionRef, elementToMove = null, drawingFunction = null, removeScrollOnStop = false, disableElementMovement = false) => {
        const storeMapParent = storeMapCanvasRef?.current?.parentElement;
        let autoScrollInterval = null;
        const start = () => {
            if (autoScrollInterval) return;
            storeMapParent.style.overflow = 'auto';
            document.body.style.cursor = 'grab';
            autoScrollInterval = setInterval(() => {
                if (!isDraggingRef.current || !isOutsideRef.current) {
                    if (removeScrollOnStop) storeMapParent.style.overflow = 'hidden';
                    document.body.style.cursor = 'default';
                    clearInterval(autoScrollInterval);
                    autoScrollInterval = null;
                    return;
                }
                const rect = storeMapParent.getBoundingClientRect();
                const mouseX = outsidePositionRef.current.x;
                const mouseY = outsidePositionRef.current.y;
                let deltaX = 0, deltaY = 0;
                deltaX = (mouseX < rect.left ? mouseX - rect.left : mouseX > rect.right ? mouseX - rect.right : 0) * SCROLL_SPEED_FACTOR;
                deltaY = (mouseY < rect.top ? mouseY - rect.top : mouseY > rect.bottom ? mouseY - rect.bottom : 0) * SCROLL_SPEED_FACTOR;
                if (elementToMove && !disableElementMovement?.current) {
                    const newX = Math.max(0, Math.min(elementToMove.current.x + deltaX * 0.8, storeMapCanvasRef.current.width));
                    const newY = Math.max(0, Math.min(elementToMove.current.y + deltaY * 0.8, storeMapCanvasRef.current.height));
                    elementToMove.current.x = newX;
                    elementToMove.current.y = newY;
                    drawingFunction?.(elementToMove.current);
                }
                storeMapParent.scrollLeft = Math.max(0, Math.min(
                    storeMapParent.scrollLeft + deltaX,
                    storeMapParent.scrollWidth - storeMapParent.offsetWidth
                ));
                storeMapParent.scrollTop = Math.max(0, Math.min(
                    storeMapParent.scrollTop + deltaY,
                    storeMapParent.scrollHeight - storeMapParent.offsetHeight
                ));
            }, 10);
        };
        const stop = () => {
            if (autoScrollInterval) {
                if (removeScrollOnStop) storeMapParent.style.overflow = 'hidden';
                clearInterval(autoScrollInterval);
                autoScrollInterval = null;
                if (document?.body) document.body.style.cursor = 'default';
            }
        };

        const onMouseMoveOutside = (event) => {
            outsidePositionRef.current = { x: event.clientX, y: event.clientY };
            const rect = storeMapParent.getBoundingClientRect();
            isOutsideRef.current = (
                event.clientX < rect.left ||
                event.clientX > rect.right ||
                event.clientY < rect.top ||
                event.clientY > rect.bottom
            );
            if (isDraggingRef.current && isOutsideRef.current) start();
        };

        return { stop, onMouseMoveOutside };
    }, [storeMapCanvasRef]);

    const handleDragMove = useCallback(() => {
        if (!storeMapCanvasRef?.current || !storeMapCanvasRef.current.parentElement) return;
        const storeMapParent = storeMapCanvasRef.current.parentElement;
        const isDragging = { current: false };
        const isOutside = { current: false };
        const outsidePosition = { current: { x: 0, y: 0 } };
        const { onMouseMoveOutside, stop: stopAutoScroll } = autoScrollMovement(isDragging, isOutside, outsidePosition);
        storeMapCanvasRef.current.style.cursor = 'pointer';

        const onMouseDown = (event) => {
            isDragging.current = true;
            storeMapParent.style.overflow = 'auto';
            storeMapCanvasRef.current.style.cursor = 'grab';
            event.preventDefault();
        };
        const onMouseMove = (event) => {
            if (!isDragging.current) return;
            storeMapParent.scrollLeft -= event.movementX;
            storeMapParent.scrollTop -= event.movementY;
        };
        const onMouseUp = (event) => {
            const storeMapParent = storeMapCanvasRef?.current?.parentElement;
            const rect = storeMapParent.getBoundingClientRect();
            storeMapCanvasRef.current.style.cursor = 'pointer';
            isDragging.current = false;
            storeMapParent.style.overflow = 'hidden';
            const isOut = (
                event.clientX < rect.left ||
                event.clientX > rect.right ||
                event.clientY < rect.top ||
                event.clientY > rect.bottom
            );
            if (!isOut) return;
            isOutside.current = false;
            stopAutoScroll();
            storeMapCanvasRef.current.style.cursor = 'pointer';
        };

        storeMapCanvasRef?.current?.addEventListener('mousedown', onMouseDown);
        storeMapCanvasRef?.current?.addEventListener('mousemove', onMouseMove);
        document?.addEventListener('mouseup', onMouseUp);
        document?.addEventListener('mousemove', onMouseMoveOutside);
        return () => {
            storeMapCanvasRef?.current?.removeEventListener('mousedown', onMouseDown);
            storeMapCanvasRef?.current?.removeEventListener('mousemove', onMouseMove);
            document?.removeEventListener('mouseup', onMouseUp);
            document?.removeEventListener('mousemove', onMouseMoveOutside);
            if (storeMapCanvasRef?.current) storeMapCanvasRef.current.style.cursor = 'default';
            stopAutoScroll();
        };
    }, [storeMapCanvasRef, autoScrollMovement]);

    const handleNewHome = useCallback((callbackFunction, onlyFirstPoint = false) => {
        if (!storeMapCanvasRef?.current || !storeMapCanvasRef.current.parentElement) return;
        const isDragging = { current: false };
        const isOutside = { current: false };
        const handleAng = { current: false };
        const startPoint = { current: { x: 0, y: 0, ang: 0 } };
        const outsidePosition = { current: { x: 0, y: 0 } };
        const clickCount = { current: 0 };
        const drawingFunction = (point) => {
            setTemporalDraw({ type: DRAWING_WAYS.POSE, bbox: [point] });
        }
        const { onMouseMoveOutside, stop: stopAutoScroll } = autoScrollMovement(isDragging, isOutside, outsidePosition, startPoint, drawingFunction, true);
        storeMapCanvasRef.current.style.cursor = 'pointer';
        const onMouseDown = (event) => {
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            if (clickCount.current === 0) {
                startPoint.current = { x: event.clientX - bounding.left, y: event.clientY - bounding.top, ang: -Math.PI / 2, name: 'dock' };
                isDragging.current = true;
                setTemporalDraw({ type: DRAWING_WAYS.POSE, bbox: [startPoint.current] });
            } else if (clickCount.current === 1) {
                isDragging.current = false;
                handleAng.current = true;
                dispatch(setSnackAlert({
                    open: true,
                    severity: 'info',
                    message: t('overseer_app.layouts.creation.adjust_angle', 'Adjust angle of the home point and press again to confirm'),
                }));
            } else if (clickCount.current === 2) {
                isDragging.current = false;
                handleAng.current = false;
                const newX = startPoint.current.x + 30 * Math.cos(startPoint.current.ang + Math.PI)
                const newY = startPoint.current.y + 30 * Math.sin(startPoint.current.ang + Math.PI)
                const base = { x: newX, y: newY, ang: startPoint.current.ang + Math.PI, name: 'base' };
                const exit_jail = { x: newX, y: newY, ang: startPoint.current.ang, name: 'exit_jail' };
                setTemporalDraw(prev => ({
                    ...prev,
                    bbox: [
                        ...prev.bbox,
                        ...(onlyFirstPoint ? [] : [base, exit_jail])
                    ]
                }));
                dispatch(setSnackAlert(null));
                const transformDock = transformToRobot(startPoint.current);
                const transformBase = transformToRobot(base);
                const transformExitJail = transformToRobot(exit_jail);
                callbackFunction?.(onlyFirstPoint ? {
                    x: transformDock[0], y: transformDock[1], ang: startPoint.current.ang + Math.PI / 2,
                } : {
                    dock: { x: transformDock[0], y: transformDock[1], ang: startPoint.current.ang + Math.PI / 2, name: 'dock' },
                    base: { x: transformBase[0], y: transformBase[1], ang: startPoint.current.ang + 1.5 * Math.PI, name: 'base' },
                    exit_jail: { x: transformExitJail[0], y: transformExitJail[1], ang: startPoint.current.ang + Math.PI / 2, name: 'exit_jail' }
                });
            }
            clickCount.current += 1
            clickCount.current = clickCount.current % 3
        }
        const onMouseMove = (event) => {
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            if (isDragging.current) {
                startPoint.current = { x: event.clientX - bounding.left, y: event.clientY - bounding.top, ang: -Math.PI / 2, name: 'dock' };
                setTemporalDraw({ type: DRAWING_WAYS.POSE, bbox: [startPoint.current] });
                return;
            }
            if (handleAng.current) {
                const newX = event.clientX - bounding.left;
                const newY = event.clientY - bounding.top;
                const dx = newX - startPoint.current.x;
                const dy = newY - startPoint.current.y;
                const newAngle = Math.atan2(dy, dx) + Math.PI;
                startPoint.current = { ...startPoint.current, ang: newAngle };
                setTemporalDraw(prev => ({
                    ...prev,
                    bbox: [{ ...prev.bbox[0], ang: newAngle }]
                }));
                return;
            }
        }

        storeMapCanvasRef?.current?.addEventListener('mousedown', onMouseDown);
        storeMapCanvasRef?.current?.addEventListener('mousemove', onMouseMove);
        document?.addEventListener('mousemove', onMouseMoveOutside);
        return () => {
            storeMapCanvasRef?.current?.removeEventListener('mousedown', onMouseDown);
            storeMapCanvasRef?.current?.removeEventListener('mousemove', onMouseMove);
            document?.removeEventListener('mousemove', onMouseMoveOutside);
            if (storeMapCanvasRef?.current) storeMapCanvasRef.current.style.cursor = 'default';
            stopAutoScroll();
            setTemporalDraw(null);
            dispatch(setSnackAlert(null));
        }
    }, [storeMapCanvasRef, dispatch, transformToRobot, t, autoScrollMovement]);

    const handleNewDraw = useCallback((callbackFunction, type = DRAWING_WAYS.RECTANGLE, needColission = false, colissionLayer = null) => {
        if (!storeMapCanvasRef?.current || !storeMapCanvasRef.current.parentElement) return;
        let startPoint = { x: 0, y: 0 };
        const endPoint = { current: { x: 0, y: 0 } };
        const isDrawing = { current: false };
        const outsidePosition = { current: { x: 0, y: 0 } };
        const isOutside = { current: false };
        const drawingFunction = (point) => {
            setTemporalDraw({ type, bbox: [startPoint, point] });
        }
        const { onMouseMoveOutside, stop: stopAutoScroll } = autoScrollMovement(isDrawing, isOutside, outsidePosition, endPoint, drawingFunction, true);
        storeMapCanvasRef.current.style.cursor = 'pointer';

        const onMouseDown = (event) => {
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            isDrawing.current = true;
            storeMapCanvasRef.current.style.cursor = 'grab';
            setTemporalDraw(null);
            startPoint = {
                x: event.clientX - bounding.left,
                y: event.clientY - bounding.top,
            };
        };
        const onMouseMove = (event) => {
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            if (!isDrawing.current) return;
            endPoint.current = { x: event.clientX - bounding.left, y: event.clientY - bounding.top };
            setTemporalDraw({ type, bbox: [startPoint, endPoint.current] });
        };
        const onMouseUp = (event, useMovement = true) => {
            if (useMovement) onMouseMove(event);
            storeMapCanvasRef.current.style.cursor = 'pointer';
            isDrawing.current = false;
            if (isDistanceBetweenPoints(startPoint, endPoint.current)) {
                if (needColission && colissionLayer) {
                    const start = transformToRobot(startPoint);
                    const end = transformToRobot(endPoint.current);
                    const colissionZones = colissionLayers(colissionLayer, { start: { x: start[0], y: start[1] }, end: { x: end[0], y: end[1] } }, type, 'partial');
                    if (colissionZones.length === 0) {
                        const snack = {
                            open: true,
                            message: t('overseer_app.layouts.creation.no_collision', 'No collision with an aisle was found in the area of ​​the drawing'),
                            severity: 'error',
                        };
                        dispatch(setSnackAlert(snack));
                        setTemporalDraw(null);
                        return;
                    }
                }
                callbackFunction?.({
                    start: transformToRobot(startPoint),
                    end: transformToRobot(endPoint.current)
                });
                startPoint = { x: 0, y: 0 };
                endPoint.current = { x: 0, y: 0 };
                return;
            }
            setTemporalDraw(null);
        };
        const onMouseUpOutside = (event) => {
            const storeMapParent = storeMapCanvasRef?.current?.parentElement;
            const rect = storeMapParent.getBoundingClientRect();
            const isOut = (
                event.clientX < rect.left ||
                event.clientX > rect.right ||
                event.clientY < rect.top ||
                event.clientY > rect.bottom
            );
            if (isOut) onMouseUp(event, false);
        }

        storeMapCanvasRef.current.addEventListener('mousedown', onMouseDown);
        storeMapCanvasRef.current.addEventListener('mousemove', onMouseMove);
        storeMapCanvasRef.current.addEventListener('mouseup', onMouseUp);
        document?.addEventListener('mousemove', onMouseMoveOutside);
        document?.addEventListener('mouseup', onMouseUpOutside);
        return () => {
            storeMapCanvasRef?.current?.removeEventListener('mousedown', onMouseDown);
            storeMapCanvasRef?.current?.removeEventListener('mousemove', onMouseMove);
            storeMapCanvasRef?.current?.removeEventListener('mouseup', onMouseUp);
            document?.removeEventListener('mousemove', onMouseMoveOutside);
            document?.removeEventListener('mouseup', onMouseUpOutside);
            if (storeMapCanvasRef?.current) storeMapCanvasRef.current.style.cursor = 'default';
            stopAutoScroll();
            setTemporalDraw(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeMapCanvasRef, transformToRobot, colissionLayers, t, autoScrollMovement]);

    const handleNewDrawOrientedBbox = useCallback((callbackFunction) => {
        if (!storeMapCanvasRef?.current || !storeMapCanvasRef.current.parentElement) return;
        let startPoint = { x: 0, y: 0 };
        const middlePoint = { current: { x: 0, y: 0 } };
        let endPoint = { current: { x: 0, y: 0 } };
        let middlePointSelected = false;
        let checkMiddlePoint = false;
        const isDrawing = { current: false };
        const isOutside = { current: false };
        const outsidePosition = { current: { x: 0, y: 0 } };
        const disableElementMovement = { current: false };
        storeMapCanvasRef.current.style.cursor = 'pointer';
        const drawingFunction = (point) => {
            if (!middlePointSelected) {
                setTemporalDraw({
                    type: DRAWING_WAYS.ORIENTED_BBOX,
                    bbox: [startPoint, point, null]
                });
            }
        }
        const { onMouseMoveOutside, stop: stopAutoScroll } = autoScrollMovement(isDrawing, isOutside, outsidePosition, middlePoint, drawingFunction, true, disableElementMovement);

        const onMouseDown = (event) => {
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            if (isDrawing.current) return;
            isDrawing.current = true;
            startPoint = {
                x: event.clientX - bounding.left,
                y: event.clientY - bounding.top
            };
            storeMapCanvasRef.current.style.cursor = 'grab';
            setTemporalDraw(null);
        };
        const onMouseMove = (event) => {
            if (!isDrawing.current) return;
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            const newPoint = {
                x: event.clientX - bounding.left,
                y: event.clientY - bounding.top
            };
            if (!middlePointSelected) {
                middlePoint.current = newPoint;
                checkMiddlePoint = true;
                setTemporalDraw({
                    type: DRAWING_WAYS.ORIENTED_BBOX,
                    bbox: [startPoint, middlePoint.current, null]
                });
                return;
            }
            const dirX = middlePoint.current.x - startPoint.x;
            const dirY = middlePoint.current.y - startPoint.y;
            const lengthDir = Math.sqrt(dirX ** 2 + dirY ** 2);
            if (lengthDir === 0) {
                endPoint.current = newPoint;
            } else {
                const perpDirX = -dirY;
                const perpDirY = dirX;
                const unitPerpX = perpDirX / lengthDir;
                const unitPerpY = perpDirY / lengthDir;
                const deltaX = newPoint.x - middlePoint.current.x;
                const deltaY = newPoint.y - middlePoint.current.y;
                const projection = deltaX * unitPerpX + deltaY * unitPerpY;
                endPoint.current = {
                    x: middlePoint.current.x + projection * unitPerpX,
                    y: middlePoint.current.y + projection * unitPerpY
                };
            }
            setTemporalDraw({
                type: DRAWING_WAYS.ORIENTED_BBOX,
                bbox: [startPoint, middlePoint.current, endPoint.current]
            });
        };
        const onMouseUp = (event) => {
            if (!isDrawing.current) return;
            storeMapCanvasRef.current.style.cursor = 'pointer';
            if (!middlePointSelected) {
                if (isDistanceBetweenPoints(startPoint, middlePoint.current) && checkMiddlePoint) {
                    middlePointSelected = true;
                    disableElementMovement.current = true;
                } else {
                    setTemporalDraw(null);
                    startPoint = { x: 0, y: 0 };
                    middlePoint.current = { x: 0, y: 0 };
                    endPoint.current = { x: 0, y: 0 };
                    isDrawing.current = false;
                    checkMiddlePoint = false;
                    disableElementMovement.current = false;
                    return;
                }
            } else {
                isDrawing.current = false;
                const [bboxStart, bboxEnd] = calculateBoundingBox(startPoint, middlePoint.current, endPoint.current);
                const _startPoint = transformToRobot(startPoint);
                const _middlePoint = transformToRobot(middlePoint.current);
                const _endPoint = transformToRobot(endPoint.current);
                const _bboxStart = transformToRobot(bboxStart);
                const _bboxEnd = transformToRobot(bboxEnd);
                callbackFunction?.({
                    drawing_way: DRAWING_WAYS.ORIENTED_BBOX,
                    points: [{
                        x: _startPoint[0],
                        y: _startPoint[1]
                    }, {
                        x: _middlePoint[0],
                        y: _middlePoint[1]
                    }, {
                        x: _endPoint[0],
                        y: _endPoint[1]
                    }],
                    start: [_bboxStart[0], _bboxStart[1]],
                    end: [_bboxEnd[0], _bboxEnd[1]]
                });
                middlePointSelected = false;
                startPoint = { x: 0, y: 0 };
                middlePoint.current = { x: 0, y: 0 };
                endPoint.current = { x: 0, y: 0 };
                checkMiddlePoint = false;
                disableElementMovement.current = false;
            }
        }
        const onMouseUpOutside = (event) => {
            const storeMapParent = storeMapCanvasRef?.current?.parentElement;
            const rect = storeMapParent.getBoundingClientRect();
            const isOut = (
                event.clientX < rect.left ||
                event.clientX > rect.right ||
                event.clientY < rect.top ||
                event.clientY > rect.bottom
            );
            if (isOut) onMouseUp(event);
        }

        storeMapCanvasRef.current.addEventListener('mousedown', onMouseDown);
        storeMapCanvasRef.current.addEventListener('mousemove', onMouseMove);
        storeMapCanvasRef.current.addEventListener('mouseup', onMouseUp);
        document.addEventListener('mousemove', onMouseMoveOutside);
        document.addEventListener('mouseup', onMouseUpOutside);
        return () => {
            storeMapCanvasRef?.current?.removeEventListener('mousedown', onMouseDown);
            storeMapCanvasRef?.current?.removeEventListener('mousemove', onMouseMove);
            storeMapCanvasRef?.current?.removeEventListener('mouseup', onMouseUp);
            document?.removeEventListener('mousemove', onMouseMoveOutside);
            document?.removeEventListener('mouseup', onMouseUpOutside);
            if (storeMapCanvasRef?.current) storeMapCanvasRef.current.style.cursor = 'default';
            setTemporalDraw(null);
            stopAutoScroll();
        }
    }, [storeMapCanvasRef, transformToRobot, autoScrollMovement]);

    const handleNewDrawPolygon = useCallback((callbackFunction) => {
        if (!storeMapCanvasRef?.current || !storeMapCanvasRef.current.parentElement) return;
        const isDrawing = { current: false };
        const isOutside = { current: false };
        const isFirstPoint = { current: true };
        const currentPoint = { current: { x: 0, y: 0 } };
        const outsidePosition = { current: { x: 0, y: 0 } };
        const isFinished = { current: false };
        const points = { current: [] };
        const drawingFunction = (point) => {
            setTemporalDraw({
                type: DRAWING_WAYS.POLYGON,
                bbox: [...points.current, point]
            });
        }
        const { onMouseMoveOutside, stop: stopAutoScroll } = autoScrollMovement(isDrawing, isOutside, outsidePosition, currentPoint, drawingFunction, true);
        storeMapCanvasRef.current.style.cursor = 'pointer';
        const onMouseDown = (event) => {
            if (isDrawing.current) return;
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            const point = {
                x: event.clientX - bounding.left,
                y: event.clientY - bounding.top
            };
            isDrawing.current = true;
            if (isFirstPoint.current) {
                points.current.push(point);
                storeMapCanvasRef.current.style.cursor = 'grab';
                isFirstPoint.current = false;
                return;
            }
        }
        const onMouseMove = (event) => {
            if (!isDrawing.current || isFinished.current) return;
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            currentPoint.current = {
                x: event.clientX - bounding.left,
                y: event.clientY - bounding.top
            };
            setTemporalDraw({
                type: DRAWING_WAYS.POLYGON,
                bbox: [...points.current, currentPoint.current]
            });
        }
        const onMouseUp = (event) => {
            if (!isDrawing.current || isFinished.current) return;
            storeMapCanvasRef.current.style.cursor = 'pointer';
            const bounding = storeMapCanvasRef?.current?.getBoundingClientRect();
            currentPoint.current = {
                x: event.clientX - bounding.left,
                y: event.clientY - bounding.top
            };
            if (points?.current?.[0]?.x === currentPoint.current.x && points?.current?.[0]?.y === currentPoint.current.y) return;
            if (points.current.length >= 4) {
                const lastIndex = points.current.length - 1;
                const lastPoint = points.current[lastIndex];
                const distance = Math.sqrt((currentPoint.current.x - lastPoint.x) ** 2 + (currentPoint.current.y - lastPoint.y) ** 2);
                if (distance <= 25) {
                    isDrawing.current = false;
                    setTemporalDraw({
                        type: DRAWING_WAYS.POLYGON,
                        bbox: points.current,
                        finished: true
                    });
                    const [bboxStart, bboxEnd] = calculatePolygonBoundingBox(points.current);
                    const _bboxStart = transformToRobot(bboxStart);
                    const _bboxEnd = transformToRobot(bboxEnd);
                    callbackFunction?.({
                        drawing_way: DRAWING_WAYS.POLYGON,
                        points: points.current.map(point => {
                            const _point = transformToRobot(point);
                            return {
                                x: _point[0],
                                y: _point[1]
                            }
                        }),
                        start: [_bboxStart[0], _bboxStart[1]],
                        end: [_bboxEnd[0], _bboxEnd[1]]
                    });
                    points.current = [];
                    isFirstPoint.current = true;
                    isFinished.current = false;
                    isDrawing.current = false;
                    currentPoint.current = { x: 0, y: 0 };
                    return;
                }
            }
            points.current.push(currentPoint.current);
            setTemporalDraw({
                type: DRAWING_WAYS.POLYGON,
                bbox: [...points.current, currentPoint]
            });
            if (points.current.length === 4) {
                dispatch(setSnackAlert({
                    open: true,
                    message: t('overseer_app.layouts.creation.press_circle_to_finish', 'Press inside the circle once more to finalize the polygon.'),
                    severity: 'info',
                }));
            }
        }
        storeMapCanvasRef.current.addEventListener('mousedown', onMouseDown);
        storeMapCanvasRef.current.addEventListener('mousemove', onMouseMove);
        storeMapCanvasRef.current.addEventListener('mouseup', onMouseUp);
        document?.addEventListener('mousemove', onMouseMoveOutside);
        return () => {
            storeMapCanvasRef?.current?.removeEventListener('mousedown', onMouseDown);
            storeMapCanvasRef?.current?.removeEventListener('mousemove', onMouseMove);
            storeMapCanvasRef?.current?.removeEventListener('mouseup', onMouseUp);
            document?.removeEventListener('mousemove', onMouseMoveOutside);
            if (storeMapCanvasRef?.current) storeMapCanvasRef.current.style.cursor = 'default';
            setTemporalDraw(null);
            stopAutoScroll();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeMapCanvasRef, autoScrollMovement, t, transformToRobot]);

    useEffect(() => {
        if (!activeTool) return;
        let handlerFunction = null;
        const [tool, callbackFunction, option = null] = Array.isArray(activeTool) ? activeTool : [activeTool, null, null];
        const optionsHandler = {
            [DRAWING_WAYS.BBOX]: handleNewDraw,
            [DRAWING_WAYS.ORIENTED_BBOX]: handleNewDrawOrientedBbox,
            [DRAWING_WAYS.POLYGON]: handleNewDrawPolygon,
        }
        const toolHandlers = {
            [LAYOUT_TOOLS.MOVE]: () => handleDragMove(),
            [LAYOUT_TOOLS.ZONE]: () => optionsHandler?.[option]?.(callbackFunction),
            [LAYOUT_TOOLS.AISLE]: () => optionsHandler?.[option]?.(callbackFunction),
            [LAYOUT_TOOLS.EXCLUSION_ZONE]: () => optionsHandler?.[option]?.(callbackFunction),
            [LAYOUT_TOOLS.AISLE_SIDE]: () => handleNewDraw(callbackFunction, 'line', true, AISLES_LAYER),
            [LAYOUT_TOOLS.HOME]: () => {
                dispatch(setSnackAlert({
                    open: true,
                    message: t('overseer_app.layouts.creation.select_home_point', 'Select a home point and press again to confirm initial point'),
                    severity: 'info',
                }));
                return handleNewHome(callbackFunction);
            },
            [LAYOUT_TOOLS.FREE_AREA]: () => optionsHandler?.[option]?.(callbackFunction),
            [LAYOUT_TOOLS.FREE_LINE]: () => handleNewDraw(callbackFunction, 'line'),
            [LAYOUT_TOOLS.FREE_PIN]: () => {
                dispatch(setSnackAlert({
                    open: true,
                    message: t('overseer_app.layouts.creation.select_map_position', 'Select a map position, then press again to confirm the starting point'),
                    severity: 'info',
                }));
                return handleNewHome(callbackFunction, true);
            },
        };
        handlerFunction = toolHandlers?.[tool]?.() || null;
        return () => {
            // Way to remove listeners from the canvas
            handlerFunction?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTool]);

    const drawMultiplesPoses = useCallback((ctx, poses) => {
        if (!poses) return;
        poses?.filter(pose => pose)?.forEach(pose => {
            const { x, y, ang = -Math.PI / 2 } = pose;
            const angle = ang + Math.PI / 2;
            drawPinOnCanvas(ctx, x, y, angle);
        });
    }, []);

    const drawTemporal = useCallback(() => {
        if (!temporalDraw) return;
        const { type, bbox } = temporalDraw;
        const [firstPoint, secondPoint, ...rest] = bbox;
        let drawerFunction = null;
        let args = null;
        switch (type) {
            case DRAWING_WAYS.RECTANGLE:
                const width = secondPoint.x - firstPoint.x;
                const height = secondPoint.y - firstPoint.y;
                const color = drawColor || '#212529EE';
                const colorFill = drawColor ? `${drawColor}55` : 'rgba(255, 164, 0, 0.15)'
                drawerFunction = drawRectangleOnCanvas;
                args = [firstPoint.x, firstPoint.y, width, height, colorFill, color];
                break;
            case DRAWING_WAYS.LINE:
                drawerFunction = canvasDrawOnLine;
                const pxInit = { x: firstPoint.x, y: firstPoint.y }
                const pxEnd = { x: secondPoint.x, y: secondPoint.y }
                args = [{ pxInit, pxEnd }, drawColor, 4]
                break;
            case DRAWING_WAYS.POSE:
                drawerFunction = drawMultiplesPoses;
                const [dock = null, base = null, exit_jail = null] = bbox;
                args = [[dock, base, exit_jail]];
                break;
            case DRAWING_WAYS.ORIENTED_BBOX:
                const thirdPoint = rest?.[0] || null;
                if (thirdPoint) {
                    const color = drawColor || '#212529EE';
                    const colorFill = drawColor ? `${drawColor}55` : 'rgba(255, 164, 0, 0.15)'
                    drawerFunction = drawOrientedRectangle;
                    args = [firstPoint, secondPoint, thirdPoint, colorFill, color];
                } else {
                    drawerFunction = canvasDrawOnLine;
                    args = [{ pxInit: firstPoint, pxEnd: secondPoint }, drawColor, 0.9];
                }
                break;
            case DRAWING_WAYS.POLYGON:
                const isDrawingLines = bbox.length <= 4 && !temporalDraw?.finished;
                drawerFunction = isDrawingLines ? canvasDrawMultipleLines : drawPolygon;
                const _color = drawColor || '#212529EE';
                const _colorFill = drawColor ? `${drawColor}55` : 'rgba(255, 164, 0, 0.15)';
                args = isDrawingLines
                    ? [bbox, _color, 0.9]
                    : [bbox, _colorFill, _color, 0.9, bbox.length > 4 && !temporalDraw?.finished];
                break;
            default:
                break;
        }
        if (!drawerFunction) return;
        canvasDraw(drawerFunction, args);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [temporalDraw, drawColor]);

    useEffect(() => {
        if (!mapMetadata || !canvasDrawRef?.current) return;
        const { width, height } = mapMetadata;
        canvasDraw(clearCanvas, [width, height]);
        drawTemporal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasDrawRef, temporalDraw, drawTemporal, drawColor, mapMetadata]);

    const resetDraw = useCallback(() => {
        setTemporalDraw(null);
    }, []);

    return {
        canvasDrawRef,
        activeTool,
        setActiveTool,
        updateDrawColor: setDrawColor,
        resetDraw
    }
}